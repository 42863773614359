import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

type RedirectPageProps = {
  path: string | null;
};

const RedirectPage = ({path}:RedirectPageProps) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if(!!id  && !!path){
      navigate(`${path}/${id}`);
    }
  }, [path, id]);
  
  return (
    <>
    </>);
};
export default RedirectPage;