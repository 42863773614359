import React, {useMemo} from "react";
import {
  LinearProgress, LinearProgressType,
  LinearProgressVariant,
  ListRow, Paginator, Tile, TileHeaderProps
} from "@laerdal/life-react-components";
import {useTranslation} from "react-i18next";
import {Session} from "../../../model/dto/organization/session";
import {SessionInformationModal} from "../components/SessionInformationModal";
import styled from "styled-components";
import {useDynamicSize} from "../../../util/DynamicSize";
import {LicenseInformationHelper} from "../helpers/LicenseInformationHelper";
import {
  selectSubscription,
  selectSubscriptionService,
  selectSubscriptionSessions
} from "../../../store/subscription/subscriptionSlice";
import {useSelector} from "react-redux";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaginatorContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const SessionManagementCard = () => {
  const {t} = useTranslation('License');
  const size = useDynamicSize();
  const [selectedSession, setSelectedSession] = React.useState<{ session: Session, seat: number }>();

  const service = useSelector(selectSubscriptionService);
  const subscription = useSelector(selectSubscription);
  const sessions = useSelector(selectSubscriptionSessions);

  const userCount = useMemo(() => LicenseInformationHelper.UserCount(service, undefined, sessions, undefined), [service, sessions, subscription]);
  const maxUserCount = useMemo(() => LicenseInformationHelper.MaxUserCount(subscription) ?? userCount, [subscription, userCount]);

  const [page, setPage] = React.useState(1);
  const pageSize = 10;
  const shouldPaginate = maxUserCount > pageSize;


  const from = (page - 1) * pageSize;
  const to = Math.min(from + pageSize, maxUserCount);

  const header = {
    title: t('Active sessions'),
    subtitle: t('Devices that are currently using this subscription.')
  } as TileHeaderProps;

  return (
    <>
      <SessionInformationModal session={selectedSession?.session}
                               seat={selectedSession?.seat}
                               onClose={() => setSelectedSession(undefined)}
                               isOpen={!!selectedSession}/>
      <Tile header={header} size={size}>
        <Content>
          <LinearProgress
            type={LinearProgressType.Line}
            size={size}
            label={t('Seats {{seats}}/{{max}}', {seats: userCount, max: maxUserCount})}
            value={userCount}
            max={maxUserCount}
            variant={LinearProgressVariant.Normal}/>
          <List>
            {
              Array.from({length: to - from}, (v, index) => index + from)
                .map((index) =>
                  <ListRow key={index}
                           mainText={sessions?.[index] ? `${sessions?.[index].firstName} ${sessions?.[index].lastName}` : '-'}
                           secondaryText={`${t('Seat')} ${index + 1}`}
                           size={size}
                           action={sessions?.[index]
                             ? () => setSelectedSession({session: sessions![index], seat: index + 1})
                             : undefined}/>)
            }
          </List>
          {
            shouldPaginate &&
            <PaginatorContainer>
              <Paginator pageCount={Math.ceil(maxUserCount / pageSize)}
                         currentPage={page}
                         onPageChange={(page) => {
                           setPage(page);
                           return true;
                         }}
                         baseUrl={''}/>
            </PaginatorContainer>
          }
        </Content>
      </Tile>
    </>
  )
}
