import React, { useContext, useEffect, useRef } from "react";
import { GlobalNav } from "@laerdal/navigation";
import UserApi from "../services/api/UserApi";
import {
  selectUserProfile,
  setUserProfile,
} from "../store/account/accountSlice";
import { useAppDispatch } from "../store/store";
import {
  FailToastOptions,
  SuccessToastOptions,
} from "../model/constants/ToastConstants";
import { ToastContext } from "@laerdal/life-react-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFeatures } from "../hooks/Features";
import { FeatureNames } from "../model/constants/FeatureConstants";
import { useNavigate } from "react-router-dom";

declare global {
  interface Window {
    globalNav: GlobalNav;
  }
}

//TODO use the switcher as a react componenct
export const Switcher = () => {
  const { t } = useTranslation("Profile");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { addToast } = useContext(ToastContext);
  const profile = useSelector(selectUserProfile);
  const skip = useRef(false);
  const [checkFeatures, initialized] = useFeatures();
  const navigateToOverview = () => navigate("/overview");

  useEffect(() => {
    if (!skip.current) {
      window.globalNav.getUserInfo();
    }
  }, [profile]);

  useEffect(() => {
    //TODO use the switcher as a react componenct noa a js
    window.globalNav?.emitter?.removeEvent("notifyOrganizationChanged");
    window.globalNav?.emitter?.subscribe("notifyOrganizationChanged", () => {
      skip.current = true;
      UserApi.getUser()
        .then((user) => dispatch(setUserProfile(user)))
        .then(() => {
          navigateToOverview();
        })
        .then(() =>
          addToast(t("Default organization updated."), SuccessToastOptions)
        )
        .catch(() =>
          addToast(t("A problem occurred – please try again"), FailToastOptions)
        );
    });
  }, []);

  useEffect(() => {
    window.globalNav?.setCanSwitchOrganization(
      profile?.switchOrganizationEnabled || false
    );
  }, [initialized, profile]);

  return null;
};
