import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router';

import {Trans, useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {
  BackButton,
  Banner,
  BREAKPOINTS,
  HyperLink,
  LoadingIndicator,
  Size,
  SystemIcons
} from '@laerdal/life-react-components';
import {useMediaMatch} from 'rooks';
import {InstanceLimitType} from "../../model/dto/userProfile/instanceLimitType";
import OrganizationApi from "../../services/api/OrganizationApi";
import {
  OrganizationServiceInvitationResponse
} from "../../model/dto/organization/organizationServiceInvitationResponse";
import {OrganizationServiceMember} from "../../model/dto/organization/organizationServiceMember";
import {ServiceRole} from "../../model/dto/userProfile/serviceRole";
import {UserManagementCard} from "./cards/UserManagementCard";
import {SessionManagementCard} from "./cards/SessionManagementCard";
import {LicenseDetailsCard} from "./cards/LicenseDetailsCard";
import {LicensePaymentDetailsCard} from "./cards/LicensePaymentDetailsCard";
import {useSelector} from "react-redux";
import dayjs from "dayjs";
import {useDynamicSize} from "../../util/DynamicSize";
import {SubscriptionTransactionsCard} from "./cards/SubscriptionTransactionsCard";
import {StyledPageWidth} from '../_commonComponents/StyledComponents';
import {SubscriptionStatus} from "../../model/dto/organization/subscriptionStatus";
import {
  selectSubscription,
  selectSubscriptionOrganizationService, selectSubscriptionScheduledAmendment,
  selectSubscriptionService,
  selectSubscriptionTransactions,
  setOrganizationService,
  setSubscriptionSessions
} from "../../store/subscription/subscriptionSlice";
import {useAppDispatch} from "../../store/store";
import {useLicenseInformation} from "./helpers/LicenseInformationHook";
import {LicenseInformationHelper} from "./helpers/LicenseInformationHelper";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;

  ${BREAKPOINTS.MEDIUM} {
    gap: 24px;
    padding: 24px 0;
  }

  ${BREAKPOINTS.LARGE} {
    gap: 32px;
    padding: 32px 0;
  }
`;


const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${BREAKPOINTS.MEDIUM} {
    flex-direction: row;
    gap: 24px;
  }

  ${BREAKPOINTS.LARGE} {
    gap: 32px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.h3`
  margin: 0;
`;

const LicenseDetailsPage = () => {
  const {t} = useTranslation('License');

  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));

  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const size = useDynamicSize();

  const service = useSelector(selectSubscriptionService);
  const organizationService = useSelector(selectSubscriptionOrganizationService);
  const subscription = useSelector(selectSubscription);
  const amendment = useSelector(selectSubscriptionScheduledAmendment);
  const transactions = useSelector(selectSubscriptionTransactions);
  const {loading} = useLicenseInformation(params.id!);

  /*
  * Retrieve session information periodically
  * */
  useEffect(() => {
    if (service?.instanceLimitType == InstanceLimitType.Session) {
      const abort = new AbortController();

      const interval = setInterval(() => {
        OrganizationApi.GetSessions(params.id!, abort.signal)
          .catch(() => [])
          .then((sessions) => dispatch(setSubscriptionSessions(sessions)));
      }, 2000);

      return () => {
        clearInterval(interval);
        abort.abort();
      };
    }
  }, [service]);

  const handleUserAdd = (invitations: OrganizationServiceInvitationResponse[]) => {
    dispatch(
      setOrganizationService({
        ...organizationService!,
        invitations: [...organizationService!.invitations ?? [], ...invitations.filter(a => a.isSuccessful).map(a => ({
          ...a.invitation,
          isActive: true
        }))]
      }));
  };

  const handleUserEdit = (member: OrganizationServiceMember, role: ServiceRole) => {
    const existing = organizationService!.members.find(a => a.id == member.id)!;
    existing.role = role;
    setOrganizationService({...organizationService!});
  };

  const handleUserRemove = (key: string) => {
    setOrganizationService({
      ...organizationService!,
      invitations: organizationService!.invitations?.filter(a => a.code != key) ?? [],
      members: organizationService!.members.filter(a => a.id != key)
    })
  };

  const handleInvitationResend = (invitation: OrganizationServiceInvitationResponse) => {
    const existing = organizationService!.invitations?.findIndex(a => a.email === invitation.invitation.email)!;
    organizationService!.invitations![existing] = {...invitation.invitation, isActive: true};
    setOrganizationService({...organizationService!});
  };

  const willDowngrade = LicenseInformationHelper.WillDowngrade(amendment);
  //@ts-ignore
  const downgradeText = LicenseInformationHelper.FormatDowngradeText(t, service, amendment);
  const downgradeDate = dayjs(amendment?.amendmentDate).format('LL');

  return (
    <StyledPageWidth useMaxWidth={true} maxWidth={1600}>
      <Header>
        <BackButton
          size={isMediumScreen ? Size.Small : Size.XSmall}
          onClick={() => navigate('/licenses')}>
          {t('Subscriptions & Licenses')}
        </BackButton>
        <Title>
          {service?.name}
        </Title>
      </Header>
      {loading && <LoadingIndicator/>}
      {
        !loading &&
        <Wrapper>
          {
            !!willDowngrade &&
            <Banner type={'neutral'}
                    icon={<SystemIcons.Information/>}
                    fullWidth={false}
                    size={size}>
              <Trans ns={'License'} i18nKey={'ScheduledSubscriptionDowngrade'}>
                This subscription will downgrade to {{downgradeText}} on {{downgradeDate}}. For more
                information <HyperLink variant={"default"} target={'_self'} href={'/cases?showCreateModal=true'}>Contact support</HyperLink>
              </Trans>
            </Banner>
          }
          {
            subscription?.status === SubscriptionStatus.Canceled &&
            <Banner type={'neutral'}
                    icon={<SystemIcons.Information/>}
                    fullWidth={false}
                    size={size}>
              {t('This subscription will expire on {{date}}', {date: dayjs(subscription!.expirationDate).format('ll')})}
            </Banner>
          }
          {
            subscription?.status == SubscriptionStatus.PastDue &&
            <Banner type={'warning'}
                    fullWidth={false}
                    link={'#'}
                    linkText={t('Review and update')}
                    linkAction={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigate(`edit/payment`);
                    }}
                    size={size}>
              {t('There is an issue with your payment method.')}
            </Banner>
          }
          <Row>
            <LicenseDetailsCard/>
            <LicensePaymentDetailsCard/>
          </Row>
          <Row>
            <UserManagementCard
              handleUserAdd={handleUserAdd}
              handleUserEdit={handleUserEdit}
              handleUserRemove={handleUserRemove}
              handleInvitationResend={handleInvitationResend}
            />
            {
              service?.instanceLimitType == InstanceLimitType.Session &&
              <SessionManagementCard/>
            }
          </Row>
          {
            transactions &&
            <SubscriptionTransactionsCard transactions={transactions!}/>
          }
        </Wrapper>

      }
    </StyledPageWidth>
  );
};

export default LicenseDetailsPage;
