import { ToastColor, ToastPosition } from "@laerdal/life-react-components";

export const SuccessToastOptions = {
    color: ToastColor.GREEN,
    showCloseButton: true,
    autoClose: true,
    position: ToastPosition.TOPMIDDLE,
};

export const FailToastOptions = {
    color: ToastColor.RED,
    showCloseButton: true,
    autoClose: true,
    position: ToastPosition.TOPMIDDLE,
};

export const InfoToastOptions = {
    color: ToastColor.BLUE,
    showCloseButton: true,
    autoClose: true,
    position: ToastPosition.TOPMIDDLE,
};