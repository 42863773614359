import React, {useContext, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {
  DropdownItem,
  LinearProgress, LinearProgressType, LinearProgressVariant,
  ListRow,
  Paginator, SearchBar,
  Size,
  SystemIcons, Tile, TileHeaderProps,
  ToastContext
} from '@laerdal/life-react-components';
import {OrganizationServiceMember,} from "../../../model/dto/organization/organizationServiceMember";
import {ServiceRole} from "../../../model/dto/userProfile/serviceRole";
import {
  OrganizationServiceInvitationResponse
} from "../../../model/dto/organization/organizationServiceInvitationResponse";
import OrganizationApi from "../../../services/api/OrganizationApi";
import {ServiceMemberItem, ServiceMemberItemInvitation, ServiceMemberItemMember} from "../types";
import {FailToastOptions, SuccessToastOptions} from "../../../model/constants/ToastConstants";
import AddUserModal from "../components/AddUserModal";
import EditUserModal from "../components/EditUserModal";
import RemoveUserModal from "../components/RemoveUserModal";
import {useDynamicSize} from "../../../util/DynamicSize";
import {useDebouncedValue} from "rooks";
import {InstanceLimitType} from "../../../model/dto/userProfile/instanceLimitType";
import {useSelector} from "react-redux";
import {
  selectCanManageSubscription, selectIsSubscriptionOwner, selectOrganizationMembers, selectSubscription,
  selectSubscriptionMembers,
  selectSubscriptionOrganizationService,
  selectSubscriptionService
} from "../../../store/subscription/subscriptionSlice";
import {LicenseInformationHelper} from "../helpers/LicenseInformationHelper";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 8px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaginatorContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

interface Props {
  handleUserAdd: (invitations: OrganizationServiceInvitationResponse[]) => void;
  handleUserEdit: (member: OrganizationServiceMember, role: ServiceRole) => void;
  handleUserRemove: (key: string) => void;
  handleInvitationResend: (invitation: OrganizationServiceInvitationResponse) => void;
}

export const UserManagementCard: React.FunctionComponent<Props> = ({
                                                                     handleUserAdd,
                                                                     handleUserEdit,
                                                                     handleUserRemove,
                                                                     handleInvitationResend,
                                                                   }) => {
  const {t} = useTranslation('License');
  const {addToast} = useContext(ToastContext);

  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState<boolean>(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState<boolean>(false);
  const [isRemoveUserModalOpen, setIsRemoveUserModalOpen] = useState<boolean>(false);
  const [focusedServiceMember, setFocusedServiceMember] = useState<ServiceMemberItem | undefined>(undefined);

  const size = useDynamicSize();
  const members = useSelector(selectSubscriptionMembers) ?? [];
  const organizationMembers = useSelector(selectOrganizationMembers) ?? [];
  const service = useSelector(selectSubscriptionService);
  const subscription = useSelector(selectSubscription);
  const organizationService = useSelector(selectSubscriptionOrganizationService);
  const canManage = useSelector(selectCanManageSubscription);
  const isOwner = useSelector(selectIsSubscriptionOwner);

  const [page, setPage] = useState<number>(1);

  const [search, setSearch] = useState<string>('');
  const [filtered, setFiltered] = useState(members);
  const [searchDebounced] = useDebouncedValue(search, 250);

  useEffect(() => {
    if (!searchDebounced) {
      setFiltered(members);
      return;
    }
    const filtered = members.filter((a) => {
      if (a.type === 'member') {
        const member = a.member;
        const name = `${member.user?.firstName} ${member.user?.lastName}`.toLowerCase();
        const email = member.user?.email.toLowerCase();
        return name.includes(searchDebounced.toLowerCase()) || email.includes(searchDebounced.toLowerCase());
      } else {
        const invitation = a.invitation;
        const email = invitation.email.toLowerCase();
        return email.includes(searchDebounced.toLowerCase());
      }
    });
    setFiltered(filtered);
  }, [members, searchDebounced]);

  const actions: DropdownItem[] = [
    {
      value: 'edit',
      displayLabel: t('Edit access'),
    },
    {
      value: 'remove',
      displayLabel: t('Remove from service'),
    },
  ];

  const invitationActions: DropdownItem[] = [
    {
      value: 'resend',
      displayLabel: t('Resend invite'),
    },
    {
      value: 'remove',
      displayLabel: t('Remove from service'),
    },
  ];

  const onUserContextMenuClick = (action: string, member: ServiceMemberItem) => {
    setFocusedServiceMember(member);
    switch (action) {
      case 'edit':
        setIsEditUserModalOpen(true);
        break;
      case 'remove':
        setIsRemoveUserModalOpen(true);
        break;
      case 'resend':
        resendInvitation(member as ServiceMemberItemInvitation);
        break;
    }
  };

  const resendInvitation = (member: ServiceMemberItemInvitation | undefined) => {
    const serviceId = organizationService?.id ?? '';
    const role = member?.invitation.role as ServiceRole;
    const email = member?.invitation?.email ?? (member?.invitation?.email ?? '');
    const code = member?.invitation?.code ?? '';

    OrganizationApi.RemoveOrganizationServiceInvitation(serviceId, code).then((a) => {
      OrganizationApi.InviteMemberToOrganizationService(serviceId, {
        role: role,
        emails: [email],
        sendEmail: true,
      })
        .then((value) => {
          handleInvitationResend(value[0]);
          addToast(t('Invite resent to {{email}}', {email: email}), SuccessToastOptions);
        })
        .catch((reason) => {
          addToast(t('Invite resent failed'), FailToastOptions);
        });
    });
  };

  const canAddUsers = useMemo(() => LicenseInformationHelper.CanAddUsers(service, subscription, members), [service, subscription, members]);
  const isUnlimited = useMemo(() => LicenseInformationHelper.IsUnlimited(subscription), [subscription]);
  const userCount = useMemo(() => LicenseInformationHelper.UserCount(service, members, undefined, organizationMembers), [service, members, organizationMembers]);
  const maxUserCount = useMemo(() => LicenseInformationHelper.MaxUserCount(subscription), [subscription]);


  const pageSize = 10;
  const shouldPaginate = filtered.length > pageSize;
  const from = (page - 1) * pageSize;
  const to = from + pageSize;

  const header = {
    title: t('User access'),
    subtitle: t('People who can access and use this subscription.'),
    buttons: canManage
      ? [{
        componentType: 'button',
        variant: 'primary',
        onClick: () => {
          //@ts-ignore
          posthog.capture?.('AC SubscriptionAddUsers Opened',{
            subscription_name: service!.name,
          });

          setIsAddUserModalOpen(true)
        },
        disabled: !canAddUsers,
        buttonText: t('Add user'),
        icon: <SystemIcons.AddUser/>
      }]
      : []
  } as TileHeaderProps;

  return (
    <>
      <AddUserModal
        onClose={() => setIsAddUserModalOpen(false)}
        isOpen={isAddUserModalOpen}
        service={service!}
        organizationService={organizationService!}
        organizationMembers={organizationMembers}
        handleUserAdd={handleUserAdd!}
      />

      <EditUserModal
        onClose={() => setIsEditUserModalOpen(false)}
        isOpen={isEditUserModalOpen}
        service={service}
        organizationServiceId={organizationService?.id}
        member={focusedServiceMember as ServiceMemberItemMember}
        handleUserEdit={handleUserEdit}
      />

      <RemoveUserModal
        onClose={() => setIsRemoveUserModalOpen(false)}
        isOpen={isRemoveUserModalOpen}
        service={service}
        organizationServiceId={organizationService?.id}
        member={focusedServiceMember}
        handleUserRemove={handleUserRemove}
      />
      <Tile header={header}
            size={size}>
        <Content>

          {
            service?.instanceLimitType != InstanceLimitType.Session &&
            <LinearProgress
              type={LinearProgressType.Line}
              size={size}
              label={
                isUnlimited
                  ? t('Number of users {{users}}', {users: userCount})
                  : t('Number of users {{users}}/{{max}}', {users: userCount, max: maxUserCount ?? userCount})
              }
              value={userCount}
              max={maxUserCount ?? userCount}
              variant={LinearProgressVariant.Normal}/>
          }
          <SearchBar id={'search'}
                     enterSearch={() => {
                     }}
                     removeSearch={() => setSearch('')}
                     setSearchTerm={term => setSearch(term)}
                     size={size == Size.Small ? size : Size.Medium}
                     searchTerm={search}
                     placeholder={t('Search users')}/>


          <List>
            {
              !members?.length &&
              <ListRow mainText={t('You have not added any people to this License yet')}
                       size={size}
                       icon={<SystemIcons.User/>}/>
            }
            {
              members?.length && !filtered.length &&
              <ListRow mainText={t('No results to display')}
                       size={size}
                       icon={<SystemIcons.User/>}/>
            }

            {!!filtered?.length &&
              filtered.slice(from, to).map((a) => (
                <ListRow
                  key={a.type === 'member' ? a.member.id : a.invitation.code}
                  size={size}
                  icon={a.type === 'member' ? <SystemIcons.User/> : <SystemIcons.Mail/>}
                  mainText={a.type === 'member' ? `${a.member.user?.firstName} ${a.member.user?.lastName}` : a.invitation?.email}
                  secondaryText={a.type === 'member' ? a.member.user?.email : undefined}
                  note={a.type === 'member' ? a.member.role?.name : t('Invited')}
                  dropdown={
                    isOwner
                      ? {
                        items: a.type === 'invitation' ? invitationActions : actions,
                        width: '200px',
                        onClick: (value) => onUserContextMenuClick(value[0], a),
                      }
                      : undefined
                  }
                />
              ))}
          </List>
          {
            shouldPaginate &&
            <PaginatorContainer>
              <Paginator pageCount={Math.ceil(filtered.length / pageSize)}
                         currentPage={page}
                         onPageChange={(page) => {
                           setPage(page);
                           return true;
                         }}
                         baseUrl={''}/>
            </PaginatorContainer>
          }
        </Content>
      </Tile>
    </>
  );
};

