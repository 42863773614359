import { TablePagination } from "@laerdal/life-react-components";

class PaginationHelper {
  GetInitial = (rowsPerPage: number, totalCount: number): TablePagination => {
    return {
        currentPage: 0, 
        rowsPerPage: rowsPerPage, 
        total: totalCount, 
        from: totalCount > 0 ? 1 : 0, 
        to: totalCount > rowsPerPage ? rowsPerPage : totalCount
    }
  }

  GetCurrent = (currentPage: number, rowsPerPage: number, totalCount: number): TablePagination => {
    return {
      currentPage: currentPage,
      rowsPerPage: rowsPerPage,
      total: totalCount,
      from: totalCount > 0 ? 1 + currentPage * rowsPerPage : 0,
      to: totalCount > (currentPage + 1) * rowsPerPage ? (currentPage + 1) * rowsPerPage : totalCount
    }
  }


  GetPrevious = (currentPage: number, rowsPerPage: number, totalCount: number): TablePagination => {
    return {
      currentPage: currentPage - 1, 
      rowsPerPage: rowsPerPage, 
      total: totalCount, 
      from: (currentPage - 1) * rowsPerPage + 1, 
      to: currentPage * rowsPerPage
    }
  }

  GetNext = (currentPage: number, rowsPerPage: number, totalCount: number): TablePagination => {
    return {
      currentPage: currentPage + 1,
      rowsPerPage: rowsPerPage,
      total: totalCount,
      from: (currentPage + 1) * rowsPerPage + 1,
      to: totalCount > (currentPage + 2) * rowsPerPage ? (currentPage + 2) * rowsPerPage : totalCount
    }
  }
}

export default new PaginationHelper();