import React, {useContext} from 'react';
import styled from "styled-components";
import {
  BackButton,
  BREAKPOINTS, Button,
  COLORS, 
  InputLabel, PageWidth,
  Size, SystemIcons, TextField, ToastContext
} from "@laerdal/life-react-components";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectGigyaAccount, selectUserProfile, setGigyaAccount, setUserProfile} from "../../store/account/accountSlice";
import {useNavigate} from "react-router";
import {useController, useForm} from "react-hook-form";
import UserApi from "../../services/api/UserApi";
import {FailToastOptions, SuccessToastOptions} from "../../model/constants/ToastConstants";
import {useAppDispatch} from "../../store/store";
import {UserInfo} from "../../model/dto/userProfile/userInfo";
import { StyledPageWidth } from '../_commonComponents/StyledComponents';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.h3`
  margin: 0;
  
  ${BREAKPOINTS.MEDIUM}{
    display: flex;
  }
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
`;


const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  
  ${BREAKPOINTS.MEDIUM}{
    gap: 16px;
    flex-direction: row-reverse;
  }
`;

export const UserDetailsPage = () => {
  const {t} = useTranslation('Profile');
  const {addToast} = useContext(ToastContext);
  const account = useSelector(selectGigyaAccount);
  const profile = useSelector(selectUserProfile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {control, reset, handleSubmit, formState} = useForm<UserInfo>(
    {
      defaultValues: {
        firstname: account!.firstName,
        lastname: account!.lastName
      }
    }
  )

  const firstname = useController({control, name: 'firstname'})
  const lastname = useController({control, name: 'lastname'})

  const submit =
    (model: UserInfo) =>
      {
        //@ts-ignore
        posthog.capture?.('UserDetailsPage UpdatedUserInfo');
        UserApi.Update(model)
        .then(() => {
          dispatch(setGigyaAccount({...account!, firstName: model.firstname!, lastName: model.lastname!}));
          dispatch(setUserProfile({...profile!, firstName: model.firstname!, lastName: model.lastname!}));

          addToast(t('Account details saved'), SuccessToastOptions);
        })
        .catch(() => {
          addToast(t('There was an error saving changes'), FailToastOptions);
        });
      }

  return (
    <StyledPageWidth  useMaxWidth={true} maxWidth={800}>
      <Header>
        <BackButton size={Size.Small} onClick={() => navigate('/profile')}>{t('Back')}</BackButton>
        <Title>{t('Edit details')}</Title>
        <Description>{t('Here you can change basic profile information.')}</Description>
      </Header>
      <Content>
        <InputLabel inputId={'firstname'} text={t('First name')}/>
        <TextField id="firstname" {...firstname.field}/>
        <InputLabel inputId={'lastname'} text={t('Last name')}/>
        <TextField id="lastname" {...lastname.field}/>
        <InputLabel inputId={'email'} text={t('Email address')}/>
        <TextField id="email" value={account!.email} readOnly={true}
                   note={{message: t('This cannot be changed'), icon: <SystemIcons.Information/>}}/>

        <Actions>
          <Button onClick={handleSubmit(submit)} loading={formState.isSubmitting}>{t('Save changes')}</Button>
          <Button onClick={() => navigate('/profile')} variant={'tertiary'}>{t('Cancel')}</Button>
        </Actions>

      </Content>
    </StyledPageWidth>
  )

};