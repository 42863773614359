import {
  ComponentS,
} from '@laerdal/life-react-components';
import styled from 'styled-components';
import { AddressDto } from '../../model/dto/address/addressDto';

const Detials = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export interface Props {
  address: AddressDto;
}

const AddressFormat = ({address}: Props) => {
  return (
    <Detials>
      { address.additionalName && <ComponentS>{address.additionalName}</ComponentS> }
      { address.email && <ComponentS>{address.email}</ComponentS> }
      { address.tel && <ComponentS>{address.tel}</ComponentS> }
      <br/>
      { address.line1 && <ComponentS>{address.line1},</ComponentS> }
      { address.line2 && <ComponentS>{address.line2},</ComponentS> }
      { address.city && <ComponentS>{address.city},</ComponentS> }
      { (address.zipCode || address.city)  && <ComponentS>{`${address.zipCode} ${address.city},`}</ComponentS> }
      { address.state && <ComponentS>{address.state},</ComponentS> }
      { address.country && <ComponentS>{address.country}</ComponentS> }
    </Detials>)
}

export default AddressFormat;