import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  BackButton,
  BREAKPOINTS, Button, Checkbox,
  COLORS, 
  LoadingIndicator, 
  PageWidth,
  Size, ToastContext,
} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {selectUserProfile, setUserProfile} from '../../store/account/accountSlice';
import SettingsApi from '../../services/api/SettingsApi';
import {useNavigate} from 'react-router';
import UserApi from '../../services/api/UserApi';
import {FailToastOptions, SuccessToastOptions} from '../../model/constants/ToastConstants';
import {useAppDispatch} from '../../store/store';
import {CompanyRole} from '../../model/dto/userProfile/companyRole';
import { StyledPageWidth } from '../_commonComponents/StyledComponents';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.h3`
  margin: 0;
  ${BREAKPOINTS.MEDIUM}{
    display: flex;
  }
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
`;


const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  ${BREAKPOINTS.MEDIUM}{
    gap: 16px;
  }
`;


const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  ${BREAKPOINTS.MEDIUM}{
    gap: 16px;
    flex-direction: row-reverse;
  }
`;

const CompanyRoles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${BREAKPOINTS.MEDIUM} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 8px;
    grid-column-gap: 8px;
  }

`;

const Role = styled.div`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  background: ${COLORS.white};
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px ${COLORS.neutral_200};
`;


export const CompanyRolesPage = () => {
  const {t} = useTranslation('Profile');
  const {addToast} = useContext(ToastContext);
  const profile = useSelector(selectUserProfile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [availableRoles, setAvailableRoles] = useState<CompanyRole[]>([]);
  const [roles, setRoles] = useState<CompanyRole[]>([]);

  useEffect(() => {
    setRoles(profile!.companyRoles ?? []);
  }, [profile]);

  useEffect(() => {
    SettingsApi.GetCompanyRoles().then((roles) => {
      const tmp =
        roles.slice()
          .sort((a, b) => (a.id.toLowerCase() === b.id.toLowerCase()
                           ? 0
                           : a.id.toLowerCase() > b.id.toLowerCase()
                             ? -1
                             : 1));
      setAvailableRoles(tmp);

      setLoading(false);
    }).catch(() => {
      addToast(t('Something went wrong please contact support'), FailToastOptions);
      navigate('/profile');
    });

  }, []);

  const updateState = () => {
    dispatch(setUserProfile({...profile!, companyRoles: roles,}));
  };

  const submit =
    () => {
      //@ts-ignore
      posthog.capture?.('CompanyRoles ChangeRoles');
      setSubmitting(true);
      UserApi.UpdateRoles(roles.map(a => a.id))
        .then(() => {
          updateState();
          addToast(t('Roles updated'), SuccessToastOptions);
        })
        .catch(() => {
          addToast(t('There was an error saving changes'), FailToastOptions);
        })
        .finally(() => setSubmitting(false));
    };

  return (
    <StyledPageWidth  useMaxWidth={true} maxWidth={800}>
      <Header>
        <BackButton size={Size.Small} onClick={() => navigate('/profile')}>{t('Back')}</BackButton>
        <Title>{t('About you')}</Title>
        <Description>{t('We use this information to customize your dashboard.')}</Description>
      </Header>
      <Content>
        {
          loading && <LoadingIndicator/>
        }

        <CompanyRoles>
          {
            availableRoles.map((role) => <Role key={role.id}>
              <Checkbox selected={!!roles.find(a => a.id === role.id)}
                        id={'checkbox_' + role.id}
                        select={(select) =>
                          select
                          ? setRoles([...roles, role])
                          : setRoles(roles.filter(r => r.id !== role.id))
                        }
                        label={t(role.name)}/>
            </Role>)
          }

        </CompanyRoles>


        <Actions>
          <Button id={'submit'} onClick={submit} loading={submitting}>{t('Save changes')}</Button>
          <Button id={'cancel'} onClick={() => navigate('/profile')} variant={'tertiary'}>{t('Cancel')}</Button>
        </Actions>

      </Content>
    </StyledPageWidth>
  );

};