import axios from 'axios';
import JwtDecode from 'jwt-decode';
import { AccountsGetJwtData } from '../../model/gigya/accountsGetJwtData';
import { Jwt } from '../../model/gigya/jwt';
import {AccountsGetAccountData} from '../../model/gigya/accountsGetAccountData';

export class AuthApi {
  token = '';
  static instance = new AuthApi();

  constructor() {
    axios.defaults.headers.patch['Content-Type'] = 'application/json';
  }

  /**
   * Retrieves user information from Gigya
   * */
  retrieveAccountInfo = () => {
    return new Promise<AccountsGetAccountData>((resolve, reject) => {
      // @ts-ignore
      window.gigya.accounts.getAccountInfo({
        include: 'UID, emails, profile, data',
        extraProfileFields: 'locale',
        callback: (res: AccountsGetAccountData) => {
          if (res.errorCode === 0) {
            resolve(res);
          } else {
            reject(res);
          }
        },
      });
    })
  };

  /**
   * Retrieves the JWT token from the Gigya REST API and stores it.
   */
  retrieveAndStoreJWT = () => {
    return new Promise((resolve, reject) => {
      // window.gigya.accounts.getJWT({fields: 'firstName, lastName, email, data.organization.country, data.commerce.market', callback: (res) => {console.log(res.id_token)}});
      // @ts-ignore
      //fields: 'firstName, lastName, email, data.organization.country, data.commerce.market',
      window.gigya.accounts.getJWT({
        fields: 'firstName, lastName, email',
        expiration: process.env.REACT_APP_JWT_LIFETIME ?? 3600,
        callback: (res: AccountsGetJwtData) => {
          if (res.errorCode === 0) {
            this.token = res.id_token;
            resolve(true);
          } else {
            reject();
          }
        },
      });
    });
  };

  /**
   * Retrieves the token from the context.
   * @returns A JWT token for the currently authenticated user.
   */
  getToken = async (): Promise<string> => {
    if (this.token) {
      const jwt: Jwt = JwtDecode(this.token);

      if (jwt?.exp && Date.now() >= jwt.exp * 1000) { //expired
        await this.retrieveAndStoreJWT();
      }
    } else {
      await this.retrieveAndStoreJWT();
    }

    // Let's return the token
    return this.token;
  };

  ClearCache = () => {
    this.token = '';
  }
}
export default AuthApi.instance;


// window.gigya.accounts.getJWT({
//   fields: 'firstName, lastName, email, data.organization.country, data.commerce.market',
//   expiration: 3600,
//   callback: (res) => {
//     console.log(res.id_token)
//   },
// });