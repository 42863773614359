import React, {useMemo} from 'react';
import * as yup from 'yup';
import {useController, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {
  BasicDropdown,
  DropdownItem,
  IconButton,
  Size,
  States,
  SystemIcons,
  Table,
  TableColumn,
  TextField,
} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

export const StyledTableCellText = styled.span`
  padding: 12px 0;
  &:first-letter{
    text-transform: none !important;
  }
`;

const schema = yup.object().shape({
  key: yup.string().required().ensure(),
  value: yup.string().required().ensure(),
});

interface Attribute {
  key: string;
  value: string;
  type: 'create' | 'value';
}

interface Props {
  attributes: { [key: string]: string };
  onChange: (attributes: { [key: string]: string }) => void;
}

const AttributesInput = ({attributes, onChange}: Props) => {

  const {t} = useTranslation(['SAML', 'Common']);

  const {handleSubmit, control, reset} = useForm<Attribute>({
    defaultValues: {
      key: '',
      value: '',
    },
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const keyField = useController({control, name: 'key'});
  const valueField = useController({control, name: 'value'});

  const rows = Object.entries(attributes ?? {}).map(([key, value]) => ({type: 'value', key, value}));

  const cdcAttributes = [
    {
      value: 'ProviderUID',
      displayLabel: 'ProviderUID',
    },
    {
      value: 'Country',
      displayLabel: 'Country',
    },
    {
      value: 'City',
      displayLabel: 'City',
    },
    {
      value: 'Email',
      displayLabel: 'Email',
    },
    {
      value: 'FirstName',
      displayLabel: 'FirstName',
    },
    {
      value: 'LastName',
      displayLabel: 'LastName',
    },
    {
      value: 'Zip',
      displayLabel: 'Zip',
    },
    {
      value: 'Gender',
      displayLabel: 'Gender',
    },
  ] as DropdownItem[];

  return (
    <Table accordion={true}
           rows={[{type: 'create'}].concat(rows)}
           noRowsLabel={t('There are no rows to display', {'ns': 'Common'})}
           rowsPerPageLabel={t('Rows per page:', {'ns': 'Common'})}
           columns={[
             {
               key: 'key',
               name: t('IdP Attribute Name'),
               width: '40%',
               type: 'custom',
               customContent: (row: Attribute) => {
                 switch (row.type) {
                   case 'create':
                     return <TextField validationMessage={keyField.fieldState.error?.message}
                                       state={keyField.fieldState.error?.message ? States.Invalid : undefined}
                                       id={'key'}
                                       onMouseDown={e => e.stopPropagation()}
                                       style={{width: '100%'}}
                                       size={Size.Small}
                                       {...keyField.field}/>;
                   case 'value':
                   default:
                     return <StyledTableCellText>{row.key}</StyledTableCellText>;
                 }
               },
             },
             {
               key: 'value',
               name: t('CDC Field Name'),
               width: '40%',
               type: 'custom',
               customContent: (row: Attribute) => {
                 switch (row.type) {
                   case 'create':
                     return <BasicDropdown activeValidationMessage={valueField.fieldState.error?.message}
                                           list={cdcAttributes}
                                           id={'value'}
                                           onSelect={valueField.field.onChange}
                                           onBlur={valueField.field.onBlur}
                                           value={valueField.field.value}
                                           ref={valueField.field.ref}
                                           size={Size.Small}/>;
                   case 'value':
                   default:
                     return <StyledTableCellText>{row.value}</StyledTableCellText>;
                 }
               },
             },
             {
               key: 'type',
               name: t('Actions'),
               width: '10%',
               type: 'custom',
               customContent: (row: Attribute) => {
                 switch (row.type) {
                   case 'create':
                     return <IconButton
                       action={handleSubmit((value) => {
                         onChange({...attributes, [value.key]: value.value});
                         reset({key: '', value: ''});
                       })}
                       variant={'secondary'}
                       shape={'circular'}>
                       <SystemIcons.Add/>
                     </IconButton>;
                   case 'value':
                   default:
                     return <IconButton
                       action={() => {
                         let value = {...attributes};
                         delete value[row.key];
                         onChange(value);
                       }}
                       variant={'secondary'}
                       shape={'circular'}>
                       <SystemIcons.Delete/>
                     </IconButton>;
                 }
               },
             },
           ]}/>
  );
};


export default AttributesInput;