import {useMediaMatch} from "rooks";
import {BREAKPOINTS, Size} from "@laerdal/life-react-components";
import {number} from "yup";

export const useDynamicSize = (type: 'input' | 'standard' = "standard"): any => {
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
  const isLargeScreen = useMediaMatch(BREAKPOINTS.LARGE.replace('@media ', ''));

  const size = isLargeScreen ? Size.Large : isMediumScreen ? Size.Medium : Size.Small;

  return type === 'input'
    ? size === Size.Large
      ? Size.Medium
      : size
    : size;
}