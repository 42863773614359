import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {
  Banner,
  Button,
  COLORS,
  ComponentMStyling,
  ComponentSStyling,
  ComponentTextStyle,
  Size,
  SystemIcons,
  ToastContext,
} from '@laerdal/life-react-components';
import {Permission} from './Permission';
import {PermissionsType, usePermissions} from '../../../hooks/Permissions';
import {SpecialPermission} from './SpecialPermission';
import {UserPermissionDetailsDto} from '../../../model/dto/userDetails/UserPermissionDetailsDto';
import {useSelector} from 'react-redux';
import {selectUserProfile} from '../../../store/account/accountSlice';
import {FailToastOptions, SuccessToastOptions} from '../../../model/constants/ToastConstants';
import PermissionsApi from '../../../services/api/PermissionsApi';
import {useNavigate} from 'react-router';
import UserApi from '../../../services/api/UserApi';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  
  max-width: 648px;
  box-sizing: border-box;
  border: 1px solid ${COLORS.neutral_200};
  border-radius: 8px;
  background: ${COLORS.white};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.div`
  ${ComponentMStyling(ComponentTextStyle.Bold, COLORS.black)}
`;

const Description = styled.div`
  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.black)}
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.div` 
  ${ComponentSStyling(ComponentTextStyle.Bold, COLORS.neutral_600)}
`;

const SectionSubTitle = styled.div` 
  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.critical_500)}
`;

const Actions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;

interface Props {
  type: 'member' | 'invitation';
  user: UserPermissionDetailsDto;
  onUpdateUser: (user: UserPermissionDetailsDto) => void;
}

export const UserPermissions = ({type, user, onUpdateUser}: Props) => {

  const {t} = useTranslation('Users');
  const {addToast} = useContext(ToastContext);
  const navigate = useNavigate();
  const profile = useSelector(selectUserProfile);
  const {hasPermissions} = usePermissions();

  const [permissions, setPermissions] = useState(user.permissions ?? []);
  const [submitting, setSubmitting] = useState(false);
  const [canPayOnAccount, setCanPayOnAccount] = useState<boolean>(false);
  const [formUpdated, setFormUpdated] = useState<boolean>(false);

  const isActive = (permission: string) => permissions.includes(permission) || permissions.includes(PermissionsType.AccountOwner);

  
  useEffect(() => {
    UserApi.CanPayOnAccount().then(canPayOnAccount => {
      setCanPayOnAccount(canPayOnAccount);
    });
  }, []);

  const togglePermission = (selected: boolean, permission: string) => {
    setFormUpdated(true);
    let change = selected ? [...permissions, permission] : permissions.filter(p => p !== permission);

    if (permission == PermissionsType.ViewProducts && !selected) {
      change = change.filter(a => a != PermissionsType.ManageProducts);
    }

    if (permission == PermissionsType.ManageProducts && selected && !permissions.includes(PermissionsType.ViewProducts)) {
      change = [...change, PermissionsType.ViewProducts];
    }

    return setPermissions(change);
  };

  const cancel = () => {
    setFormUpdated(false);
    setPermissions(user.permissions ?? []);
    navigate('/users');
  };

  const submit = () => {
    setFormUpdated(false);
    setSubmitting(true);
    let data = permissions ?? [];

    if (data.includes(PermissionsType.AccountOwner)) {
      data = [PermissionsType.AccountOwner];
    }

    let request: Promise<any>;
    switch (type) {
      case 'member':
        //@ts-ignore
        posthog.capture?.('UserPermissions UpdateUserPermission',{
          permissions: data
        });
        request = PermissionsApi.UpdateUserPermissions(user!.id!, data);
        break;
      case 'invitation':
        //@ts-ignore
        posthog.capture?.('UserPermissions UpdateInvitationPermission',{
          permissions: data
        });
        request = PermissionsApi.UpdateInvitationPermissions(user!.inviteCode!, data);
        break;
      default:
        request = Promise.reject();
        break;
    }

    return request.then(() => {
      addToast(t('User account updated'), SuccessToastOptions);
      onUpdateUser({...user!, permissions: permissions});
    }).catch(() => {
      setPermissions(user?.permissions ?? []);
      addToast(t('A problem occurred – please try again'), FailToastOptions);
    }).finally(() => setSubmitting(false));
  };

  const currentOrganization = profile!.currentOrganization;
  const selfEditing = profile!.id.toLowerCase() === user.id?.toLowerCase();

  const isDisabled = permissions.includes(PermissionsType.AccountOwner);
  
  return (
    <Wrapper>
      <Header>
        <Title>
          {t('Settings & Permissions')}
        </Title>
        <Description>
          {t('Control what this person can see and do at your organization.')}
        </Description>
      </Header>

      {
        !hasPermissions(PermissionsType.AccountOwner) &&
        <Banner icon={<SystemIcons.Information/>} type={'neutral'}
                size={Size.Small}>{t('Locked items are only editable by Administrators')}</Banner>
      }

      <SpecialPermission id={PermissionsType.AccountOwner}
                         name={t('Set as Administrator')}
                         description={t(
                           'Can purchase products and licenses, as well as perform every action listed below.')}
                         locked={!hasPermissions(PermissionsType.AccountOwner)}
                         selfEditing={selfEditing}
                         selected={isActive(PermissionsType.AccountOwner)}
                         onToggle={(selected) => togglePermission(selected, PermissionsType.AccountOwner)}/>
      <Section>
        <SectionTitle>{t('Organization')}</SectionTitle>
        <Permission id={PermissionsType.ManageAccess}
                    name={t('Manage access')}
                    description={t('Manage access for others.')}
                    disabled={isDisabled}
                    locked={selfEditing}
                    selected={isActive(PermissionsType.ManageAccess)}
                    onToggle={(selected) => togglePermission(selected, PermissionsType.ManageAccess)}/>
        {
          currentOrganization?.allowProducts &&
          <>
            <Permission id={PermissionsType.ViewProducts}
                        name={t('View products')}
                        description={t(
                          'Can access <strong>My Products</strong> in Laerdal Connect to see equipment and items belonging to this organization.')}
                        disabled={isDisabled}
                        selected={isActive(PermissionsType.ViewProducts)}
                        onToggle={(selected) => togglePermission(selected, PermissionsType.ViewProducts)}/>
            <Permission id={PermissionsType.ManageProducts}
                        name={t('Manage products')}
                        description={t(
                          'Can hide products from displaying to others and edit details such as product nickname and notes.')}
                        disabled={isDisabled}
                        selected={isActive(PermissionsType.ManageProducts)}
                        onToggle={(selected) => togglePermission(selected, PermissionsType.ManageProducts)}/>
            <Permission id={PermissionsType.UpdateProducts}
                        name={t('Update products')}
                        description={t('Can perform software updates for connected products.')}
                        disabled={isDisabled}
                        selected={isActive(PermissionsType.UpdateProducts)}
                        onToggle={(selected) => togglePermission(selected, PermissionsType.UpdateProducts)}/>
          </>
        }

      </Section>

      <Section>
        <SectionTitle>{t('My Account')}</SectionTitle>
        {
          currentOrganization?.allowServiceAppointments &&
          <>
            <Permission id={PermissionsType.ViewAppointments}
                        name={t('View appointments')}
                        description={t('Can view all service appointments related to this organization.')}
                        disabled={isDisabled}
                        selected={isActive(PermissionsType.ViewAppointments)}
                        onToggle={(selected) => togglePermission(selected, PermissionsType.ViewAppointments)}/>
          </>
        }
        {
          currentOrganization?.allowOrders &&
          <>
            <Permission id={PermissionsType.ViewOrders}
                        name={t('View orders, shipments, and invoices')}
                        description={t('Can view and track all purchases related to this organization.')}
                        disabled={isDisabled}
                        selected={isActive(PermissionsType.ViewOrders)}
                        onToggle={(selected) => togglePermission(selected, PermissionsType.ViewOrders)}/>
          </>
        }
        <Permission id={PermissionsType.ViewSupportCases}
                    name={t('View all support cases')}
                    description={t('Can view every support case that is raised by people at this organization.')}
                    disabled={isDisabled}
                    selected={isActive(PermissionsType.ViewSupportCases)}
                    onToggle={(selected) => togglePermission(selected, PermissionsType.ViewSupportCases)}/>
      </Section>

      {canPayOnAccount && 
        <Section>
          <SectionTitle>{t('Online purchases')}</SectionTitle>
          <SectionSubTitle>{t('Changes to this setting will take effect the next time this user signs in')}</SectionSubTitle>
          <Permission id={PermissionsType.PurchaseOnAccount}
                      name={t('Can purchase on behalf of the organization')}
                      description={t('Can place online orders using this organization.')}
                      disabled={isDisabled}
                      locked={!hasPermissions(PermissionsType.AccountOwner)}
                      selected={isActive(PermissionsType.PurchaseOnAccount)}
                      onToggle={(selected) => togglePermission(selected, PermissionsType.PurchaseOnAccount)}/>
        </Section>
      }

      {
        (!selfEditing || !hasPermissions(PermissionsType.AccountOwner)) &&
        <Actions>
          <Button variant={'tertiary'} onClick={cancel} disabled={submitting}>{t('Cancel')}</Button>
          <Button variant={'primary'} onClick={submit} disabled={!formUpdated} loading={submitting}>{t('Save changes')}</Button>
        </Actions>
      }
    </Wrapper>
  );
};
