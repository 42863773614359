import React, { useEffect } from 'react';
import styled from "styled-components";
import {
  COLORS,
  ComponentMStyling,
  ComponentTextStyle,
  HyperLink,
} from "@laerdal/life-react-components";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import AccessDeniedIllustration from '../../assets/403error.svg';


export const Wrapper = styled.div`
  box-sizing: border-box;
  background: ${COLORS.white};
  width: 320px;
  margin: 56px auto auto auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid ${COLORS.neutral_200};

  h3 {
    margin-top: 16px;
    margin-bottom: 0;
  }
  p {
    color: ${COLORS.neutral_600};
  
    margin: 16px 0 0 0;
    &:last-of-type {
      margin-bottom: 16px;
    }
  }
  a {
    margin-bottom: 16px;
    text-decoration: none;
    ${ComponentMStyling(ComponentTextStyle.Bold, null)}
  }
`;


export const AccessDeniedPage = () => {
  
  const {t} = useTranslation('AccessDenied');
  const navigate = useNavigate();

  
  useEffect(() => {
    //@ts-ignore
    posthog.capture?.('AccessDenied');
  }, []);
  
  
  
  return (
    <Wrapper>
      <img src={AccessDeniedIllustration} alt={t('Access denied')}/>
      <h3>{t('Access denied (403)')}</h3>

      <p>{t('You are not authorized to view this page.')}</p>
      <p>{t('Contact an account administrator at your organization for assistance.')}</p>

      {
        <HyperLink variant={'default'}
                   href={'/'}
                   target={'_self'}
                   onClick={(e) => {e.preventDefault(); navigate('/')}}>
          {t('Return to homepage')}
        </HyperLink>
      }

    </Wrapper>
  )
  
  
};