import {useTranslation} from "react-i18next";
import {
  COLORS,
  ComponentSStyling,
  ComponentTextStyle,
  ComponentXSStyling, HyperLink,
  SystemIcons,
  Table,
  Tag,
  Tile,
  TileHeaderProps
} from "@laerdal/life-react-components";
import {useDynamicSize} from "../../../util/DynamicSize";
import {useMemo} from "react";
import {TableColumn} from "@laerdal/life-react-components/dist/Table/TableTypes";
import styled from "styled-components";
import dayjs from "dayjs";
import {SubscriptionTransaction} from "../../../model/dto/organization/subscriptionTransaction";
import {TransactionStatus} from "../../../model/dto/organization/transactionStatus";

const TransactionCell = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const TransactionCellIcon = styled.div`
  width: 20px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const TransactionCellText = styled.div`
  display: flex;
  flex-direction: column;
  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.black)}
`;

const TransactionCellNote = styled.div`
  ${ComponentXSStyling(ComponentTextStyle.Regular, COLORS.neutral_600)}
`;

const InvoiceCellLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

interface Props {
  transactions: SubscriptionTransaction[];
}

export const SubscriptionTransactionsCard = ({transactions}: Props) => {
  const {t, i18n} = useTranslation('License');
  const size = useDynamicSize();

  const header = useMemo<TileHeaderProps>(() => ({
    title: t('Billing history'),
    subtitle: t('View your previous plan receipts and usage details.')
  }), []);

  const columns = useMemo<TableColumn[]>(() => ([
    {
      key: 'id',
      type: 'custom',
      sortable: true,
      name: t('Invoice'),
      customContent: (transaction: SubscriptionTransaction) =>
        <TransactionCell>
          <TransactionCellIcon>
            <SystemIcons.Document/>
          </TransactionCellIcon>
          <TransactionCellText>
            <span>{transaction.invoiceNumber}</span>
            <TransactionCellNote>{dayjs(transaction.dueDate).format('ll')}</TransactionCellNote>
          </TransactionCellText>
        </TransactionCell>
    }, {
      key: 'status',
      type: 'custom',
      name: t('Status'),
      customContent: (transaction: SubscriptionTransaction) =>
        transaction.status === TransactionStatus.Paid
          ? <Tag label={t('Paid')} variant={'positive'} icon={<SystemIcons.CheckMark/>}/>
          : transaction.status === TransactionStatus.Failed
            ? <Tag label={t('Overdue')} variant={'critical'}/>
            : transaction.status === TransactionStatus.Refunded
              ? <Tag label={t('Refunded')} variant={'neutral'}/>
              : <Tag label={t('To be paid')} variant={'neutral'}/>
    }, {
      key: 'dueDate',
      name: t('Due date'),
      type: 'custom',
      sortable: true,
      customContent: (transaction: SubscriptionTransaction) => dayjs(transaction.dueDate).format('ll'),
    }, {
      key: 'amount',
      name: t('Amount'),
      type: 'custom',
      justify: 'right',
      sortable: true,
      customContent: (transaction: SubscriptionTransaction) =>
        transaction.amount.toLocaleString(i18n.language, {
          style: 'currency',
          currency: transaction.currency
        })
    }, {
      key: 'invoiceUrl',
      name: '',
      type: 'custom',
      customContent: (transaction: SubscriptionTransaction) =>
        <>
          {
            transaction.url &&
            <HyperLink variant={'default'}
                       href={transaction.identifier}>
              <InvoiceCellLink>
                <HyperLink variant="default" target="_blank" href={transaction.url}>{t('View invoice')}</HyperLink>
                <SystemIcons.OpenNewWindow/>
              </InvoiceCellLink>
            </HyperLink>
          }
        </>
    }
  ]), [])

  return (
    <Tile header={header} size={size} id={'billing-history'}>
      <Table columns={columns} rows={transactions} border={true}/>
    </Tile>
  )
};