import axios from 'axios';
import {CaseCreateDto} from '../../model/dto/cases/caseCreateDto';
import {CaseDetailsDto} from '../../model/dto/cases/caseDetailsDto';
import { CaseEmailDto } from '../../model/dto/cases/caseEmailDto';
import {CasesDto} from '../../model/dto/cases/casesDto';
import AuthApi from './AuthApi';
import {BaseApi} from './BaseApi';
import { CaseStatuses } from '../../model/constants/CaseStatuses';
import { CaseCommentCreateDto } from '../../model/dto/cases/caseCommentCreateDto';

class CaseApi extends BaseApi {

  GetCases = async (
    limit: number,
    offset: number,
    lang: string,
    filterUsers?: boolean,
    status?: CaseStatuses[],
    orderBy?: string,
    orderType?: string,
    searchTerm?: string,
    signal?: AbortSignal,
  ): Promise<CasesDto> => {
    const cacheKey = `${filterUsers || ''}_${status || ''}_${orderBy || ''}_${orderType || ''}_${searchTerm || ''}_${limit || ''}_${offset || ''}_${lang || ''}`;

    let url = `${process.env.REACT_APP_API_URL}/Cases?limit=${limit}&offset=${offset}`;
    if (filterUsers) {
      url += `&filterUsers=${filterUsers}`;
    }
    if (status && status.length > 0) {
      for(let i = 0; i<status.length; i++)
      url += `&status=${status[i]}`;
    }
    if (orderBy) {
      url += `&orderBy=${orderBy}`;
    }
    if (orderType) {
      url += `&orderType=${orderType}`;
    }
    if (searchTerm) {
      url += `&searchTerm=${searchTerm}`;
    }
    if (lang) {
      url += `&lang=${lang}`;
    }

    const request = async () => axios.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${await AuthApi.getToken()}`,
          },
          signal: signal,
        },
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });

    return this.GetCached('cases', cacheKey, request);
  };

  GetCaseDetails = async (caseNumber: string, lang: string,): Promise<CaseDetailsDto> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/Cases/${caseNumber}?lang=${lang}`, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  };

  GetCaseEmails = async (caseNumber: string): Promise<CaseEmailDto[]> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/Cases/${caseNumber}/emails`, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  };

  CreateComment = async (dto: CaseCommentCreateDto): Promise<string> => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/Cases/comment`, dto, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        this.ClearCache();
        return response.data;
      });
  };


  CreateCase = async (dto: CaseCreateDto): Promise<string> => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/Cases`, dto, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        this.ClearCache();
        return response.data;
      });
  };

  ClearCache = () => {
    this.Clear('cases');
  };
}

export default new CaseApi();
