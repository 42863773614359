import {User} from '../model/dto/userProfile/user';

export const hasSubscriptionManagementPermissions = (user?: User, orgServiceId?: string) => {
  if (orgServiceId?.toLowerCase() == process.env.REACT_APP_KNOWLEDGE_HUB_SERVICE_ID?.toLocaleLowerCase()) {
    return false;
  }

  const isOwnerOfService =
    user?.currentOrganization?.services?.some((s) =>
      (!orgServiceId || s.id === orgServiceId) &&
      s.members?.some(
        (m) =>
          m.role?.id?.toLowerCase() === process.env.REACT_APP_SERVICE_OWNER_ROLE_ID?.toLowerCase() ||
          m.role?.parentRoleId?.toLowerCase() === process.env.REACT_APP_SERVICE_OWNER_ROLE_ID?.toLowerCase(),
      ),
    ) || false;
  const isAdminOfService =
    user?.currentOrganization?.services?.some((s) =>
      (!orgServiceId || s.id === orgServiceId) &&
      s.members?.some(
        (m) =>
          m.role?.id?.toLowerCase() === process.env.REACT_APP_SERVICE_ADMIN_ROLE_ID?.toLowerCase() ||
          m.role?.parentRoleId?.toLowerCase() === process.env.REACT_APP_SERVICE_ADMIN_ROLE_ID?.toLowerCase(),
      ),
    ) || false;

  return isOwnerOfService || isAdminOfService;
};

export const hasSubscriptionOwnerManagementPermissions = (user?: User, orgServiceId?: string) => {
  if (orgServiceId?.toLowerCase() == process.env.REACT_APP_KNOWLEDGE_HUB_SERVICE_ID?.toLocaleLowerCase()) {
    return false;
  }
  return user?.currentOrganization?.services?.some((s) =>
    (!orgServiceId || s.id === orgServiceId) &&
    s.members?.some(
      (m) =>
        m.role?.id?.toLowerCase() === process.env.REACT_APP_SERVICE_OWNER_ROLE_ID?.toLowerCase() ||
        m.role?.parentRoleId?.toLowerCase() === process.env.REACT_APP_SERVICE_OWNER_ROLE_ID?.toLowerCase(),
    ),
  ) || false;
}
