import { BREAKPOINTS, COLORS } from "@laerdal/life-react-components";
import styled from "styled-components";

export const BasicCard = styled.div`
  display: flex;
  flex-grow:1;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  gap: 16px;
  background: ${COLORS.white};
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  
  ${BREAKPOINTS.MEDIUM}{
    flex-basis: 648px;
  }
`;

export const SmallCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  gap: 16px;
  background: ${COLORS.white};
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  
  ${BREAKPOINTS.MEDIUM}{
    width: 300px;
  }
`;