import axios, {CancelToken} from 'axios';
import AuthApi from './AuthApi';
import {Session} from "../../model/dto/organization/session";
import {SubscriptionDetails} from "../../model/dto/organization/subscriptionDetails";
import {address} from "../../model/dto/userProfile/address";
import {ServicePrice} from "../../model/dto/organization/servicePrice";
import {SubscriptionPrice} from "../../model/dto/organization/subscriptionPrice";
import {SubscriptionOptions} from "../../model/dto/organization/subscriptionOptions";
import {SubscriptionAmendmentOrderResponse} from "../../model/dto/organization/subscriptionAmendmentOrderResponse";
import {SubscriptionAmendmentOrderStatus} from "../../model/dto/organization/subscriptionAmendmentOrderStatus";
import {BaseApi} from "./BaseApi";

class SubscriptionApi extends BaseApi {

  GetPrices = async (serviceId: string, accountNumber: string, address: address, signal?: AbortSignal): Promise<ServicePrice[]> => {
    let url = `${process.env.REACT_APP_API_URL}/subscription/prices`;
    url += `?serviceId=${serviceId}`;
    url += `&countryCode=${address.country.codeAlpha2}`;
    url += `&zipCode=${address.zipCode}`;

    if (address.state) {
      url += `&state=${address.state}`;
    }

    url += `&city=${address.city}`;

    url += `&accountNumber=${accountNumber}`;

    const request = async () => axios.get(url, {
      headers: {Authorization: `Bearer ${await AuthApi.getToken()}`, 'Content-Type': 'application/json'},
      signal: signal,
    })
      .then((response) => {
        return response?.data;
      });

    return this.GetCached('subscription_prices', url, request);
  };

  GetSessions = async (subscriptionId: string, token?: CancelToken): Promise<Session[]> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/session/${subscriptionId}`, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
        cancelToken: token,
      })
      .then((a) => a.data)
      .catch(() => []);
  };

  GetSubscriptionDetails = async (subscriptionId: string, signal?: AbortSignal): Promise<SubscriptionDetails> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/subscription/${subscriptionId}`, {
        headers: {Authorization: `Bearer ${await AuthApi.getToken()}`, 'Content-Type': 'application/json'},
        signal: signal,
      })
      .then((response) => {
        return response?.data;
      });
  };

  CreateAmendmentOrder = async (subscriptionId: string, options: SubscriptionOptions): Promise<SubscriptionAmendmentOrderResponse> => {
    return axios.post(`${process.env.REACT_APP_API_URL}/subscription/${subscriptionId}/amendment/order`,
      options,
      {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        return response?.data;
      });
  };

  CompleteAmendmentOrder = async (subscriptionId: string, sessionId: string): Promise<void> => {
    return axios.post(`${process.env.REACT_APP_API_URL}/subscription/${subscriptionId}/amendment/${sessionId}/complete`,
      {},
      {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        return response?.data;
      });
  }

  WaitForAmendmentSessionSuccess = async (subscriptionId: string, sessionId: string): Promise<void> => {
    return axios.get<void>(`${process.env.REACT_APP_API_URL}/subscription/${subscriptionId}/amendment/${sessionId}/poll`,
      {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        return response?.data;
      }).catch((e) => {
        return new Promise<void>((resolve) => {
          setTimeout(() => {
            resolve(this.WaitForAmendmentSessionSuccess(subscriptionId, sessionId));
          }, 1000);
        });
      });
  }

  CancelSubscription = async (subscriptionId: string): Promise<void> => {
    return axios.post(`${process.env.REACT_APP_API_URL}/subscription/${subscriptionId}/cancel`,
      {},
      {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        return response?.data;
      });
  };
}


export default new SubscriptionApi();
