import { BREAKPOINTS, Button, COLORS } from "@laerdal/life-react-components";
import styled from "styled-components";

const Wrapper = styled.div`

  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
  }
  flex-direction: column;

  display: flex;
  padding: 24px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid ${COLORS.neutral_200};
  background: ${COLORS.white};
`;

const First = styled.div`
  flex-direction:column;
  display: flex;
  padding: 24px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-grow: 1;
`;

const Second = styled.div`
  ${BREAKPOINTS.LARGE}{
    dispplay:block;
    align-self: start;
  }
  
  align-self: center;
  display: flex;
  justify-content: center;

  width: 320px;
  height: 180px;
  flex-shrink: 0;
`;

const Head = styled.h1`
  ${BREAKPOINTS.LARGE}{
    font-size: 30px;
  }
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;


const Text = styled.p`
  ${BREAKPOINTS.LARGE}{
    font-size: 20px;
  }

  font-size: 16px;
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export interface EmptyPageBoxProps {
  title: string;
  description: string;
  ilustrationPath?: string;
  buttonText?: string;
  buttonAction?: () => void;
}



export const EmptyPageBox: React.FunctionComponent<EmptyPageBoxProps> = ({
  title,
  description,
  ilustrationPath,
  buttonText,
  buttonAction
}) => {

  return(
    <Wrapper>
      <First>
        <Head>{title}</Head>

        <Text>{description}</Text>

        { !!buttonText && !!buttonAction &&
          <Button testId={'createFirstCase'}
                  id="create"
                  onClick={buttonAction}>
            {buttonText}
          </Button>
        }
      </First>
      { !!ilustrationPath &&
        <Second>
          <img src={ilustrationPath} />
        </Second>
      }
    </Wrapper>
  )
};