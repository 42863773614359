import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {
  COLORS,
  ComponentSStyling,
  ComponentTextStyle,
  LinearProgress,
  SystemIcons,
  Tile,
  TileFooterProps,
  TileHeaderProps
} from '@laerdal/life-react-components';
import {useNavigate} from 'react-router';
import {OrganizationService} from "../../../model/dto/organization/organizationService";
import {Session} from "../../../model/dto/organization/session";
import {Service} from "../../../model/dto/userProfile/service";
import {navigateByUrl} from "../../../util/navigation-helper";
import {useDynamicSize} from "../../../util/DynamicSize";
import dayjs from "dayjs";
import {OrganizationMember} from '../../../model/dto/organization/organizationMember';
import {SubscriptionStatus} from "../../../model/dto/organization/subscriptionStatus";
import {InstanceLimitType} from "../../../model/dto/userProfile/instanceLimitType";
import {LicenseInformationHelper} from "../helpers/LicenseInformationHelper";
import {PermissionsType, usePermissions} from "../../../hooks/Permissions";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;


const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 0;
`;

const ItemTitle = styled.span`
  ${ComponentSStyling(ComponentTextStyle.Bold, COLORS.black)}
`;

const ItemValue = styled.span`
  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.black)}
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 4px;

  .right {
    margin-left: auto;
  }
`;

interface Props {
  organizationService: OrganizationService;
  organizationMembers: OrganizationMember[];
  service: Service;
  sessions?: Session[];
  openInviteUsersModal: () => void;
}

const LicenseCard: React.FunctionComponent<Props> = ({
                                                       service,
                                                       organizationMembers,
                                                       organizationService,
                                                       sessions,
                                                       openInviteUsersModal,
                                                     }) => {
  const {t} = useTranslation('License');
  const navigate = useNavigate();
  const size = useDynamicSize();

  const {hasPermissions} = usePermissions();

  const members = useMemo(() => LicenseInformationHelper.ServiceMembers(organizationService), [organizationService]);
  //@ts-ignore
  const statusText = useMemo(() => LicenseInformationHelper.FormatStatus(t, organizationService?.subscription), [t, organizationService]);
  const canManage = useMemo(() => hasPermissions(PermissionsType.Subscription, organizationService.id), [organizationService, hasPermissions]);

  const hasExternalManagementUrl = useMemo(() => LicenseInformationHelper.HasExternalManagementUrl(organizationService), [organizationService]);
  const managementUrl = useMemo(() => LicenseInformationHelper.ManagementPath(organizationService), [organizationService]);
  const canAddUsers = useMemo(() => LicenseInformationHelper.CanAddUsers(service, organizationService.subscription, members), [service, organizationService, members]);
  //@ts-ignore
  const typeText = useMemo(() => LicenseInformationHelper.FormatPlanTier(t, service, organizationService?.subscription), [t, service, organizationService]);
  const willRenew = useMemo(() => LicenseInformationHelper.WillRenew(organizationService?.subscription), [organizationService]);
  const willExpire = useMemo(() => LicenseInformationHelper.WillExpire(organizationService?.subscription), [organizationService]);
  const isExpired = useMemo(() => LicenseInformationHelper.IsExpired(organizationService?.subscription), [organizationService]);
  const expiredOn = useMemo(() => LicenseInformationHelper.ExpiredOn(organizationService?.subscription), [organizationService]);
  const renewalDate = useMemo(() => LicenseInformationHelper.RenewalDate(organizationService?.subscription), [organizationService]);
  //@ts-ignore
  const licenseOwner = useMemo(() => LicenseInformationHelper.FormatLicenseOwner(t, service, organizationService, organizationMembers), [t, service, organizationService, organizationMembers]);
  const isUnlimited = useMemo(() => LicenseInformationHelper.IsUnlimited(organizationService?.subscription), [organizationService]);
  const userCount = useMemo(() => LicenseInformationHelper.UserCount(service, members, sessions, organizationMembers), [service, organizationMembers, sessions, members]);
  const maxUserCount = useMemo(() => LicenseInformationHelper.MaxUserCount(organizationService?.subscription) ?? userCount, [organizationService, userCount]);

  const header = useMemo(() => ({
    title: service.name,
    tag: {
      label: statusText,
      variant:
        organizationService?.subscription?.status === SubscriptionStatus.Active
          ? 'positive'
          : 'neutral'
    }
  } as TileHeaderProps), [statusText, organizationService, service]);

  const footer = useMemo(() => ({
    leftItem: canManage
      ? {
        componentType: 'button',
        variant: 'secondary',
        buttonText: t('View details'),
        onClick: () => {

          //@ts-ignore
          posthog.capture?.('AC Subscription Opened',{
            subscription_name: service.name,
            subscription_status:
              organizationService?.subscription?.status
              ? SubscriptionStatus[organizationService?.subscription?.status]
              : undefined,
          });
          navigateByUrl(managementUrl, navigate)
        },
      }
      : undefined,
    buttons: canManage && !hasExternalManagementUrl
      ? [{
        componentType: 'icon',
        icon: <SystemIcons.AddUser className={'add-user'}/>,
        disabled: !canAddUsers,
        variant: 'secondary',
        shape: 'circular',
        action: () => {
          //@ts-ignore
          posthog.capture?.('AC SubscriptionAddUsers Opened',{
            subscription_name: service.name,
          });

          openInviteUsersModal()
        },
      }]
      : []
  } as TileFooterProps), [canManage, hasExternalManagementUrl, canAddUsers, managementUrl]);

  return (
    <Tile header={header} size={size} footer={footer}>
      <Wrapper>
        <ItemContainer>
          <ItemTitle>{t('Plan')}</ItemTitle>
          <ItemValue>{typeText}</ItemValue>
        </ItemContainer>
        {
          (willRenew || willExpire || isExpired) &&
          <ItemContainer>
            {
              willRenew &&
              <>
                <ItemTitle>{t('Renews on')}</ItemTitle>
                <ItemValue>{dayjs(renewalDate).format('ll')}</ItemValue>
              </>
            }
            {
              willExpire &&
              <>
                <ItemTitle>{t('Expires on')}</ItemTitle>
                <ItemValue>{dayjs(organizationService?.subscription?.expirationDate).format('ll') ?? '-'}</ItemValue>
              </>
            }
            {
              isExpired &&
              <>
                <ItemTitle>{t('Expired on')}</ItemTitle>
                <ItemValue>{dayjs(expiredOn).format('ll') ?? '-'}</ItemValue>
              </>
            }
          </ItemContainer>
        }
        <ItemContainer>
          <ItemTitle>{t('License owner')}</ItemTitle>
          <ItemValue>{licenseOwner}</ItemValue>
        </ItemContainer>

        {
          service.instanceLimitType != InstanceLimitType.Role &&
          <>
            <ItemContainer>
              <ItemTitle>{t('License usage')}</ItemTitle>
            </ItemContainer>
            <LinearProgress size={size}
                            label={
                              isUnlimited
                                ? service.instanceLimitType == InstanceLimitType.Session
                                  ? t('{{count}} sessions', {count: userCount})
                                  : t('{{count}} users', {count: userCount})
                                : `${userCount}/${maxUserCount}`
                            }
                            value={userCount}
                            max={maxUserCount}/>
          </>
        }
      </Wrapper>
    </Tile>
  );
};
export default LicenseCard;
