import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {UserPermissionDetailsDto} from '../../model/dto/userDetails/UserPermissionDetailsDto';
import styled from 'styled-components';
import {
  BackButton,
  BREAKPOINTS,
  Button,
  LoadingIndicator,
  PageWidth,
  Size,
  ToastContext,
} from '@laerdal/life-react-components';
import {UserServiceDetails} from './components/UserServiceDetails';
import {UserDetails} from './components/UserDetails';
import {useTranslation} from 'react-i18next';
import {UserPermissions} from './components/UserPermissions';
import PermissionsApi from '../../services/api/PermissionsApi';
import {FailToastOptions, SuccessToastOptions} from '../../model/constants/ToastConstants';
import {useSelector} from 'react-redux';
import {selectUserProfile, setUserProfile} from '../../store/account/accountSlice';
import {PermissionsType, usePermissions} from '../../hooks/Permissions';
import {AdminPermissionsRequiredModal} from './components/AdminPermissionsRequiredModal';
import {RemoveUserModal} from './components/RemoveUserModal';
import SettingsApi from '../../services/api/SettingsApi';
import {Service} from '../../model/dto/userProfile/service';
import UserApi from "../../services/api/UserApi";
import {useAppDispatch} from "../../store/store";
import { StyledPageWidth } from '../_commonComponents/StyledComponents';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  ${BREAKPOINTS.MEDIUM}{
    gap: 32px;
    flex-direction: row;
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 416px;
  position: sticky;
  top: 16px;

  gap: 16px;
  ${BREAKPOINTS.MEDIUM}{
    gap: 32px;
  }
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${BREAKPOINTS.MEDIUM}{
    gap: 32px;
    margin-top: calc(32px + 48px);
  }
  
  .action{
    width: '100%';
  }
  
  ${BREAKPOINTS.MEDIUM}{
    .action{
      width: max-content;
    }
  }
`;

export const UserPermissionsPage = () => {

  const {t} = useTranslation('Users');
  const {addToast} = useContext(ToastContext);
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const {type} = useParams<{ type: 'invitation' | 'member' }>();
  const navigate = useNavigate();
  const profile = useSelector(selectUserProfile);

  const {hasPermissions} = usePermissions();

  const [loading, setLoading] = useState(true);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const [user, setUser] = useState<UserPermissionDetailsDto>();
  const [permissions, setPermissions] = useState<string[]>([]);
  const [services, setServices] = useState<Service[]>([]);

  const email = params.get('email');

  const selfEditing = email === profile?.email;

  useEffect(() => {
    if (!email) {
      navigate('/users');
      return;
    }

    let userRequest: Promise<UserPermissionDetailsDto>;

    switch (type) {
      case 'invitation':
        userRequest = PermissionsApi.GetInvitationsPermissionDetails(email);
        break;
      case 'member':
        userRequest = PermissionsApi.GetUserPermissionDetails(email);
        break;
      default:
        userRequest = Promise.reject();
    }

    userRequest = userRequest!.then((data) => {
      setUser(data);
      setPermissions(data.permissions ?? []);
      return data;
    });
    const servicesRequest = SettingsApi.GetServices().then(setServices);

    Promise.all([userRequest, servicesRequest]).catch(() => {
      addToast(t('A problem occurred – please try again'), FailToastOptions);
      navigate('/users');
    }).finally(() => setLoading(false));

  }, [email]);

  const removeMember = () => {
    navigate('/users');
  };
  
  const handleUserUpdate = (data: UserPermissionDetailsDto) => {
    setUser(data);
    if (selfEditing){
      UserApi.getUser().then((user) => dispatch(setUserProfile(user)));
    }
  }

  const isEditingOwner = permissions.includes(PermissionsType.AccountOwner);
  const isOwner = hasPermissions(PermissionsType.AccountOwner);

  return (
    <StyledPageWidth useMaxWidth={true} maxWidth={1600}>
      {
        loading &&
        <LoadingIndicator/>
      }
      {
        !loading &&
        <>
          <AdminPermissionsRequiredModal isOpen={showRemoveModal && isEditingOwner && !isOwner}
                                         onClose={() => setShowRemoveModal(false)}/>

          <RemoveUserModal isOpen={showRemoveModal && (!isEditingOwner || isOwner)}
                           user={user!}
                           onRemove={removeMember}
                           onClose={() => setShowRemoveModal(false)}/>

          <Content>
            <LeftSide>
              <BackButton size={Size.Small} onClick={() => navigate('/users')}>{t('Back to People')}</BackButton>
              <UserDetails 
                user={user!} 
                onUpdateUser={handleUserUpdate} 
                type={type!} 
                showRemoveUserButton={type === 'member' && !selfEditing}
                removeUserAction={() => setShowRemoveModal(true)}
                />
            </LeftSide>
            <RightSide>
              <UserServiceDetails services={services}
                                  user={user!}
                                  onUpdateUser={handleUserUpdate}/>

              <UserPermissions type={type!}
                               user={user!}
                               onUpdateUser={handleUserUpdate}/>
            
            </RightSide>
          </Content>
        </>
      }
    </StyledPageWidth>
  );
};