import styled from "styled-components";
import {
  Banner,
  BREAKPOINTS,
  Button,
  COLORS,
  ContentIcons,
  defaultOnMouseDownHandler,
  HorizontalCard,
  HyperLink,
  PageWidth,
  Size,
  SystemIcons,
} from "@laerdal/life-react-components";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import {
  selectUserProfile,
  selectUserWebshopMarketCode,
  selectUserWebshopMarketCountry,
} from "../../store/account/accountSlice";
import { useTranslation } from "react-i18next";
import { useMediaMatch } from "rooks";
import { OrganizationDetails } from "./components/OrganizationDetails";
import { PermissionsType, usePermissions } from "../../hooks/Permissions";
import { useFeatures } from "../../hooks/Features";
import { FeatureNames } from "../../model/constants/FeatureConstants";
import { Profile } from "./components/Profile";
import { selectFeatures } from "../../store/features/featuresSlice";
import {
  isEnableCountry,
  isEpiCountry,
} from "../../services/helpers/marketHelpers";
import { StyledPageWidth } from "../_commonComponents/StyledComponents";

const Row = styled.div`
  display: flex !important;
  flex-direction: column;

  gap: 16px;
  ${BREAKPOINTS.MEDIUM} {
    box-sizing: border-box;
    width: 100%;
    flex-direction: row;
    gap: 32px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.h1`
  margin: 0;

  ${BREAKPOINTS.MEDIUM} {
    display: flex;
  }
`;

const ChangeOrganization = styled.div`
  align-items: center;
  display: none;

  ${BREAKPOINTS.MEDIUM} {
    display: flex;
    gap: 8px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  ${BREAKPOINTS.MEDIUM} {
    gap: 32px;
  }
`;

const NavigationItems = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;

  ${BREAKPOINTS.MEDIUM} {
    gap: 16px;
  }
`;

export const OverviewPage = () => {
  const { t } = useTranslation(["Overview", "License"]);
  const user = useSelector(selectUserProfile);
  const marketCountry = useSelector(selectUserWebshopMarketCountry);
  const marketCode = useSelector(selectUserWebshopMarketCode);
  const navigate = useNavigate();
  const { hasPermissions } = usePermissions();

  const organization = user?.currentOrganization;

  const isMediumScreen = useMediaMatch(
    BREAKPOINTS.MEDIUM.replace("@media ", "")
  );

  return (
    <StyledPageWidth useMaxWidth={true} maxWidth={1600}>
      <Row>
        {!isMediumScreen && (
          <Button
            variant={"tertiary"}
            onClick={() => {
              //@ts-ignore
              posthog.capture?.("OverviewPage Profile");
              navigate("/profile");
            }}
            icon={<SystemIcons.User />}
            width="max-content"
          >
            {t("Manage Profile")}
          </Button>
        )}
        <Wrapper>
          <Header>
            <Title>{organization?.name}</Title>
            {(user?.organizations?.length ?? 0) > 1 &&
              user?.switchOrganizationEnabled && (
                <ChangeOrganization>
                  <SystemIcons.Institute />
                  <HyperLink
                    variant="default"
                    href={"#"}
                    onMouseDown={defaultOnMouseDownHandler}
                    onClick={(e) => {
                      //@ts-ignore
                      posthog.capture?.(
                        "OverviewPage ChangeOrganization"
                      );
                      e.preventDefault();
                      window.globalNav.toggleChangeOrganizationView(
                        !isMediumScreen
                      );
                    }}
                  >
                    {t("Change organization")}
                  </HyperLink>
                </ChangeOrganization>
              )}
          </Header>
          <OrganizationDetails />
          <NavigationItems>
            <HorizontalCard
              icon={<ContentIcons.TeamWork />}
              title={t("People")}
              data-testid="PeopleManagementLink"
              description={t("View people with access to this account")}
              action={() => {
                //@ts-ignore
                posthog.capture?.("OverviewPage Users");
                navigate("/users");
              }}
              actions={[
                {
                  componentType: "icon",
                  icon: <SystemIcons.ChevronRight />,
                  action: () => {
                    //@ts-ignore
                    posthog.capture?.("OverviewPage Users");
                    navigate("/users");
                  },
                },
              ]}
            />

            {hasPermissions(PermissionsType.Subscription) && (
              <HorizontalCard
                icon={<ContentIcons.Note />}
                title={t("Subscriptions & Licenses")}
                data-testid="SubsAndLicensesLink"
                description={t(
                  "Manage access to digital products and services.",
                  { ns: "License" }
                )}
                action={() => {
                  //@ts-ignore
                  posthog.capture?.("OverviewPage Licenses");
                  navigate("/licenses");
                }}
                actions={[
                  {
                    componentType: "icon",
                    icon: <SystemIcons.ChevronRight />,
                    action: () => {
                      //@ts-ignore
                      posthog.capture?.("OverviewPage Licenses");
                      navigate("/licenses");
                    },
                  },
                ]}
              />
            )}

            {hasPermissions(PermissionsType.AccountOwner) &&
              !!marketCountry && (
                <>
                  {isEpiCountry(marketCountry) && (
                    <HorizontalCard
                      icon={<ContentIcons.Buildings />}
                      title={t("Address book")}
                      data-testid="AddressBookLink"
                      description={t("Manage delivery addresses.")}
                      action={() => {
                        //@ts-ignore
                        posthog.capture?.("OverviewPage Licenses");
                        navigate("/addresses");
                      }}
                      actions={[
                        {
                          componentType: "icon",
                          icon: <SystemIcons.ChevronRight />,
                          action: () => {
                            //@ts-ignore
                            posthog.capture?.("OverviewPage Licenses");
                            navigate("/addresses");
                          },
                        },
                      ]}
                    ></HorizontalCard>
                  )}
                  {isEnableCountry(marketCountry) && (
                    <HorizontalCard
                      icon={<ContentIcons.Buildings />}
                      title={t("Address book")}
                      data-testid="AddressBookLink"
                      description={t("Manage delivery addresses.")}
                      action={() =>
                        window.open(
                          new URL(
                            `/${marketCode}/Account/ShippingAddresses.aspx`,
                            process.env.REACT_APP_ECOMMERCE_BASE_URL
                          ).href,
                          "_blank"
                        )
                      }
                      actions={[
                        {
                          componentType: "icon",
                          icon: <SystemIcons.ChevronRight />,
                          action: () =>
                            window.open(
                              new URL(
                                `/${marketCode}/Account/ShippingAddresses.aspx`,
                                process.env.REACT_APP_ECOMMERCE_BASE_URL
                              ).href,
                              "_blank"
                            ),
                        },
                      ]}
                    ></HorizontalCard>
                  )}
                </>
              )}

            {hasPermissions(PermissionsType.AccountOwner) &&
              !!marketCountry && (
                <HorizontalCard
                  icon={<ContentIcons.ProvidersOrders />}
                  title={
                    isEpiCountry(marketCountry)
                      ? t("Payment and Billing")
                      : t("Billing")
                  }
                  data-testid="PaymentAndBillingLink"
                  description={
                    isEpiCountry(marketCountry)
                      ? t("Manage payment methods")
                      : t("Billing information")
                  }
                  action={() => {
                    //@ts-ignore
                    posthog.capture?.("OverviewPage Billing");
                    navigate("/billing");
                  }}
                  actions={[
                    {
                      componentType: "icon",
                      icon: <SystemIcons.ChevronRight />,
                      action: () => {
                        //@ts-ignore
                        posthog.capture?.("OverviewPage Billing");
                        navigate("/billing");
                      },
                    },
                  ]}
                />
              )}
          </NavigationItems>

          <Banner
            icon={<SystemIcons.Help />}
            size={Size.Medium}
            linkText={t("Go to Laerdal Connect")}
            link={process.env.REACT_APP_CONNECT_URL!}
          >
            {t("Looking for your products and services?")}
          </Banner>
        </Wrapper>
        {isMediumScreen && <Profile />}
      </Row>
    </StyledPageWidth>
  );
};
