import { Tag, TagVariants } from "@laerdal/life-react-components";
import {useTranslation} from 'react-i18next';

type ShipmentStatusProps = {
    status: string ;
    statusTranslated: string ;
  };

const ShipmentStatus = ({status, statusTranslated}: ShipmentStatusProps ) => { 
    const {t} = useTranslation('Shipment');

    const GetStatusVariant = (status :string) : TagVariants => {
        if(status == "Delivered") return 'positive';
        else if(status == "Delayed") return 'warning';
        return 'neutral';
      }
      
    return (
        !!statusTranslated ? <Tag label={statusTranslated} variant={GetStatusVariant(status)} /> : <></>
      );
}

export default ShipmentStatus;