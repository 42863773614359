import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';

import moment from 'moment';
import 'moment/min/locales';

i18n
  .use(backend)
  .use(initReactI18next)
  .init({
    lng: 'en',
    debug: false,
    load: 'currentOnly',
    cleanCode: true,
    keySeparator: false,
    fallbackLng: 'en',
    compatibilityJSON: 'v3',
    defaultNS: 'Common',
    interpolation: {
      escapeValue: false,
      format: function (value: any, format: any, lng: any) {
        if (value instanceof Date) {
          return moment(value).format(format);
        }
        return value;
      },
    },
    react: {
      useSuspense: true,
    },
    nonExplicitSupportedLngs:false,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    }
  }
);

i18n.on('languageChanged', (lng: string) => {
  localStorage.setItem('userLanguage', lng);
});

export default i18n;
