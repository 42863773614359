import React, {useContext, useState} from 'react';
import {IdPConfigDto} from '../../../model/dto/idP/IdPConfig';
import {ModalDialog, Size, TextField, ToastContext} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import SAMLApi from '../../../services/api/SAMLApi';
import {FailToastOptions, SuccessToastOptions} from '../../../model/constants/ToastConstants';

interface Props {
  isModalOpen: boolean;
  onClose: () => void;
  onImport: (config: IdPConfigDto) => void;
}

const ImportFromUrlModal = ({isModalOpen, onClose, onImport}: Props) => {

  const {t} = useTranslation('SAML');
  const {addToast} = useContext(ToastContext);

  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setUrl('');
    setIsLoading(false);
    onClose();
  };

  const submit = () => {
    setIsLoading(true);
    SAMLApi.ImportIdPUrl(url)
      .then(onImport)
      .then(() => addToast && addToast(t('IdP settings imported successfully'), SuccessToastOptions))
      .catch(() => addToast && addToast(t('Provided url does not contain valid metadata'), FailToastOptions))
      .finally(close);
  };

  return (
    <ModalDialog isModalOpen={isModalOpen}
                 closeModalAndClearInput={close}
                 closeAction={close}
                 submitAction={submit}
                 size={Size.Medium}
                 title={t('Import settings from metadata url')}
                 buttons={[
                   {
                     type: 'button',
                     variant: 'tertiary',
                     text: t('Cancel'),
                     action: close,
                     disabled: isLoading,
                   },
                   {
                     type: 'button',
                     variant: 'primary',
                     text: t('Import'),
                     loading: isLoading,
                     action: submit,
                     disabled: !url,
                   },
                 ]}>
      <TextField value={url} onChange={setUrl} placeholder={'Url'}/>
    </ModalDialog>
  );
};
export default ImportFromUrlModal;