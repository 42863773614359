export interface CountrySupportInfo {
    country: string,
    phone: string,
    email: string,
  }

export const CountriesSupportInfo: CountrySupportInfo[] = [
    {
        country: "Afghanistan",
        phone: "+92 21 343 02 331",
        email: "syed.waqar@medinostic.com"
    },
    {
        country: "Albania",
        phone: "+381 21 46 81 90",
        email: "ljilijana.lainovic@mit-med.co.rs"
    },
    {
        country: "Algeria",
        phone: "+33 47 25 20 252",
        email: "info.france@laerdal.com"
    },
    {
        country: "Andorra",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.com"
    },
    {
        country: "American Samoa",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.com"
    },
    {
        country: "Angola",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za "
    },
    {
        country: "Anguilla",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com"
    },
    {
        country: "Antigua and Barbuda",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Argentina",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Armenia",
        phone: "+4751511700",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Aruba",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com"
    },
    {
        country: "Australia",
        phone: "+61 1800 331 565",
        email: "CustomerService.ANZ@laerdal.com"
    },
    {
        country: "Austria",
        phone: "+43 (1) 31 52 384",
        email: "helpdesk.de@laerdal.com"
    },
    {
        country: "Azerbaijan",
        phone: "+47 51 51 17 00",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Bahamas",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com"
    },
    {
        country: "Bangladesh",
        phone: "+91 44 4261 4773",
        email: "Customer.service@laerdal.co.in"
    },
    {
        country: "Barbados",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Belgium",
        phone: "+32 (0)2 253 36 96",
        email: "info@laerdal.be"
    },
    {
        country: "Belize",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Benin",
        phone: "+44 1634 370 389",
        email: "seyi.oyesola@practiceventures.com"
    },
    {
        country: "Bermuda",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com"
    },
    {
        country: "Bhutan",
        phone: "+91 44 4261 4773",
        email: "Customer.service@laerdal.co.in"
    },
    {
        country: "Bolivia",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com"
    },
    {
        country: "Botswana",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Brazil",
        phone: "+55 11 4193-8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Brunei Darussalam",
        phone: "(65) 62821912",
        email: "sgcustomer.service@laerdal.com"
    },
    {
        country: "Burkina Faso",
        phone: "+44 1634 370 389",
        email: "seyi.oyesola@practiceventures.com"
    },
    {
        country: "Burundi",
        phone: "+27 11 79 22 190",
        email: " allanp@medtec.co.za "
    },
    {
        country: "Cambodia",
        phone: "(65) 62821912",
        email: "sgcustomer.service@laerdal.com"
    },
    {
        country: "Cameroon",
        phone: "+44 1634 370 389",
        email: "seyi.oyesola@practiceventures.com"
    },
    {
        country: "Canada",
        phone: "(English) 416-298-9600 | (Français) 1-800-265-9987",
        email: "savelives@laerdal.ca"
    },
    {
        country: "Chad",
        phone: "+27 11 79 22 190",
        email: " allanp@medtec.co.za "
    },
    {
        country: "Chile",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "China (Beijing)",
        phone: "010-84417726",
        email: "Sales@laerdal.cn"
    },
    {
        country: "China (Hangzhou)",
        phone: "86-571-8801 1705",
        email: "Customer.Service@laerdal.cn"
    },
    {
        country: "Christmas Island",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Colombia",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Comoros",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Congo",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Costa Rica",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Cote D'Ivoire",
        phone: "+33 47 25 20 252",
        email: "info.france@laerdal.com"
    },
    {
        country: "Cuba",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Curacao",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Denmark",
        phone: "+45 80 333 112",
        email: "kundeservice@laerdal.dk"
    },
    {
        country: "Djibouti",
        phone: "+33 47 25 20 252",
        email: "info.france@laerdal.com"
    },
    {
        country: "Dominica",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Dominican Rep.",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Ecuador",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "El Salvador",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Equatorial Guinea",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Eritrea",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Ethiopia",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Faroe Islands",
        phone: "+45 80 333 112",
        email: "kundeservice@laerdal.dk"
    },
    {
        country: "Fiji",
        phone: "+55 1800  331  565",
        email: "customer.service@laerdal.com.au"
    },
    {
        country: "Finland",
        phone: "+358 96 12 99 80",
        email: "asiakaspalvelu@laerdal.no"
    },
    {
        country: "France",
        phone: "+33 47 25 20 252",
        email: "info.france@laerdal.com"
    },
    {
        country: "French Guiana",
        phone: "+33 47 25 20 252",
        email: "info.france@laerdal.com"
    },
    {
        country: "French Polynesia",
        phone: "+33 47 25 20 252",
        email: "info.france@laerdal.com"
    },
    {
        country: "French soutern territories",
        phone: "+33 47 25 20 252",
        email: "info.france@laerdal.com"
    },
    {
        country: "Gabon",
        phone: "+33 47 25 20 252",
        email: "info.france@laerdal.com"
    },
    {
        country: "Gambia",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Germany",
        phone: "+49 89 86 49 54-0",
        email: "helpdesk.de@laerdal.com"
    },
    {
        country: "Ghana",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Gibraltar",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Greenland",
        phone: "+45 80 333 112",
        email: "laerdal.denmark@laerdal.no"
    },
    {
        country: "Grenada",
        phone: "+55 11 4193-8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Guadeloupe",
        phone: "+33 47 25 20 252",
        email: "info.france@laerdal.com"
    },
    {
        country: "Guam",
        phone: "877-LAERDAL (523-7325)",
        email: "customerservice@laerdal.com"
    },
    {
        country: "Guatemala",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Guernsey",
        phone: "+44 16 89 87 66 34",
        email: "customer.service@laerdal.co.uk"
    },
    {
        country: "Guinea",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Guinea-Bissau",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Guyana",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Haiti",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Honduras",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Hong Kong (China)",
        phone: "+852-31682518",
        email: ""
    },
    {
        country: "Iraq",
        phone: "+47 51 51 17 00",
        email: "customerservice-distributors@laerdal.com "
    },
    {
        country: "Ireland",
        phone: "+353(0)18 307499",
        email: "mailto:sales@cardiac-services.com"
    },
    {
        country: "Italy",
        phone: "+39 05 13 55 587",
        email: "laerdal.italy@laerdal.no"
    },
    {
        country: "Ivory Coast",
        phone: "+33 47 25 20 252",
        email: "info.france@laerdal.com"
    },
    {
        country: "Jamaica",
        phone: "+33 47 25 20 252",
        email: "info.france@laerdal.com"
    },
    {
        country: "Japan",
        phone: "0120-309-060",
        email: "CustomerService.jp@laerdal.com"
    },
    {
        country: "Korea",
        phone: "82-2-6714-8800",
        email: "csi@laerdal.co.kr"
    },
    {
        country: "Lao People's Democratic Republic",
        phone: "+65 6282 1912",
        email: "sgcustomer.service@laerdal.com"
    },
    {
        country: "Lesotho",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Liberia",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Libya",
        phone: "+47 51 51 17 00",
        email: "customerservice-distributors@laerdal.com "
    },
    {
        country: "Liechtenstein",
        phone: "+41 58 7487474",
        email: "info.ch@draeger.com"
    },
    {
        country: "Luxembourg",
        phone: "+370 52 37 56 75 ",
        email: "kristina@amis.lt"
    },
    {
        country: "Macao",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Madagascar",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Malawi",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Malaysia",
        phone: "+603 7782-7002",
        email: "mycustomer.service@laerdal.com"
    },
    {
        country: "Maldives",
        phone: "+91 44 4261 4773",
        email: "Customer.service@laerdal.co.in"
    },
    {
        country: "Mali",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Marshall Islands",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Martinique",
        phone: "+33 47 25 20 252",
        email: "info.france@laerdal.com"
    },
    {
        country: "Mauritania",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Mayotte",
        phone: "+33 47 25 20 252",
        email: "info.france@laerdal.com"
    },
    {
        country: "Mexico",
        phone: "+52 55 9225 2578",
        email: "comercial.mexico@laerdal.com"
    },
    {
        country: "Micronesia Federated States of",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Moldova",
        phone: "+40 264 430891",
        email: "office@medimpactco.ro"
    },
    {
        country: "Monaco",
        phone: "+33 47 25 20 252",
        email: "info.france@laerdal.com"
    },
    {
        country: "Mongolia",
        phone: "+86-800-810-12",
        email: "Customer.Service@laerdal.cn"
    },
    {
        country: "Montenegro",
        phone: "+381 21 46 81 90 ",
        email: "ljilijana.lainovic@mit-med.co.rs"
    },
    {
        country: "Morocco",
        phone: "+33 47 25 20 252",
        email: "info.france@laerdal.com"
    },
    {
        country: "Mozambique",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Myanmar",
        phone: "+65 6282 1912",
        email: "sgcustomer.service@laerdal.com"
    },
    {
        country: "Namibia",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Nauru",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Netherlands",
        phone: "+31 (0)33 422 0600",
        email: "info@laerdal.nl"
    },
    {
        country: "New Caledonia",
        phone: "+33 47 25 20 252",
        email: "info.france@laerdal.com"
    },
    {
        country: "New Zealand",
        phone: "+64 0800 523 732",
        email: "CustomerService.ANZ@laerdal.com"
    },
    {
        country: "Nicaragua",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Niger",
        phone: "+44 1634 370 389",
        email: "seyi.oyesola@practiceventures.com"
    },
    {
        country: "Nigeria",
        phone: "+44 1634 370 389",
        email: "seyi.oyesola@practiceventures.com"
    },
    {
        country: "Niue",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Norfolk Island",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Northern Mariana Islands",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Norway",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Palau",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Palestine",
        phone: "970-2-2971253",
        email: "labtech@palnet.com"
    },
    {
        country: "Panama",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Papua New Guinea",
        phone: "+55 1800  331  565",
        email: "customer.service@laerdal.com.au"
    },
    {
        country: "Paraguay",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Peru",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Puerto Rico",
        phone: "+974 445 66 100",
        email: "jomayah@sharq.qa"
    },
    {
        country: "Rèunion",
        phone: "+33 47 25 20 252",
        email: "info.france@laerdal.com"
    },
    {
        country: "Rwanda",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Saint Kitts and Nevis",
        phone: "+974 445 66 100",
        email: "jomayah@sharq.qa"
    },
    {
        country: "Saint Lucia",
        phone: "+974 445 66 100",
        email: "jomayah@sharq.qa"
    },
    {
        country: "Saint Piette and Miquelon",
        phone: "+33 47 25 20 252",
        email: "info.france@laerdal.com"
    },
    {
        country: "Saint Vincent and the Grenadines",
        phone: "+974 445 66 100",
        email: "jomayah@sharq.qa"
    },
    {
        country: "Samoa",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "San Marino",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Sao Tome and Principe",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Senegal",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Serbia",
        phone: "+381 21 46 81 90 ",
        email: "ljilijana.lainovic@mit-med.co.rs"
    },
    {
        country: "Seychelles",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Sierra Leone",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Singapore",
        phone: "+65 6282 1912",
        email: "sgcustomer.service@laerdal.com"
    },
    {
        country: "Solomon Islands",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Somalia",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "South Sudan",
        phone: "+47 51 51 17 00",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Spain",
        phone: "+34 916591754",
        email: "laerdal.spain@laerdal.com"
    },
    {
        country: "Sudan",
        phone: "+47 51 51 17 00",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Suriname",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Swaziland",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Sweden",
        phone: "+46 85 56 14 610",
        email: "kontakt.sverige@laerdal.com"
    },
    {
        country: "Switzerland",
        phone: "+41 58 7487474",
        email: "info.ch@draeger.com"
    },
    {
        country: "Taiwan",
        phone: "852-31682518",
        email: ""
    },
    {
        country: "Tajikistan",
        phone: "+47 51 51 17 00",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Tanzania",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Togo",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Tokelau",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Tonga",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Trinidad and Tobago",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Tunisia",
        phone: "+216 21964778",
        email: "tkallel.eps@gmail.com"
    },
    {
        country: "Turkmenistan",
        phone: "+47 51 51 17 00",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Tuvalu",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Uganda",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "United Arab States",
        phone: "971526072868",
        email: "thiru@mpchealthcare.com"
    },
    {
        country: "United Kingdom",
        phone: "+44 16 89 87 66 34",
        email: "customer.service@laerdal.co.uk"
    },
    {
        country: "United States of America",
        phone: "877-LAERDAL (523-7325)",
        email: "collections@laerdal.com"
    },
    {
        country: "United States",
        phone: "877-LAERDAL (523-7325)",
        email: "customerservice@laerdal.com"
    },
    {
        country: "USA",
        phone: "877-LAERDAL (523-7325)",
        email: "customerservice@laerdal.com"
    },
    {
        country: "Uruguay",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Uzbekistan",
        phone: "+47 51 51 17 00",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Vanuatu",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Venezuela",
        phone: "+55 11 4193 8007",
        email: "comercial@laerdal.com "
    },
    {
        country: "Virgin Islands (British)",
        phone: "+47 51 51 17 00",
        email: "laerdal.norge@laerdal.no"
    },
    {
        country: "Wallis and Futuna",
        phone: "+33 47 25 20 252",
        email: "info.france@laerdal.com"
    },
    {
        country: "Yemen",
        phone: "+47 51 51 17 00",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Zambia",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Zimbabwe",
        phone: "+27 11 79 22 190",
        email: "allanp@medtec.co.za"
    },
    {
        country: "Serbia",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Bahrain",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Belarus",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Bosnia and Herzegovina",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Bulgaria",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Croatia",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Cyprus",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Czechia",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Egypt",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Estonia",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Georgia",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Greece",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Hungary",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Iceland",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "India",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Indonesia",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Iran",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Israel",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Jordan",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Kazakhstan",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Kenya",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Kuwait",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Kyrgyzstan",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Latvia",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Lebanon",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Lithuania",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Republic of North Macedonia",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Malta",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Mauritius",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Nepal",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Oman",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Pakistan",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Philippines",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Poland",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Portugal",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Qatar",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Romania",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Russian Federation",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Saudi Arabia",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Slovakia",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Slovenia",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "South Africa",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Sri Lanka",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Syrian Arab Republic",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Thailand",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Turkey",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Ukraine",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "United Arab Emirates",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
    {
        country: "Vietnam",
        phone: "",
        email: "customerservice-distributors@laerdal.com"
    },
]