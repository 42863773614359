import { Button, COLORS, ComponentM, ComponentS, ComponentSStyling, ComponentTextStyle, ProfileButton, Size, SystemIcons } from "@laerdal/life-react-components";
import moment from "moment";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CaseEmailDto } from "../../model/dto/cases/caseEmailDto";
import { Ref, createRef, useLayoutEffect, useState } from "react";
import React from "react";
import dompurify from "dompurify";

const CreateIcon = styled.div`
  pointer-events: none;

  
  display: block;
`;


export const EmailCard = styled.div`
  position: relative;
  overflow: hidden;

  
  background-color: ${COLORS.white};
  padding: 12px 0;

  border-top:1px solid  ${COLORS.neutral_200}
`;

export const FirstLine = styled.div`
    display:flex;
    justify-content: space-between;
    gap: 16px;
`;

export const Paragraph = styled.p`
    overflow-wrap: anywhere;
`;

export const Container = styled.div`
    display:flex;
`;

export const FirstColumn = styled.div`
    display:flex;
`;

export const First = styled.div`
    display:flex;
`;
export const Second = styled.div`
        flex-grow: 1;
    display:flex;
    flex-direction:column;
    padding: 12px 16px;
`;

export const ShowButton = styled(Button)`
    align-self:start;
`;

export const HtmlContent = styled.div`
    font-size: 16px;

    p {
        font-size: 16px;
    }

    p.acknowledgement {
        display: none;
    }
`;

interface ExpandableProps {
    expanded: boolean;
    isOverflowY: boolean;
}

export const Expandable = styled.div<ExpandableProps>`
  ${(props) => (!props.expanded && 'max-height: 128px;')}
  ${(props) => (!props.expanded && 'overflow-y: hidden;')}
  
  ${(props) => (props.isOverflowY && !props.expanded && 'background: -webkit-linear-gradient(top, black, black 50%, #ffffffbf);')}
  ${(props) => (props.isOverflowY && !props.expanded && 'background: -o-linear-gradient(bottom, black, black 50%, #ffffffbf);')}
  ${(props) => (props.isOverflowY && !props.expanded && 'background: -moz-linear-gradient(bottom, black, black 50%, #ffffffbf);')}
  ${(props) => (props.isOverflowY && !props.expanded && 'background: linear-gradient(to bottom, black, black 50%, #ffffffbf); ')}
  ${(props) => (props.isOverflowY && !props.expanded && '-webkit-background-clip: text;')}
  ${(props) => (props.isOverflowY && !props.expanded && '-webkit-text-fill-color: transparent;')}
`;

type CaseEmailProps = {
    emailData: CaseEmailDto;
};

const EmailContent = styled.div`
  ${ComponentSStyling(ComponentTextStyle.Regular, null)}
`;

const CaseEmail = ({ emailData }: CaseEmailProps) => {
    const { t } = useTranslation('Cases');
    const [expanded, setExpanded] = useState<boolean>(false);
    const [isOverflowY, setIsOverflowY] = useState<boolean>(false)
    const refOuter = React.useRef<HTMLDivElement>(null)
    const refInner = React.useRef<HTMLDivElement>(null)

    const isHtml = !!emailData.htmlBody;
    const htmlContent = dompurify.sanitize(emailData.htmlBody ?? '')
                            .replaceAll('<img', '<img onerror="this.remove();" ')
                            .replaceAll('white-space:nowrap;',  '')
                            .replaceAll('&nbsp;',  ' ');
                            
    useLayoutEffect(() => {
        const { current: outer } = refOuter;
        const { current: inner } = refOuter;

        if (!!outer && !!inner) {
            const hasOverflowY = inner.scrollHeight > outer.clientHeight
            if (hasOverflowY !== isOverflowY) {
                setIsOverflowY(hasOverflowY)
            }
        }
    }, [emailData, expanded, refOuter, refInner])

    const getInitials = (fromName?: string) => {
        if (!fromName) return "";
        if (fromName.indexOf('@') >= 0) return "";

        const nameParts = fromName.split(" ");

        if (nameParts.length == 1) return nameParts[0][0];

        return nameParts[0][0] + nameParts[1][0];
    };

    return (
        <EmailCard >
            <Container>
                <First>
                    <CreateIcon>
                        <ProfileButton icon={<SystemIcons.User size="36px" />} onClick={() => {}} initials={getInitials(emailData.fromName)} />
                    </CreateIcon>
                </First>
                <Second>
                    <FirstLine>
                        <FirstColumn>
                            <ComponentM textStyle={ComponentTextStyle.Bold}>{emailData.fromName}</ComponentM>
                        </FirstColumn>
                        { !isNaN(new Date(emailData.messageDate).getTime()) &&
                            <ComponentS textStyle={ComponentTextStyle.Regular} color={COLORS.neutral_600}>
                                {moment(emailData.messageDate).format('ll LT')}
                            </ComponentS>
                        }
                    </FirstLine>

                    <Expandable ref={refOuter} expanded={expanded} isOverflowY={isOverflowY}>
                        <EmailContent ref={refInner}>
                            {isHtml &&
                                <HtmlContent dangerouslySetInnerHTML={{ __html: htmlContent.replace('cellpadding:0;border:0;cellspacing:0;display:table;width:100%;table-layout:fixed;border-collapse:seperate;float:none;','cellpadding:0;border:0;cellspacing:0;display:none;width:100%;table-layout:fixed;border-collapse:seperate;float:none;') ?? '' }} />
                            }
                            {!isHtml &&
                                emailData.textBody?.split(/\r?\n/).filter(x => x).map(paragraph =>
                                    <Paragraph>{paragraph}</Paragraph>
                                )}
                        </EmailContent>
                    </Expandable>

                    {isOverflowY &&
                        <ShowButton
                            onClick={() => {
                                //@ts-ignore
                                posthog?.capture('CaseEmail Expand');
                                setExpanded(true)
                            }}
                            variant="tertiary"
                            size={Size.Small}>{t('Show more')}</ShowButton>
                    }
                    {expanded &&
                        <ShowButton
                            onClick={() => {
                                //@ts-ignore
                                posthog.capture?.('CaseEmail Collaps');
                                setExpanded(false)
                            }}
                            variant="tertiary"
                            size={Size.Small}>{t('Show less')}</ShowButton>
                    }
                </Second>
            </Container>
        </EmailCard>
    );
}

export default CaseEmail;
