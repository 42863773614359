import React from "react";
import {
  ComponentMStyling,
  ComponentSStyling, 
} from "@laerdal/life-react-components";
import {BREAKPOINTS, COLORS, ComponentTextStyle, ModalDialog} from "@laerdal/life-react-components";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {Size} from "@laerdal/life-react-components";
import {Session} from "../../../model/dto/organization/session";
import {useMediaMatch} from 'rooks';


const SessionInfoModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  
  ${BREAKPOINTS.MEDIUM}{
    gap: 16px;
  }
  
  &> div{
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const SessionInfoModalBodyRowKey = styled.div`
  ${ComponentSStyling(ComponentTextStyle.Bold, COLORS.black)}
  ${BREAKPOINTS.MEDIUM}{
    ${ComponentMStyling(ComponentTextStyle.Bold, COLORS.black)}
  }
`;
const SessionInfoModalBodyRowValue = styled.p`
`;


interface Props {
  session?: Session;
  seat?: number;
  onClose: () => void;
  isOpen: boolean;
}

export const SessionInformationModal: React.FunctionComponent<Props> = (props) => {
  const {t} = useTranslation('License');
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
  const started = moment(props.session?.created).format('LLL');

  return (
    <ModalDialog isModalOpen={props.isOpen}
                 closeModalAndClearInput={props.onClose}
                 closeAction={props.onClose}
                 submitAction={props.onClose}
                 buttons={[
                   {
                     action: props.onClose,
                     text: t('Close'),
                     variant: 'tertiary',
                   },
                 ]}
                 title={t('User session information')}
                 size={isMediumScreen ? Size.Medium : Size.Small}>
      <SessionInfoModalBody>
        <div>
          <SessionInfoModalBodyRowKey>{t('Started')}:</SessionInfoModalBodyRowKey>
          <SessionInfoModalBodyRowValue>{started}</SessionInfoModalBodyRowValue>
        </div>

        <div>
          <SessionInfoModalBodyRowKey>{t('Seat')}:</SessionInfoModalBodyRowKey>
          <SessionInfoModalBodyRowValue>{props.seat}</SessionInfoModalBodyRowValue>
        </div>

        <div>
          <SessionInfoModalBodyRowKey>{t('Username')}:</SessionInfoModalBodyRowKey>
          <SessionInfoModalBodyRowValue>{props.session?.email}</SessionInfoModalBodyRowValue>
        </div>


        <div>
          <SessionInfoModalBodyRowKey>{t('Software version')}:</SessionInfoModalBodyRowKey>
          <SessionInfoModalBodyRowValue>{props.session?.softwareInfo}</SessionInfoModalBodyRowValue>
        </div>

        <div>
          <SessionInfoModalBodyRowKey>{t('Device')}:</SessionInfoModalBodyRowKey>
          <SessionInfoModalBodyRowValue>{props.session?.deviceInfo}</SessionInfoModalBodyRowValue>
        </div>

      </SessionInfoModalBody>

    </ModalDialog>
  );
}
