import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useNavigationType,
} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './store/store';
import './app/i18n';
import {QueryParamProvider} from "use-query-params";
import {ReactRouter6Adapter} from 'use-query-params/adapters/react-router-6'
import {createRoot} from 'react-dom/client';
import {Route, useLocation} from 'react-router';
import React from 'react';
import {Switcher} from "./app/Switcher";
import {ToastProvider} from "@laerdal/life-react-components";
import {GlobalNav} from "@laerdal/navigation";
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { AppInsightsContext, ReactPlugin} from '@microsoft/applicationinsights-react-js';

import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
dayjs.extend(LocalizedFormat)
dayjs.extend(utc);

var reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({ config: {
  connectionString: process.env.REACT_APP_APPLICATIONINSIGHTS,
  enableAutoRouteTracking: true,
  extensions: [reactPlugin],
} });
appInsights.loadAppInsights();


const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
    <Provider store={store}>
      <BrowserRouter>
        <ToastProvider>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <Routes>
                <Route path='*' element={
                  <AppInsightsContext.Provider value={reactPlugin}>
                    <>
                      <GlobalNav
                        ref={(globalNav: GlobalNav) => {
                          window.globalNav = globalNav;
                          window.globalNav?.initialize({
                            env: process.env.REACT_APP_SWITCHER_ENV,
                            canSwitchOrganization: true,
                          });
                        }}
                      />
                      <Switcher/>
                      <App/>
                    </>
                  </AppInsightsContext.Provider>
                }/>
            </Routes>
          </QueryParamProvider>
        </ToastProvider>
      </BrowserRouter>
    </Provider>
)

// const switcherRoot = document.getElementById('switcherRoot')!;
// const switcherRootRoot = createRoot(switcherRoot);
// switcherRootRoot.render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <ToastProvider>
//         <GlobalNav
//           ref={(globalNav: GlobalNav) => {
//             window.globalNav = globalNav;
//             window.globalNav?.initialize({
//               env: process.env.REACT_APP_SWITCHER_ENV,
//               canSwitchOrganization: true,
//             });
//           }}
//         />
//         <Switcher/>
//       </ToastProvider>
//     </BrowserRouter>
//   </Provider>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
