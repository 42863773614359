import axios from 'axios';
import { CreditCardDto } from '../../model/dto/payment/creditCardDto';
import AuthApi from './AuthApi';
import { BaseApi } from './BaseApi';

class PaymentApi extends BaseApi {
  GetCards = async (): Promise<CreditCardDto[]> => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/Payment/CreditCards`,
      {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  }
}

export default new PaymentApi();