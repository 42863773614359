import {
  BREAKPOINTS,
  Button,
  COLORS,
  ComponentM,
  ComponentTextStyle,
  ComponentXL,
  HyperLink,
  LoadingIndicator,
  PageWidth,
  Size,
  SystemIcons,
  BackButton
} from '@laerdal/life-react-components';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import PropertyWrapper, {
  InnerPropertyWrapper,
  PropertyLabel,

} from '../../_commonComponents/PropertyWrapper';
import ShipmentsApi from '../../../services/api/ShipmentsApi';
import { ShipmentDetailsDto } from '../../../model/dto/shipments/shipmentDetailsDto';
import moment from 'moment';
import { BasicCard } from '../../_commonComponents/BasicBox';
import ShipmentStatus from './ShipmentStatus';
import { StyledPageWidth } from '../../_commonComponents/StyledComponents';

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  ${BREAKPOINTS.MEDIUM}{
    flex-direction: row;
  }
`;
const TrackingLink = styled(HyperLink)`
  display:flex;
`;

const DetailsContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow:1;
`;

const Row = styled.div`
  display:flex;
  justify-content: space-between;
  width: 100%;
`;

const ShipmentDetails = styled.div`
  margin:32px 0 0 0;
`;

const TrackingButton = styled(Button)`
  margin: 8px 0 32px 0;
`;

const ShipmetnDetailsPage = () => {
  const navigate = useNavigate();
  const { shipmentNumber } = useParams<{ shipmentNumber: string }>();
  const { t, i18n } = useTranslation('Shipment');

  const [loading, setLoading] = useState<boolean>(true);
  const [shipmentDetails, setShipmentDetails] = useState<ShipmentDetailsDto | undefined>(undefined);

  useEffect(() => {
    ShipmentsApi.GetShipmentDetails(shipmentNumber!, i18n.language).then((response) => {
      setShipmentDetails(response);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('Shipment details page')}</title>
      </Helmet>
      <StyledPageWidth useMaxWidth={true} maxWidth={1600}>

        <BackButton 
          size={Size.Small} 
          onClick={() => {
            //@ts-ignore
            posthog.capture?.('ShipmentDetails BackToShipments');
            navigate('/ordersinvoices/shipments')
          }}>
            {t('All Shipments')}
        </BackButton>
        <ComponentXL textStyle={ComponentTextStyle.Bold} >{t('Shipment details page')}</ComponentXL>

        {loading && <LoadingIndicator />}
        {shipmentDetails &&
          <>
            {
              shipmentDetails.trackingUrl &&
              <TrackingButton icon={<SystemIcons.OpenNewWindow />} variant="secondary"
                onClick={() => window.open(shipmentDetails.trackingUrl, '_blank')}>{t('Track shipment')}</TrackingButton>
            }
            <BasicCard>
              <Row>
                <ComponentM textStyle={ComponentTextStyle.Bold} >{t('Shipment details')}</ComponentM>
                <ShipmentStatus status={shipmentDetails.trackingStatus} statusTranslated={shipmentDetails.trackingStatusTranslated} />
              </Row>

              <DetailsContainer>
                <DetailsContainerColumn>
                  {shipmentDetails.poNumber && <PropertyWrapper label={t('PO number')} value={shipmentDetails.poNumber} />}
                  <PropertyWrapper label={t('Shipment number')} value={shipmentDetails.shipmentNumber} />
                  {shipmentDetails.shippedDate && <PropertyWrapper label={t('Shipped date')}
                    value={moment(shipmentDetails.shippedDate)
                      .format('LL')} />}
                  {shipmentDetails.shippingProvider &&
                    <PropertyWrapper label={t('Shipping provider')} value={shipmentDetails.shippingProvider} />}
                  {shipmentDetails.trackingNumber &&

                    <InnerPropertyWrapper>
                      <PropertyLabel>{t('Tracking number')}</PropertyLabel>
                      {!! shipmentDetails.trackingUrl?
                            <TrackingLink id="HyperLinkDefault" variant="default" href={shipmentDetails?.trackingUrl ?? ""} >{shipmentDetails?.trackingNumber}<SystemIcons.OpenNewWindow size='20' /></TrackingLink>
                            : shipmentDetails.trackingNumber}
                    </InnerPropertyWrapper>
                  }

                </DetailsContainerColumn>
                <DetailsContainerColumn>
                  {shipmentDetails.orderNumber && <PropertyWrapper label={t('Order number')} value={!!shipmentDetails.qadOrderNumber ? shipmentDetails.qadOrderNumber : shipmentDetails.orderNumber} />}
                  {shipmentDetails.actualDeliveryDate && <PropertyWrapper label={t('Actual delivery date')}
                    value={moment(shipmentDetails.actualDeliveryDate)
                      .format('LL')} />}
                  {shipmentDetails.estimatedDeliveryDate && <PropertyWrapper label={t('Estimated delivery date')}
                    value={moment(shipmentDetails.estimatedDeliveryDate)
                      .format('LL')} />}
                  {shipmentDetails.estimatedDeliveryTime && <PropertyWrapper label={t('Estimated delivery time')}
                    value={moment(
                      shipmentDetails.estimatedDeliveryTime,
                      'hh:mm:ss',
                    ).format('LT')} />}
                  {shipmentDetails.lastTrackUpdateTime && <PropertyWrapper label={t('Last track update time')}
                    value={moment(shipmentDetails.lastTrackUpdateTime)
                      .format('LL')} />}
                </DetailsContainerColumn>
              </DetailsContainer>
            </BasicCard>
          </>
        }
      </StyledPageWidth>
    </>);
};

export default ShipmetnDetailsPage;