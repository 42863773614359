import React, {useContext, useEffect, useState} from 'react';
import {
  BREAKPOINTS, Checkbox, COLORS,
  ModalDialog,  
  Size,
  ToastContext,
} from '@laerdal/life-react-components';
import {Trans, useTranslation} from 'react-i18next';
import OrganizationApi from '../../../services/api/OrganizationApi';
import {FailToastOptions, SuccessToastOptions} from '../../../model/constants/ToastConstants';
import {useMediaMatch} from 'rooks';
import styled from 'styled-components';
import {UserPermissionDetailsDto} from '../../../model/dto/userDetails/UserPermissionDetailsDto';


const Wrapper = styled.div`
  .user, .email{
    font-weight: 700;
  }
`;

const Description = styled.p`
  margin-bottom: 16px;
`;

interface Props {
  isOpen: boolean;
  user: UserPermissionDetailsDto;
  onRemove: () => void;
  onClose: () => void;
}

export const RemoveUserModal = ({user, onRemove, onClose, isOpen}: Props) => {
  const {t} = useTranslation('Organization');
  const {addToast} = useContext(ToastContext);
  const [loading, setLoading] = useState(false);
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));

  const [sendEmail, setSendEmail] = useState(false);

  useEffect(() => setSendEmail(false), [user]);

  const close = () => {
    onClose();
  };

  const submit = () => {
    setLoading(true);
    return OrganizationApi.RemoveOrganizationMember(email!, sendEmail)
      .then(() => addToast(t('{{emailAddress}} removed from organization', {emailAddress: email}), SuccessToastOptions))
      .then(() => onRemove())
      .then(() => onClose())
      .catch(() => addToast(t('A problem occurred – please try again'), FailToastOptions))
      .finally(() => setLoading(false));
  };

  const name = `${user?.firstName} ${user?.lastName} (${user.email})`;
  const email = user.email;

  // @ts-ignore
  return (
    <ModalDialog isModalOpen={isOpen}
                 closeModalAndClearInput={close}
                 closeAction={close}
                 title={t('Remove user?')}
                 size={isMediumScreen ? Size.Medium : Size.Small}
                 submitAction={submit}
                 state={'critical'}
                 note={t('This action cannot be undone.')}
                 buttons={[
                   {
                     id: 'cancel',
                     variant: 'tertiary',
                     disabled: loading,
                     action: close,
                     text: t('Cancel'),
                   },
                   {
                     id: 'submit',
                     text: t('Yes, remove'),
                     action: submit,
                     loading: loading,
                     variant: 'critical',
                   },
                 ]}>
      <Wrapper>
        <Description id={'description'}>
          <Trans t={t} ns={'Organization'} key={'RemoveUserDescription'}>
            {/*@ts-ignore*/}
            <span className={'user'}>{{name}}</span> will be removed from your organization and lose access to all
            products, services and Licenses.
          </Trans>
        </Description>

        <Checkbox id="sendEmail"
                  selected={sendEmail}
                  select={setSendEmail}>
          <Trans t={t} ns={'Organization'} key={'RemoveUserCheckboxLabel'}>
            {/*@ts-ignore*/}
            Send email notification to <span className={'email'}>{{email}}</span>
          </Trans>
        </Checkbox>
      </Wrapper>
    </ModalDialog>
  );
};