import React, {useContext, useEffect, useRef} from 'react'
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {Banner, BREAKPOINTS, ToastContext} from "@laerdal/life-react-components";
import {useSelector} from "react-redux";
import {
  selectSubscription, selectSubscriptionBillingDetails,
  selectSubscriptionOrganizationService, selectSubscriptionService, setSubscriptionBillingDetails
} from "../../../store/subscription/subscriptionSlice";
import {SuccessToastOptions} from "../../../model/constants/ToastConstants";
import {useNavigate, useSearchParams} from "react-router-dom";
import {SubscriptionStatus} from "../../../model/dto/organization/subscriptionStatus";
import {LicenseInformationHelper} from "../helpers/LicenseInformationHelper";
import SubscriptionApi from "../../../services/api/SubscriptionApi";
import {useAppDispatch} from "../../../store/store";
import {PaymentType} from "../../../model/dto/organization/paymentType";


const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${BREAKPOINTS.MEDIUM} {
    gap: 24px;
  }

  ${BREAKPOINTS.LARGE} {
    gap: 32px;
  }
`;

const StyledIFrame = styled.iframe`
  width: 100%;
  min-height: 450px;
  max-width: 640px;
  border: none;
`;

export const LicenseAmendmentPayment = () => {
  const {t} = useTranslation('License');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {addToast} = useContext(ToastContext);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [search] = useSearchParams();

  const orgService = useSelector(selectSubscriptionOrganizationService)!;
  const service = useSelector(selectSubscriptionService);
  const subscription = useSelector(selectSubscription);
  const billingDetails = useSelector(selectSubscriptionBillingDetails);

  const isCardExpired = LicenseInformationHelper.IsCardExpired(billingDetails);
  const hasPaymentIssue = subscription?.status === SubscriptionStatus.PastDue;

  useEffect(() => {
    const handle = (message: MessageEvent) => {
      if (!message.data?.action) return;

      const {data, action} = message.data;
      switch (action) {
        case 'checkout.payment.updated':
          //@ts-ignore
          posthog.capture?.('AC SubscriptionManageCreditCard Changed', {
            subscription_name: service!.name,
            subscription_number: subscription!.subscriptionNumber || subscription!.id,
            payment_provider: PaymentType[subscription!.paymentType],
          });

          addToast(t('Payment method updated successfully'), SuccessToastOptions);
          SubscriptionApi.GetSubscriptionDetails(subscription!.id)
            .then((details) => {
              dispatch(setSubscriptionBillingDetails(details.subscription.billingDetails));
            })
            .then(() => {
              const returnUrl = search.get('returnUrl') || `/licenses/${orgService?.id}`;
              navigate(returnUrl);
            })
          break;
        case 'checkout.payment.resized':
          if (!iframeRef.current || !data) return;
          iframeRef.current!.height = data + 'px';
          break;
      }
    }
    window.addEventListener('message', handle);

    return () => window.removeEventListener('message', handle);
  }, []);

  const src = new URL(`subscription/${subscription!.id}/payment?embedded=true`, process.env.REACT_APP_CHECKOUT_URL!).toString();

  return (
    <>
      <h5>{t('Update payment method')}</h5>
      <Content>
        <p>
          {t('If you have any other outstanding balances, you will be charged for them after your payment method has been updated.')}
        </p>
        {
          hasPaymentIssue && !isCardExpired &&
          <Banner type={'warning'}>{t('Your payment method has expired, please enter new card details.')}</Banner>
        }
        {
          hasPaymentIssue && !!isCardExpired &&
          <Banner type={'warning'}>{t('Your payment method has expired, please enter new card details.')}</Banner>
        }

        <StyledIFrame src={src}
                      ref={iframeRef}
                      style={{width: '1px', minWidth: '100%'}}/>
      </Content>
    </>
  )
}