import {
  BREAKPOINTS,
  PageWidth,
  SearchBar,
  SegmentControl,
  SegmentControlItem,
  Size,
  TablePagination,
  VerticalTabEntry,
  VerticalTabs,
  ToastContext,

  Table,
  LoadingIndicator,
  SystemIcons,
  IconButton,
  Banner
} from '@laerdal/life-react-components';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { InvoiceListItemDto } from '../../../model/dto/invoices/invoiceListItemDto';
import styled from 'styled-components';
import { useDebouncedValue, useMediaMatch } from 'rooks';
import { useSelector } from 'react-redux';
import { selectUserProfile } from '../../../store/account/accountSlice';
import { ListHeader, ListPreTitle, ListSubtitle, ListTitle, StyledPageWidth } from '../../_commonComponents/StyledComponents';
import InvoicesApi from '../../../services/api/InvoicesApi';
import PaginationHelper from '../../../services/helpers/PaginationHelper';
import { FailToastOptions } from '../../../model/constants/ToastConstants';
import moment from 'moment';
import { TableSortingDirection } from '@laerdal/life-react-components/dist/Table/TableTypes';
import { EmptyPageBox } from '../../_commonComponents/EmptyPageBox';


const Wrapper = styled.div`
  overflow-x: auto;
  background: white;
`;

const InitialPagination: TablePagination = {
  currentPage: 0,
  from: 0,
  rowsPerPage: 20,
  to: 0,
  total: 0,
};

const SearchWrapper = styled.div`
  margin:0 0 32px 0 ;
  width: 100%;
  ${BREAKPOINTS.LARGE}{
    width: 50%;
  }
`;

const FullWidth = styled.div`
  width: 100%;  
`;

const VerticalTabWrapper = styled.div`
  width: 350px;
`;

const Row = styled.div`
  width:100%;
  display: flex;
  gap: 32px;

  flex-direction: column;
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
  }
`;

const InvoicesPage = () => {
  const { t, i18n } = useTranslation(['Invoices', 'Shipment', 'Order']);
  const navigate = useNavigate();
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
  const user = useSelector(selectUserProfile);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchTermDebounced] = useDebouncedValue(searchTerm, 300);
  const { addToast } = useContext(ToastContext);
  const [invoices, setInvoices] = useState<InvoiceListItemDto[]>([]);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [hasItems, setHasItems] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);
  const [downloading, setDownloading] = useState<{ [key: string]: boolean }>({});
  const [pagination, setPagination] = useState<TablePagination>(InitialPagination);

  const [sortingDirection, setSortingDirection] = useState<TableSortingDirection | undefined>(undefined);
  const [sortingKey, setSortingKey] = useState<string | undefined>(undefined);

  const [data, setData] = useState<(InvoiceListItemDto & { downloading: boolean })[]>([]);

  const openInvoiceDetails = (row: InvoiceListItemDto) => {
    navigate(`/ordersinvoices/invoices/details/${encodeURIComponent(row.invoiceNumber)}`);
  };


  const verticalItems = [
    { requiredLine: t('Orders'), to: '/ordersinvoices/orders' },
    { requiredLine: t('Shipments'), to: '/ordersinvoices/shipments' },
    { requiredLine: t('Invoices'), to: '/ordersinvoices/invoices' },
  ];

  const horizontalOptions = [
    { key: '/ordersinvoices/orders', content: t('Orders'), disabled: false },
    { key: '/ordersinvoices/shipments', content: t('Shipments'), disabled: false },
    { key: '/ordersinvoices/invoices', content: t('Invoices'), disabled: false }
  ];

  useEffect(() => {
    //@ts-ignore
    posthog.capture?.('InvoicesPage Search',{
      term: searchTermDebounced
    });
    setLoading(true);

    InvoicesApi.GetInvoices(pagination.rowsPerPage, 0, i18n.language, sortingKey, sortingDirection, searchTerm, undefined)
      .then((response) => {
        setInvoices(response.items);
        setPagination(PaginationHelper.GetInitial(pagination.rowsPerPage, response.totalCount));
        setLoading(false);
        setInitialLoad(false);
        if (response.items.length > 0) setHasItems(true);
      })
      .finally(() => setLoading(false));

  }, [searchTermDebounced]);

  useEffect(() => {
    setData(invoices?.map(a => ({ ...a, downloading: downloading[a.invoiceNumber] })));
  }, [downloading, invoices]);


  const handleInvoiceDownload = (invoiceNumber: string) => {
    //@ts-ignore
    posthog.capture?.('InvoicesPage DownloadInvoice');

    setDownloading({ ...downloading, [invoiceNumber]: true });
    InvoicesApi.DownloadInvoice(invoiceNumber, handleDownloadError).finally(() => {
      setDownloading({ ...downloading, [invoiceNumber]: false });
    });
  };

  const handleDownloadError = (errorMessage: string) => {
    //@ts-ignore
    posthog.capture?.('InvoicesPage DownloadInvoiceError');
    addToast(t(errorMessage), { ...FailToastOptions, autoClose: false });
  };

  const onPreviousPage = () => {
    //@ts-ignore
    posthog.capture?.('InvoicesPage PreviousPage');
    setLoading(true);
    InvoicesApi.GetInvoices(
      pagination.rowsPerPage,
      pagination.currentPage - 1,
      i18n.language,
      sortingKey,
      sortingDirection,
      searchTerm,
      undefined
    ).then((response) => {
      setInvoices(response.items);
      setPagination(PaginationHelper.GetPrevious(pagination.currentPage, pagination.rowsPerPage, response.totalCount));
      setLoading(false);
    });
  };

  const onNextPage = () => {
    //@ts-ignore
    posthog.capture?.('InvoicesPage NextPage');
    setLoading(true);

    InvoicesApi.GetInvoices(
      pagination.rowsPerPage,
      pagination.currentPage + 1,
      i18n.language,
      sortingKey,
      sortingDirection,
      searchTerm,
      undefined
    ).then((response) => {
      setInvoices(response.items);
      setPagination(PaginationHelper.GetNext(pagination.currentPage, pagination.rowsPerPage, response.totalCount));
      setLoading(false);
    });
  };

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    //@ts-ignore
    posthog.capture?.('InvoicesPage RowsPerPage',{
      rowsPerPage: newRowsPerPage
    });
    setLoading(true);

    InvoicesApi.GetInvoices(newRowsPerPage, 0, i18n.language, sortingKey, sortingDirection, searchTerm, undefined)
      .then((response) => {
        setInvoices(response.items);
        setPagination(PaginationHelper.GetInitial(newRowsPerPage, response.totalCount));
        setLoading(false);
      });
  };

  const onSortingChanged = (key: string, direction?: TableSortingDirection) => {
    //@ts-ignore
    posthog.capture?.('InvoicesPage Sort',{
      key: key,
      direction: direction
    });

    setSortingDirection(direction);
    setSortingKey(key);
    setLoading(true);

    InvoicesApi.GetInvoices(pagination.rowsPerPage, 0, i18n.language, key, direction, searchTerm, undefined)
      .then((response) => {
        setInvoices(response.items);
        setPagination(PaginationHelper.GetInitial(pagination.rowsPerPage, response.totalCount));
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>{t('Invoices')}</title>
      </Helmet>
      <StyledPageWidth useMaxWidth={true} maxWidth={1600}>
        <ListHeader>
          <ListPreTitle>{user!.currentOrganization!.name}</ListPreTitle>
          <ListTitle>{t('Orders & Invoices')}</ListTitle>
          <ListSubtitle>{t('Review order history, track shipments, and download invoices.')}</ListSubtitle>
        </ListHeader>

        <Row>
          {!isMediumScreen && <SegmentControl 
                                selected='/ordersinvoices/invoices' 
                                items={horizontalOptions} 
                                onChange={(to) => { 
                                  navigate(to); 
                                }} size={Size.Small} />}

          {!!isMediumScreen && <VerticalTabWrapper><VerticalTabs entries={verticalItems} /></VerticalTabWrapper>}

          <FullWidth>

            {initialLoad &&
              <LoadingIndicator />
            }

            {!initialLoad && !hasItems &&
              <EmptyPageBox
                title={t('Your invoices')}
                description={t('A history of charges and fees relating to your orders will be displayed here. It can take up to 24 hours for new invoices to appear here.')}
                ilustrationPath='/assets/invoice_check.svg' />
            }

            {!initialLoad && hasItems &&
              <>
                <SearchWrapper>
                  <SearchBar
                    enterSearch={() => { }}
                    id="orderSearchField"
                    placeholder={t('Search by Invoice or PO number')}
                    size={Size.Medium}
                    setSearchTerm={setSearchTerm}
                    removeSearch={() => setSearchTerm('')}
                  />
                </SearchWrapper>
                {/* <InvoicesTable onInvoiceClick={openInvoiceDetails} searchTerm={searchTermDebounced ?? ''} /> */}

                <Wrapper>
                  <Banner type='neutral' fullWidth={true}  >
                    { t('It can take up to 24 hours for new invoices to appear here.')}
                  </Banner> 
                  <Table
                    noRowsLabel={t('There are no rows to display', { 'ns': 'Common' })}
                    rowsPerPageLabel={t('Rows per page:', { 'ns': 'Common' })}
                    rows={data || []}
                    remoteOperations={true}
                    pagination={pagination}
                    onRowsPerPageChange={(newRowsPerPage: number) => onRowsPerPageChange(newRowsPerPage)}
                    onNextPageClick={() => onNextPage()}
                    onPreviousPageClick={() => onPreviousPage()}
                    showLoadingIndicator={loading}
                    onSelectionChange={(row: InvoiceListItemDto) => openInvoiceDetails(row)}
                    selectable={true}
                    onTriggerSortingChange={(key: string, direction?: TableSortingDirection) => onSortingChanged(key, direction)}
                    columns={[
                      {
                        key: 'orderNumber',
                        name: t('Order number'),
                        type: 'custom',
                        customContent: (row: InvoiceListItemDto, key: string) => !!row.qadOrderNumber ? row.qadOrderNumber : row.orderNumber,
                      },
                      {
                        key: 'poNumber',
                        name: t('PO number'),
                        sortable: true,
                      },
                      {
                        key: 'invoiceNumber',
                        name: t('Invoice number'),
                        sortable: true,
                      },
                      // {
                      //   key: 'status',
                      //   name: t('Status'),
                      //   sortable: false,
                      //   type: 'custom',
                      //   customContent: (row: InvoiceListItemDto, key: string) => <InvoiceStatus status={row.status}
                      //                                                                     statusTranslated={row.statusTranslated}/>,
                      // },
                      {
                        key: 'invoiceDueDate',
                        name: t('Due date'),
                        sortable: true,
                        width: 230,
                        type: 'custom',
                        customContent: (row: InvoiceListItemDto, key: string) => moment(row.invoiceDate).format('ll'),
                      },
                      {
                        key: 'invoiceType',
                        name: t('Invoice type'),
                        sortable: true,
                      },
                      {
                        key: 'downloading',
                        name: '',
                        sortable: false,
                        type: 'custom',
                        customContent: (row: InvoiceListItemDto & { downloading: boolean }) =>
                          <>
                            { new Date(new Date().setHours((new Date().getHours() - 24))) > (new Date(row.invoiceDate)) &&
                              <IconButton action={() => handleInvoiceDownload(row.invoiceNumber)}
                                variant={'secondary'}
                                shape={'circular'}
                                disabled={row.downloading}>
                                {row.downloading ? <LoadingIndicator /> : <SystemIcons.CloudDownload />}
                              </IconButton>
                            }
                          </>,
                        width: 100,
                      },
                    ]}
                  />
                </Wrapper>
              </>
            }

          </FullWidth>
        </Row>
      </StyledPageWidth>
    </>);
};
export default InvoicesPage;