import axios from 'axios';
import {User} from '../../model/dto/userProfile/user';
import AuthApi from './AuthApi';
import {UserPreferences} from '../../model/dto/userProfile/preferences';
import {UserInfo} from '../../model/dto/userProfile/userInfo';
import {BaseApi} from './BaseApi';
import {UserPermissionDetailsDto} from "../../model/dto/userDetails/UserPermissionDetailsDto";

export class UseApi extends BaseApi {
  token = '';
  static instance = new UseApi();

  constructor() {
    super();
    axios.defaults.headers.patch['Content-Type'] = 'application/json';
  }

  /**
   * Retrieves the user profile from the org dir.
   * @returns A user profile for the currently authenticated user.
   */
  getUser = async (): Promise<User> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/user`, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      })
  };

  IsSfContact = async (signal?: AbortSignal): Promise<boolean> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/user/is-sf-contact`, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
        signal: signal,
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  };

  CanPayOnAccount = async (): Promise<boolean> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/user/can-pay-on-account`, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  };

  UpdatePreferences = async (preferences: UserPreferences): Promise<void> => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/user/preferences`, preferences, {
      headers: {
        Authorization: `Bearer ${await AuthApi.getToken()}`,
      },
    });
  };

  Update = async (user: UserInfo): Promise<void> => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/user`, user, {
      headers: {
        Authorization: `Bearer ${await AuthApi.getToken()}`,
      },
    });
  };

  UpdateRoles = async (roles: string[]): Promise<void> => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/user/roles`, {
      roles: roles,
    }, {
      headers: {
        Authorization: `Bearer ${await AuthApi.getToken()}`,
      },
    });
  };

  ChangeCurrentOrganization = async (organizationId: string): Promise<void> => {
    return axios.post(`${process.env.REACT_APP_API_URL}/user/organization/current/${organizationId}`, undefined, {
      headers: {
        Authorization: `Bearer ${await AuthApi.getToken()}`,
      },
    }).then(() => this.ClearCache());
  };

  LeaveOrganization = async (organizationId: string): Promise<void> => {
    return axios.post(`${process.env.REACT_APP_API_URL}/user/organization/leave/${organizationId}`, undefined, {
      headers: {
        Authorization: `Bearer ${await AuthApi.getToken()}`,
      },
    });
  };

}

export default UseApi.instance;
