import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  BackButton,
  BREAKPOINTS, Button,
  COLORS, DropdownFilter, DropdownItem,
  InputLabel, LoadingIndicator,
  PageWidth,
  Size, ToastContext,
} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import {Country} from '../../model/dto/settings/country';
import {Language} from '../../model/dto/settings/language';
import {useSelector} from 'react-redux';
import {selectGigyaAccount, setGigyaAccount} from '../../store/account/accountSlice';
import SettingsApi from '../../services/api/SettingsApi';
import {useNavigate} from 'react-router';
import {useController, useForm} from 'react-hook-form';
import {UserPreferences} from '../../model/dto/userProfile/preferences';
import UserApi from '../../services/api/UserApi';
import {FailToastOptions, SuccessToastOptions} from '../../model/constants/ToastConstants';
import {useAppDispatch} from '../../store/store';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { GigyaUserData } from '../../model/gigya/gigyaUserData';
import { StyledPageWidth } from '../_commonComponents/StyledComponents';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.h3`
  margin: 0;

  ${BREAKPOINTS.MEDIUM}{
    display: flex;
  }
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
`;

const SubTitle = styled.h6`
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  ${BREAKPOINTS.MEDIUM}{
    gap: 16px;
  }
  *:first-letter,
  input{
    text-transform: capitalize !important;
  }
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  ${BREAKPOINTS.MEDIUM}{
    gap: 16px;
    flex-direction: row-reverse;
  }
`;

export const LanguageAndRegionPage = () => {
  const {t, i18n} = useTranslation('Profile');
  const {addToast} = useContext(ToastContext);
  const user = useSelector(selectGigyaAccount);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState<DropdownItem[]>([]);
  const [languages, setLanguages] = useState<DropdownItem[]>([]);

  const getLang = (user: GigyaUserData | undefined) => {
    if(user == undefined) return 'en';
    
    var userLocale = user!.locale?.toLowerCase() ?? 'en';
    if(userLocale.startsWith('en-')) return 'en';
    
    return userLocale;
  }
  
  const {control, reset, handleSubmit, formState} = useForm<UserPreferences>(
    {
      defaultValues: {
        locale: getLang(user),
        country: user!.country?.toLowerCase(),
      },
      resolver: yupResolver(yup.object().shape({
        locale: yup.string().required(t('Field is required')),
        country: yup.string().required(t('Field is required')),
      }))
    },
  );

  const locale = useController({control, name: 'locale'});
  const country = useController({control, name: 'country'});

  

  useEffect(() => {
    const abort = new AbortController();

    Promise.all([
      SettingsApi.GetLanguages(abort.signal),
      SettingsApi.GetCountries(abort.signal),
    ]).then(([languages, countries]) => {
      setCountries(countries.map((country: Country) => ({
        value: country.codeAlpha2.toLowerCase(),
        displayLabel: country.name,
      })));
      setLanguages(languages.map((language: Language) => ({
        value: language.locale?.toLowerCase(),
        displayLabel: language.name,
      })));
    }).then(() => setLoading(false));

    return () => abort.abort();
  }, []);

  const ShowMessageIfTranslationLoaded = (locale: string) => {
    if(i18n.resolvedLanguage?.toLocaleLowerCase() == locale.toLocaleLowerCase())
      addToast(t('Account preferences saved', { lng: locale }), SuccessToastOptions);
    else
      setTimeout(() => {ShowMessageIfTranslationLoaded(locale)}, 250)
  }
  
  const submit =
    handleSubmit((preferences: UserPreferences) =>
      {
        //@ts-ignore
        posthog.capture?.('LanguageAndRegionPage ChangedLanguage');
        dispatch(setGigyaAccount({...user!, country: preferences.country!, locale: preferences.locale!}));
        UserApi.UpdatePreferences(preferences)
          .then(() => {
            ShowMessageIfTranslationLoaded(preferences.locale);
          })
          .catch(() => {
            addToast(t('There was an error saving changes'), FailToastOptions);
            reset();
          })
        });
      

  return (
    <StyledPageWidth useMaxWidth={true} maxWidth={800}>
      <Header>
        <BackButton size={Size.Small} onClick={() => navigate('/profile')}>{t('Back')}</BackButton>
        <Title>{t('Language & Region')}</Title>
      </Header>
      {
        loading &&
        <LoadingIndicator/>
      }
      {
        !loading &&
        <Content>
          <ContentBlock>
            <SubTitle>{t('Language settings')}</SubTitle>
            <Description>{t('Menus and buttons are displayed using your preferred Language.')}</Description>
            <div>
              <InputLabel inputId={'language'} text={t('Primary language')}/>
              <DropdownFilter list={languages}
                              id={'language'}
                              ref={locale.field.ref}
                              value={locale.field.value}
                              onSelect={locale.field.onChange}
                              onBlur={locale.field.onBlur}
                              name={locale.field.name}
                              activeValidationMessage={locale.fieldState.error?.message}
                              scrollable={true}/>
            </div>
          </ContentBlock>

          <ContentBlock>
            <SubTitle>{t('Regional settings')}</SubTitle>
            <Description>{t('Date and time formats are based on your Country / Region.')}</Description>
            <div>
              <InputLabel inputId={'region'} text={t('Country / Region')}/>
              <DropdownFilter list={countries}
                              id={'country'}
                              ref={country.field.ref}
                              value={country.field.value}
                              onSelect={country.field.onChange}
                              onBlur={country.field.onBlur}
                              name={country.field.name}
                              activeValidationMessage={country.fieldState.error?.message}
                              scrollable={true}/>
            </div>
          </ContentBlock>

          <Actions>
            <Button id={'submit'} onClick={submit} loading={formState.isSubmitting}>{t('Save changes')}</Button>
            <Button onClick={() => navigate('/profile')} variant={'tertiary'}>{t('Cancel')}</Button>
          </Actions>

        </Content>
      }
    </StyledPageWidth>
  );


};