import { BREAKPOINTS, Button, COLORS, ComponentSStyling, ComponentTextStyle, ProfileButton, RichTextField, Size, Tag, TagVariants, Typography, useClickOutsideRef } from "@laerdal/life-react-components";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import { useMemo, useRef, useState } from "react";
import dompurify from "dompurify";


const CreateContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0;
  gap: 16px
`;
const CreateIcon = styled.div`
  pointer-events: none;

  display: none;

  ${BREAKPOINTS.MEDIUM} {
    display: block;
  }
`;

const CreateContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

const CreateInput = styled.div`
  .ql-snow .ql-editor {
    ${Typography}
  }

  .ql-snow .ql-editor p {
    ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.black)}
  }

  &.open .ql-editor {
    min-height: 108px;
  }

  &.open .ql-toolbar.ql-snow {
    display: block;
  }

  &.closed .ql-toolbar.ql-snow {
    display: none;
  }
`;
const CreateActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

type CreateCommentProps = {
    initials: string,
    save: (comment: string) => void
};

const defaultToolBarOptions = [
    [{header: [4, 5, 6, false]}],
    ['bold', 'italic', 'underline', 'strike'],
    [{'color': []}, {'background': []}],
    [{'script': 'sub'}, {'script': 'super'}],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    ['clean']
  ];

const emptyToolBarOptions = [[]];
    
const CreateComment = ({ initials, save }: CreateCommentProps) => {
    const {t} = useTranslation('Cases');

    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [note, setNote] = useState<string>('');
    const quill = useRef<any>();
    const ref = useClickOutsideRef(() => isEmpty() && reset(), []);
    const modules = useMemo(() => ({toolbar: defaultToolBarOptions}), [])
    //const modules = useMemo(() => ({toolbar: emptyToolBarOptions}), [])

    const isEmpty = () => {
      
        const el = document.createElement('div');
        el.innerHTML = note;
        return !el.textContent?.trim();
    }
    const reset = () => {
        setNote('');
        setOpen(false);
        quill.current?.blur();
    }


    return (
        <CreateContainer>
            <CreateIcon>
                <ProfileButton onClick={() => { }} initials={initials} />
            </CreateIcon>
            <CreateContent>
                <CreateInput className={open ? 'open' : 'closed'} ref={ref}>
                    <RichTextField value={note}
                        size={Size.Small}
                        ref={quill}
                        onChange={value => setNote(value)}
                        onFocus={() => setOpen(true)}
                        placeholder={t('Add a reply')}
                        readOnly={loading}
                        modules={modules} />
                </CreateInput>
                {
                    open &&
                    <CreateActions>
                        <Button 
                            variant={'primary'} 
                            loading={loading} 
                            onClick={() => {
                                save(note); 
                                reset();
                                }}>
                                    {t('Save')}
                        </Button>
                        <Button variant={'tertiary'} disabled={loading} onClick={() => reset()}>{t('Cancel')}</Button>
                    </CreateActions>
                }
            </CreateContent>


        </CreateContainer>
    );
}

export default CreateComment;