import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  BackButton,
  BREAKPOINTS,
  COLORS,
  ListRow,
  PageWidth,
  Size,
  SystemIcons,
  ToastContext,
} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {selectUserProfile, setUserProfile} from '../../store/account/accountSlice';
import {useNavigate} from 'react-router';
import {useAppDispatch} from '../../store/store';
import moment from 'moment/moment';
import {useMediaMatch} from 'rooks';
import {Organization} from '../../model/dto/organization/organization';
import {ConfirmModal} from './components/ConfirmModal';
import UserApi from '../../services/api/UserApi';
import {FailToastOptions, SuccessToastOptions} from '../../model/constants/ToastConstants';
import { StyledPageWidth } from '../_commonComponents/StyledComponents';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.h3`
  margin: 0;
  
  ${BREAKPOINTS.MEDIUM}{
    display: flex;
  }
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
`;


const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const UserOrganizationsPage = () => {
  const {t} = useTranslation('Profile');

  const user = useSelector(selectUserProfile);
  const {addToast} = useContext(ToastContext);
  const profile = useSelector(selectUserProfile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));

  const [action, setAction] = useState<{ action: string, organization: Organization }>();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  
  useEffect(() => {
    if(user && user.switchOrganizationEnabled == false)
    {
      navigate('/profile');
    }
  }, [user]);

  const handleContextMenuClick = ([value]: string[], organization: Organization) => {
    setAction({
      action: value,
      organization: organization,
    });
    setConfirmModalOpen(true);
  };

  const submit = () => {
    switch (action?.action) {
      case 'current':
        return UserApi.ChangeCurrentOrganization(action!.organization.id)
          .then(() => UserApi.getUser().then((user) => dispatch(setUserProfile(user))))
          .then(() => {
            //@ts-ignore
            posthog.capture?.('UserOrganizationsPage OrganizationChanged');
            addToast(t('Default organization updated.'), SuccessToastOptions)}
          )
          .catch(() => {addToast(t('A problem occurred – please try again'), FailToastOptions)})
          .finally(() => setConfirmModalOpen(false));
      case 'remove':
        return UserApi.LeaveOrganization(action!.organization.id)
          .then(() => UserApi.getUser().then((user) => dispatch(setUserProfile(user))))
          .then(() => {
            //@ts-ignore
            posthog.capture?.('UserOrganizationsPage LeftOrganization');
            addToast(t('You have left {{organization}}', action!.organization.name), SuccessToastOptions)
          })
          .catch(() => {addToast(t('A problem occurred – please try again'), FailToastOptions)})
          .finally(() => setConfirmModalOpen(false));
    }
    return Promise.resolve();
  };

  return (
    <StyledPageWidth  useMaxWidth={true} maxWidth={800}>
      <ConfirmModal isOpen={confirmModalOpen}
                    onClose={() => setConfirmModalOpen(false)}
                    onSubmit={submit}
                    action={action?.action}
                    organization={action?.organization}/>
      <Header>
        <BackButton size={Size.Small} onClick={() => navigate('/profile')}>{t('Back')}</BackButton>
        <Title>{t('My Organizations')}</Title>
        <Description>{t('You are a member of the following organizations.')}</Description>
      </Header>
      <Content>
        {
          profile!.organizations.map((organization) =>
            <ListRow key={organization.id}
                     id={'org_' + organization.id}
                     size={isMediumScreen ? Size.Large : Size.Medium}
                     icon={<SystemIcons.Institute/>}
                     mainText={organization.name}
                     secondaryText={t(
                       'Member since {{date}}',
                       {date: moment(organization.members[0].created).format('ll')},
                     )}
                     note={organization.id === profile!.currentOrganization?.id ? t('Default') : ''}
                     dropdown={{
                       onClick: (v) => handleContextMenuClick(v, organization),
                       items: [
                         {
                           value: 'current',
                           displayLabel: t('Set as default'),
                         }, {
                           value: 'remove',
                           displayLabel: t('Leave organization'),
                         },
                       ],
                     }}
            />,
          )
        }

      </Content>
    </StyledPageWidth>
  );

};