import React, {useEffect, useState} from 'react';
import {ContentAccordion} from '@laerdal/life-react-components';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  .accordion {
    max-width: 100%;
  }
`;

const Accordion = ({children, title, open}: { children: React.ReactNode, title: string, open?: boolean }) => {

  return (
    <Wrapper>
      <ContentAccordion items={[{id: '1', title: title, body: children, active: open}]} className={'accordion'}/>
    </Wrapper>
  );
};

export default Accordion;