import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {
  BackButton,
  BREAKPOINTS,
  Button,
  COLORS,
  ComponentMStyling,
  ComponentSStyling,
  ComponentTextStyle,
  DropdownButton,
  IconButton,
  ListRow,
  LoadingIndicator,
  PageWidth,
  Paginator,
  SearchBar,
  Size,
  SystemIcons,
} from '@laerdal/life-react-components';
import {useMediaMatch} from 'rooks';
import {InviteUserModal} from './components/InviteUserModal';
import {useUserManagementHelper} from './helpers/UserManagementHelper';
import {useNavigate} from 'react-router';
import { StyledPageWidth } from '../_commonComponents/StyledComponents';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.h3`
  margin: 0;

  ${BREAKPOINTS.MEDIUM}{
    display: flex;
  }
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 30%;
  gap: 32px; 
`;


const Subtitle = styled.h6`
  margin: 0;
  color: ${COLORS.neutral_600};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.neutral_200};
  border-radius: 8px;
  
  gap: 8px;
  padding: 8px 16px;
  
  ${BREAKPOINTS.MEDIUM}{
    gap: 16px;
    padding: 16px 24px;
  }
  
  ${BREAKPOINTS.LARGE}{
    max-width: 66%;
  }
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ListToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  
  & > div:first-child{
    flex: 1;
  }
  
  .sort{
    display: none;
  }
  ${BREAKPOINTS.MEDIUM}{
    .sort{
      display: flex;
    }
  }
`;

const ListContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListFooterContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const NotFoundMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.neutral_600)}
  ${BREAKPOINTS.LARGE}{
    ${ComponentMStyling(ComponentTextStyle.Regular, COLORS.neutral_600)}
  }
`;


export const UserManagementPage = () => {
  const {t} = useTranslation('Users');
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));

  const [showInviteUser, setShowInviteUser] = useState(false);
  const navigate = useNavigate();

  const userSortingOptions = [
    {
      value: 'date',
      displayLabel: t('Date Added'),
    },
    {
      value: 'name',
      displayLabel: t('Name A-Z'),
    },
  ];

  const {
    count,
    emails,
    listItems,
    loading,
    page,
    setSearchTerm,
    searchTerm,
    setSortBy,
    sortBy,
    canManage,
    organization,
    handleUserAdd,
  } = useUserManagementHelper();


  return (
    <StyledPageWidth useMaxWidth={true} maxWidth={1600}>
      {loading && <Wrapper><LoadingIndicator/></Wrapper>}
      {
        !loading &&
        <>
          <InviteUserModal isOpen={showInviteUser}
                           members={emails}
                           onClose={() => setShowInviteUser(false)}
                           onAdd={handleUserAdd}/>

          <Header>
            <BackButton size={Size.Small} onClick={() => navigate('/overview')}>{t('Back to overview')}</BackButton>
            <Title>{t('People')}</Title>
            <Description>{t('Only administrators can invite and manage access for people at your account')}</Description>
          </Header>
          <Content>
            <ContentHeader>
              <Subtitle>{organization.name}</Subtitle>
              {
                canManage && isMediumScreen &&
                <Button variant={'primary'}
                        id={'invite-button'}
                        size={isMediumScreen ? Size.Medium : Size.Small}
                        onClick={() => setShowInviteUser(true)}
                        icon={<SystemIcons.AddUser/>}>
                  {t('Invite People')}
                </Button>
              }
              {
                canManage && !isMediumScreen &&
                <IconButton id={'invite-button'}
                            shape={'circular'}
                            action={() => setShowInviteUser(true)}>
                  <SystemIcons.AddUser/>
                </IconButton>
              }

            </ContentHeader>
            <ListToolbarContainer>
              <SearchBar id={'user-search'}
                         searchTerm={searchTerm}
                         setSearchTerm={setSearchTerm}
                         size={isMediumScreen ? Size.Medium : Size.Small}
                         placeholder={t('Search by name or email')}
                         enterSearch={() => {
                         }}
                         removeSearch={() => setSearchTerm('')}/>
              <DropdownButton items={userSortingOptions}
                              onClick={setSortBy}
                              icon={<SystemIcons.Sort/>}
                              className={'sort'}
                              value={sortBy}
                              size={isMediumScreen ? Size.Medium : Size.Small}
                              type={'text'}/>
            </ListToolbarContainer>
            <ListContentContainer>
              {
                !!listItems.length &&
                listItems.map(({key, type, title, subtitle, note}) =>
                  <ListRow key={key}
                           size={isMediumScreen ? Size.Medium : Size.Small}
                           icon={type === 'member' ? <SystemIcons.User/> : <SystemIcons.Mail/>}
                           action={canManage ? () => navigate(`/users/details/${type}?email=${encodeURIComponent(key)}`) : undefined}
                           mainText={title}
                           secondaryText={subtitle}
                           note={note}/>,
                )
              }
              {
                !listItems.length &&
                <NotFoundMessage>
                  <Subtitle>
                    {t('No results to display')}
                  </Subtitle>
                  <div>
                    {t('Clear the field above to try searching again')}
                  </div>
                </NotFoundMessage>
              }
            </ListContentContainer>
            <ListFooterContainer>
              <Paginator pageCount={Math.ceil(count / 10)}
                         currentPage={+page}
                         baseUrl={'/users'}/>
            </ListFooterContainer>
          </Content>
        </>
      }
    </StyledPageWidth>
  );
};