import styled from 'styled-components';
import {
  BackButton,
  Banner,
  BREAKPOINTS,
  COLORS,
  ComponentM,
  ComponentTextStyle,
  ListRow,
  LoadingIndicator,
  PageWidth,
  Size,
  SystemIcons,
} from '@laerdal/life-react-components';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { selectUserProfile, selectUserWebshopMarketCountry } from '../../store/account/accountSlice';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ListHeader, ListSubtitle, ListTitle, StyledPageWidth } from '../_commonComponents/StyledComponents';
import { BasicCard } from '../_commonComponents/BasicBox';
import PropertyWrapper from '../_commonComponents/PropertyWrapper';
import PaymentModal from './PaymentModal';
import { CreditCardDto } from '../../model/dto/payment/creditCardDto';
import PaymentApi from '../../services/api/PaymentApi';
import { Helmet } from 'react-helmet';
import { CountriesSupportInfo, CountrySupportInfo } from '../../model/constants/CountrySupportInfo';
import { forEach } from 'lodash';
import { isEpiCountry } from '../../services/helpers/marketHelpers';
import { EmptyPageBox } from '../_commonComponents/EmptyPageBox';

const LoadingContainer = styled.div`
  padding: 50px;
`;


const Row = styled.div`
  width:100%;
  display: flex;
  gap: 8px;

  flex-direction: column;
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  ${BREAKPOINTS.MEDIUM}{
    flex-direction: row;
  }
`;
const DetailsContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow:1;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  gap: 16px;
  background: ${COLORS.primary_100};
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  height: 250px;
  
  ${BREAKPOINTS.MEDIUM}{
    width: 300px;
  }
`;

export const FullDiv = styled.div`
  width: 100%;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-grow:1;
  flex-direction: column;
  gap: 8px;
  
  ${BREAKPOINTS.MEDIUM}{
    width: 648px;
    gap: 32px;
  }
`;


const PaymentPage = () => {
  const { t } = useTranslation('Payment');
  const navigate = useNavigate();
  const user = useSelector(selectUserProfile);
  const marketCountry = useSelector(selectUserWebshopMarketCountry);

  const [cards, setCards] = useState<CreditCardDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [supportInfo, setSupportInfo] = useState<CountrySupportInfo | undefined>(undefined);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<CreditCardDto | undefined>();

  useEffect(() => {
    if(!!user){
      
      CountriesSupportInfo.forEach(x => {
        if(x.country.toLocaleLowerCase().trim() == user.currentOrganization?.address?.country?.name?.toLocaleLowerCase().trim()){
          setSupportInfo(x);
        }
      }); 
    }
  }, [user]);

  useEffect(() => {
    PaymentApi.GetCards().then((response) => {
      setCards(response);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{isEpiCountry(marketCountry) ? t('Payment and Billing') : t('Billing')}</title>
      </Helmet>
      <PaymentModal isOpen={isPaymentModalOpen} onClose={() => setIsPaymentModalOpen(false)} card={selectedCard}/>

      <StyledPageWidth useMaxWidth={true} maxWidth={1600}>
        <ListHeader>
          <BackButton size={Size.Small} onClick={() => navigate('/overview')}>{t('Back to overview')}</BackButton>
          <ListTitle>{isEpiCountry(marketCountry) ? t('Payment and Billing') : t('Billing')}</ListTitle>
          <ListSubtitle>{isEpiCountry(marketCountry) ? t('Manage payment methods') : t('Billing information')}</ListSubtitle>
        </ListHeader>

        <Row>
          <LeftColumn>
            <Row>
              <BasicCard>
                <ComponentM textStyle={ComponentTextStyle.Bold} >{t('Billing details')}</ComponentM>

                <DetailsContainer>
                  <DetailsContainerColumn>
                    {user?.currentOrganization?.billingOrganizationName && <PropertyWrapper label={t('Organization name')} value={user?.currentOrganization?.billingOrganizationName} />}
                    {user?.currentOrganization?.billingContactPersonFirstName && <PropertyWrapper label={t('Primary contact person')} value={`${user?.currentOrganization?.billingContactPersonFirstName} ${user?.currentOrganization?.billingContactPersonLastName}`} />}
                    {user?.currentOrganization?.billingEmailAddress && <PropertyWrapper label={t('Billing email address')} value={user?.currentOrganization?.billingEmailAddress} />}
                  </DetailsContainerColumn>

                  <DetailsContainerColumn>
                    <PropertyWrapper
                      label={t('Country / Region')}
                      value={user?.currentOrganization?.useOrganizationAddressForBilling ? user?.currentOrganization?.address?.country?.name : user?.currentOrganization?.billingAddress?.country?.name} />

                    <PropertyWrapper
                      label={t('Address')}
                      value={user?.currentOrganization?.useOrganizationAddressForBilling ? user?.currentOrganization?.address?.addressLine : user?.currentOrganization?.billingAddress?.addressLine} />

                    <PropertyWrapper
                      label={t('City')}
                      value={user?.currentOrganization?.useOrganizationAddressForBilling ? user?.currentOrganization?.address?.city : user?.currentOrganization?.billingAddress?.city} />

                    <PropertyWrapper
                      label={t('State')}
                      value={user?.currentOrganization?.useOrganizationAddressForBilling ? user?.currentOrganization?.address?.state : user?.currentOrganization?.billingAddress?.state} />

                    <PropertyWrapper
                      label={t('Zip / Postal code')}
                      value={user?.currentOrganization?.useOrganizationAddressForBilling ? user?.currentOrganization?.address?.zipCode : user?.currentOrganization?.billingAddress?.zipCode} />


                  </DetailsContainerColumn>
                </DetailsContainer>
                <FullDiv>
                  <Banner icon={<SystemIcons.Help />}
                    fullWidth={true}
                    size={Size.Medium}>
                    {t('To change these details, contact Laerdal Support.')}
                  </Banner>
                </FullDiv>
              </BasicCard>
            </Row>

            { isEpiCountry(marketCountry) &&
              <Row>
                {
                  loading && <LoadingContainer><LoadingIndicator/></LoadingContainer>
                }

                { !loading && !!cards?.length &&
                  <BasicCard>
                    <ComponentM textStyle={ComponentTextStyle.Bold} >{t('Stored cards')}</ComponentM>
                    <ComponentM  >{t('View your payment cards')}</ComponentM>  
                      {   
                      cards && 
                        <List>
                          {cards.map(c => 
                            <ListRow mainText={`${c.variant}   **** **** **** ${c.lastNumbers}`}
                              size={Size.Medium}
                              dropdown={{
                                width: '200px',
                                onClick: (values) => {
                                  setSelectedCard(c);
                                  setIsPaymentModalOpen(true)
                                },
                                items: [
                                  {
                                    value: t('Details')
                                  }
                                ]
                              }}
                            />
                          )}
                        </List>
                    }         
                  </BasicCard>
                }

                { !loading && !cards?.length &&
                  <EmptyPageBox 
                    title={t('Stored cards')} 
                    description={t('Saved payment cards will be displayed here.')}/>
                }
              </Row>
            }
          </LeftColumn>

          <Info>
            <ComponentM textStyle={ComponentTextStyle.Bold} >{t('Apply to pay against invoice')}</ComponentM>

            <p>
              {t('For more information, contact your local customer service team:')}
            </p>

            { !!supportInfo?.email &&
              <p style={{fontWeight: 700, color: COLORS.primary_600}}>
                {supportInfo?.email}
              </p>
            }

            { !!supportInfo?.phone &&
              <p style={{fontWeight: 700, color: COLORS.primary_600}}>
                {supportInfo?.phone}
              </p>
            }
          </Info>
        </Row>

      </StyledPageWidth>
    </>
  );
};


export default PaymentPage;