import {
  BREAKPOINTS,
  COLORS,
  DropdownFilter,
  InputLabel, ModalDialog,
  Size,
  States,
  Textarea,
  TextField,
  ToastContext,
} from '@laerdal/life-react-components';
import {useContext, useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import CaseApi from '../../services/api/CaseApi';
import {CaseCreateDto} from '../../model/dto/cases/caseCreateDto';
import {FailToastOptions, SuccessToastOptions} from '../../model/constants/ToastConstants';
import {useMediaMatch} from "rooks";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
`;

const TextAreaWrapper = styled.div`
  .textarea {
    font-family: 'Lato';
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    box-sizing: border-box;
  }
`;

const CaseDescription = styled(Textarea)`
  & > textarea {
    font-family: 'Lato' !important;
  }
`;


export interface Props {
  isOpen: boolean;
  onClose: () => void;
  afterSubmit?: () => void;
}

const CaseCreateModal = ({isOpen, onClose, afterSubmit}: Props) => {
  const {t} = useTranslation('Cases');
  const {addToast} = useContext(ToastContext);
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));

  const [creating, setCreating] = useState<boolean>(false);
  const [subject, setSubject] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [help, setHelp] = useState<string | undefined>(undefined);

  const helpList = [
    'Asset is missing or incorrect',
    'I have a question about an order or invoice',
    'I have a question about a product or service',
    'I wish to initiate a return',
    'I wish to update account or billing information',
    'My product does not work'
  ];

  const close = () => {
    setSubject(undefined);
    setDescription(undefined);
    setHelp(undefined);
    onClose();
  }

  const submit = () => {
    if (subject === undefined) setSubject('');
    if (description === undefined) setDescription('');
    if (help === undefined) setHelp('');
    if (!!help && !!subject && !!description) {
      const dto = {
        description,
        subject,
        howCanWeHelp: helpList.find(helpListItem => help == t(helpListItem))
      } as CaseCreateDto;
      setCreating(true);
      CaseApi.CreateCase(dto)
        .then(() => {
            //@ts-ignore
            posthog.capture?.('CreateCaseModal Created');
            addToast(t('Case created'), SuccessToastOptions);
            afterSubmit && afterSubmit();
            close();
          }
        )
        .catch(() => {
            //@ts-ignore
            posthog.capture?.('CreateCaseModal Error');
            addToast(t('Error when creating case'), FailToastOptions)
          }
        ).finally(() => {
        setCreating(false);
      });
    }
  };

  return (
    <ModalDialog isModalOpen={isOpen}
                 closeAction={close}
                 size={isMediumScreen ? Size.Medium : Size.Small}
                 closeModalAndClearInput={close}
                 submitAction={(e) => e?.preventDefault()}
                 buttons={[{
                   variant: 'tertiary',
                   action: close,
                   disabled: creating,
                   text: t('Cancel')
                 }, {
                   variant: 'primary',
                   action: submit,
                   text: t('Submit'),
                   loading: creating
                 }
                 ]}
                 title={t('Create new case')}>
      <Wrapper>
        <Description>
          {t('Complete this form to ask a question or report an issue to our customer support team.')}
        </Description>

        <InputLabel inputId="helpDropdown" text={t('How can we help?')} size={Size.Medium}/>
        <DropdownFilter
          scrollable={true}
          size={Size.Medium}
          id="helpDropdown"
          list={helpList.map((helpItem: string) => ({value: t(helpItem)}))}
          placeholder={t('Select...')}
          activeValidationMessage={help === undefined || help.length > 0 ? '' : t('This field is required')}
          onSelect={(value: string) => {
            setHelp(value)
          }}
        />

        <InputLabel inputId="subjectField" text={t('Subject')} size={Size.Medium}/>
        <TextField
          id="subjectField"
          placeholder={t('e.g. Simpad not working')}
          size={Size.Medium}
          validationMessage={subject === undefined || subject.length > 0 ? '' : t('This field is required')}
          onChange={(value) => {
            setSubject(value);
          }}
          state={subject?.length === 0 ? States.Invalid : undefined}
        />

        <InputLabel inputId="descriptionField" text={t('Describe your issue')}/>
        <TextAreaWrapper>
          <CaseDescription
            id="descriptionField"
            placeholder={t('What are you trying to do or tried so far?')}
            rows={5}
            className={'textarea'}
            validationMessage={description === undefined || description.length > 0 ? '' : t('This field is required')}
            onChange={(value) => setDescription(value)}
            state={description?.length === 0 ? States.Invalid : undefined}
            size={Size.Medium}
          />
        </TextAreaWrapper>
      </Wrapper>
    </ModalDialog>);
}

export default CaseCreateModal;