import {
    BREAKPOINTS,
    Button,
    Card,
    DropdownButton,
    DropdownItem,
    LoadingIndicator,
    SearchBar,
    SegmentControl,
    Size,
    Table,
    TableColumn,
    COLORS,
    SystemIcons,
    ComponentL,
    ComponentTextStyle,
    HorizontalCard,
    CardTag,
    Banner,
    FilterChip,
    HorizontalCardIconButton,
    HorizontalCardToggleButton,
    HorizontalCardDropdownButton,
    HorizontalCardCustomContent
} from '@laerdal/life-react-components';
import {
    useEffect,
    useMemo,
    useState
} from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { AppointmentListItemDto } from "../../model/dto/appointments/appointmentListItemDto";
import AppointmentsApi from "../../services/api/AppointmentsApi";
import styled from "styled-components";
import { useDebouncedValue } from "rooks";
import moment from "moment-timezone";
import AppointmentStatus from './AppointmentStatus';
import { useSelector } from 'react-redux';
import { selectUserProfile } from '../../store/account/accountSlice';
import { ListHeader, ListPreTitle, ListTitle, StyledPageWidth } from '../_commonComponents/StyledComponents';
import { EmptyPageBox } from '../_commonComponents/EmptyPageBox';
import { useFeatures } from '../../hooks/Features';
import { FeatureNames } from '../../model/constants/FeatureConstants';

const SegmentControllContainer = styled.div`
  display: flex;
  
  & > div{
    flex: 1;
  }
  ${BREAKPOINTS.MEDIUM}{
    width: 428px;
  }
`;

const SearchBarContainer = styled.div`
  display: flex;

  & > div{
    flex: 1;
  }

  ${BREAKPOINTS.MEDIUM}{
    width: 360px;
  }
`;

const FilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    ${BREAKPOINTS.LARGE}{
        flex-direction: row;
    }
`;

const TableContainer = styled.div`
    overflow-x: auto;
    background: white;
`;

const Frame = styled.div`
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: normal;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid ${COLORS.neutral_200};
    background: ${COLORS.white};
`;

const ChipWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap:8px;
`;

const SectionHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
const SectionHeaderRight = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
`;


const UpcomingContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;

    ${BREAKPOINTS.MEDIUM}{
        grid-template-columns: 1fr 1fr;
    }
    
    ${BREAKPOINTS.LARGE}{
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

const UnscheduledHorizontalCard = styled(HorizontalCard)`
    & > div {
        background: ${COLORS.accent1_20}
    }
`

const AppointmentsPage = () => {
    const { t, i18n } = useTranslation(['Appointments', 'Common']);
    const navigate = useNavigate();

    const user = useSelector(selectUserProfile);

    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [initialLoad, setInitialLoad] = useState<boolean>(true);
    const [hasItems, setHasItems] = useState<boolean>(false);

    const [isOverview, setIsOverview] = useState<boolean>(true);

    const [upcomingWorkOrderType, setUpcomingWorkOrderType] = useState<string>('');
    const [upcomingAppointments, setUpcomingAppointments] = useState<AppointmentListItemDto[]>([]);

    const [unscheduledWorkOrderType, setUnscheduledWorkOrderType] = useState<string>('');
    const [unscheduledAppointments, setUnscheduledAppointments] = useState<AppointmentListItemDto[]>([]);

    const [allWorkOrderType, setAllWorkOrderType] = useState<string>('');
    const [allYear, setAllYear] = useState<number>((new Date).getFullYear());
    const [years, setYears] = useState<DropdownItem[]>([]);
    const [allShowUnscheduled, setAllShowUnscheduled] = useState<boolean>(false);
    const [allShowUnscheduledEnabled, setAllShowUnscheduledEnabled] = useState<boolean>(false);
    const [allShowExpired, setAllShowExpired] = useState<boolean>(false);
    const [allShowExpiredEnabled, setAllShowExpiredEnabled] = useState<boolean>(false);

    const [onHoldAppointments, setOnHoldAppointments] = useState<AppointmentListItemDto[]>([]);

    const [loading, setLoading] = useState<boolean>(true);

    const [appointments, setAppointments] = useState<AppointmentListItemDto[]>([]);
    const [baseAppointments, setBaseAppointments] = useState<AppointmentListItemDto[]>([]);

    const columns = useMemo<TableColumn[]>(() => [
        {
            key: 'scheduleStart',
            name: t('Date'),
            sortable: false,
            type: "custom",
            customContent: (row: AppointmentListItemDto, key: string) =>
                (row.scheduleStart || row.workOrderDueDate) && (!row.scheduleStart ? moment(row.workOrderDueDate as string).format('YYYY') : moment(row.scheduleStart as string).format('ll'))
        },
        {
            key: "workType",
            name: t("Subject"),
            sortable: false
        },
        {
            key: 'serviceAppointmentsStatusTranslated',
            name: t('Status'),
            sortable: false,
            width: 210,
            type: 'custom',
            customContent: (row: AppointmentListItemDto, key: string) => <AppointmentStatus status={row.serviceAppointmentsStatus} statusTranslated={row.serviceAppointmentsStatusTranslated} />
        },
        {
            key: "deliveryTypeTranslated",
            name: t("Type"),
            width: 250,
            sortable: false
        },
        {
            key: 'contactName',
            name: t('Customer contact'),
            sortable: false,
        },
    ], [t]);

    const currentDate = new Date();

    const workOrderTypes = useMemo<DropdownItem[]>(() => [
        {
            value: '',
            displayLabel: t('All event types'),
        },
        {
            value: 'Technical Service Delivery',
            displayLabel: t('Technical appointments'),
            showDividerAbove: true
        },
        {
            value: 'Educational Service Delivery',
            displayLabel: t('Educational appointments'),
        }
    ], [t]);

    const [searchTermDebounce] = useDebouncedValue(searchTerm, 300);

    const upcomingFilter = (x: AppointmentListItemDto): boolean => new Date(x.scheduleStart ?? '') > currentDate
                                                                    && ( x.serviceAppointmentsStatus == 'Dispatched' 
                                                                        || x.serviceAppointmentsStatus == 'Scheduled' 
                                                                        || x.serviceAppointmentsStatus == 'Requested');
    const onHoldFilter = (x: AppointmentListItemDto): boolean => x.serviceAppointmentsStatus == 'On hold' || x.serviceAppointmentsStatus == 'Needs Reschedule' ;
    const unscheduledFilter = (x: AppointmentListItemDto): boolean => 
                                (x.serviceAppointmentsStatus == 'Unscheduled' && x.canBeScheduled == false)
                                || x.serviceAppointmentsSfStatus == 'Ready to Self-Schedule';

    //@ts-ignore
    const searchTermFilter = (x: AppointmentListItemDto, searchTerm: string | undefined): boolean => !searchTerm || x.workType?.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0;
    const allYearFilter = (x: AppointmentListItemDto, year: number): boolean =>
        (!x.scheduleStart && !x.workOrderDueDate)
        || (!!x.scheduleStart && (new Date(x.scheduleStart)).getFullYear() == year)
        || (!!x.workOrderDueDate && (new Date(x.workOrderDueDate)).getFullYear() == year);

    const scheduledYearFilter = (x: AppointmentListItemDto, year: number): boolean => !!x.scheduleStart && (new Date(x.scheduleStart)).getFullYear() == year;

    type ActionType = (HorizontalCardIconButton | HorizontalCardToggleButton | HorizontalCardDropdownButton | HorizontalCardCustomContent);
    const appointmentActions = (appointment: AppointmentListItemDto, t: any): ActionType[] => {
        const actions: ActionType[] = [
            { componentType: 'icon', action: () => { }, icon: <SystemIcons.ChevronRight />, tooltip: { label: 'Tooltip' } },
        ];


        if (appointment.canBeScheduled) {
            if (appointment.serviceAppointmentsSfStatus == 'Ready to Self-Schedule') {
                actions.unshift(
                    {
                        componentType: 'custom',
                        content: <Button size={Size.Medium} variant="secondary" onClick={(e) => {
                            e.stopPropagation();
                            //@ts-ignore
                            posthog.capture?.('AppointmentsPage ActionBook',{
                                serviceAppointmentNumber: appointment.serviceAppointmentNumber
                            });
                            navigate(`details/${appointment.serviceAppointmentNumber}/book`);
                        }}>
                            {t('Schedule')}
                        </Button>
                    }
                )
            }
            else if (appointment.serviceAppointmentsStatus == 'Requested' ||
                    appointment.serviceAppointmentsStatus == 'Needs reschedule' ||
                    appointment.serviceAppointmentsStatus == 'Scheduled') {

                actions.push(
                    {
                        componentType: 'custom',
                        content: <Button 
                                    size={Size.Medium} 
                                    variant="secondary" 
                                    onClick={(e) => { 
                                        //@ts-ignore
                                        posthog.capture?.('AppointmentsPage ActionReschedule',{
                                            serviceAppointmentNumber: appointment.serviceAppointmentNumber
                                        });
                                        e.stopPropagation(); 
                                        navigate(`details/${appointment.serviceAppointmentNumber}/book`)  
                                    }}>
                            {t('Reschedule')}
                        </Button>
                    }
                )
            }
        }

        return actions;
    }

    useEffect(() => {
        setLoading(true);

        AppointmentsApi.GetAppointments(i18n.language, searchTerm, '', '', '').then((response) => {

            let tempYears: DropdownItem[] = [];
            tempYears.push({ value: currentDate.getFullYear().toString() });

            for (let i = 0; i < response.items.length; i++) {
                if (!!response.items[i].workOrderDueDate) {
                    const year = new Date(response.items[i].workOrderDueDate!).getFullYear();
                    if (!tempYears.find(x => x.value == year.toString()))
                        tempYears.push({ value: year.toString() });
                }

                if (!!response.items[i].scheduleStart) {
                    const year = new Date(response.items[i].scheduleStart!).getFullYear();
                    if (!tempYears.find(x => x.value == year.toString()))
                        tempYears.push({ value: year.toString() });
                }
            }
            
            tempYears.sort((a, b) => Number(b.value) - Number(a.value))
            setYears(tempYears);
            
            setBaseAppointments(response.items);

            setUpcomingAppointments(response?.items?.filter(upcomingFilter));
            setOnHoldAppointments(response?.items?.filter(onHoldFilter));
            setUnscheduledAppointments(response?.items?.filter(unscheduledFilter));
            setLoading(false);
            if (response.items.length > 0) setHasItems(true);
                setInitialLoad(false);
        });
    }, []);

    useEffect(() => {
        setAppointments(baseAppointments.filter(x => searchTermFilter(x, searchTermDebounce)));
        setUpcomingAppointments(baseAppointments
            .filter(upcomingFilter)
            .filter(x => searchTermFilter(x, searchTermDebounce))
            .filter(x => upcomingWorkOrderType == '' || x.workOrderType == upcomingWorkOrderType)
            .filter(x => scheduledYearFilter(x, (new Date).getFullYear())));

        setOnHoldAppointments(baseAppointments
            .filter(onHoldFilter)
            .filter(x => searchTermFilter(x, searchTermDebounce))
            .filter(x => unscheduledWorkOrderType == '' || x.workOrderType == unscheduledWorkOrderType)
        );

        setUnscheduledAppointments(baseAppointments
            .filter(unscheduledFilter)
            .filter(x => searchTermFilter(x, searchTermDebounce))
            .filter(x => unscheduledWorkOrderType == '' || x.workOrderType == unscheduledWorkOrderType)
        );

    }, [searchTermDebounce, unscheduledWorkOrderType, upcomingWorkOrderType]);

    
    useEffect(() => {
        //@ts-ignore
        posthog.capture?.('AppointmentsPage Search',{
            selected: searchTermDebounce
        });
    }, [searchTermDebounce]);

    useEffect(() => {
        setAppointments(
            baseAppointments
                .filter(x => searchTermFilter(x, searchTermDebounce))
                .filter(x => allShowUnscheduled || x.serviceAppointmentsStatus?.toLowerCase() != 'unscheduled')
                .filter(x => allShowExpired || x.serviceAppointmentsStatus?.toLowerCase() != 'expired')
                .filter(x => allWorkOrderType == '' || x.workOrderType == allWorkOrderType)
                .filter(x => allYearFilter(x, allYear))
        );

        const unscheduledEnabled = baseAppointments
            .filter(x => searchTermFilter(x, searchTermDebounce))
            .filter(x => allWorkOrderType == '' || x.workOrderType == allWorkOrderType)
            .filter(x => allYearFilter(x, allYear))
            .some(x => x.serviceAppointmentsStatus?.toLowerCase() == 'unscheduled');

        const expiredEnabled = baseAppointments
            .filter(x => searchTermFilter(x, searchTermDebounce))
            .filter(x => allWorkOrderType == '' || x.workOrderType == allWorkOrderType)
            .filter(x => allYearFilter(x, allYear))
            .some(x => x.serviceAppointmentsStatus?.toLowerCase() == 'expired')

        setAllShowUnscheduledEnabled(unscheduledEnabled);
        setAllShowExpiredEnabled(expiredEnabled);

        if (unscheduledEnabled == false) setAllShowUnscheduled(false);
        if (expiredEnabled == false) setAllShowExpired(false);
    }, [searchTermDebounce, allYear, allWorkOrderType, allShowExpired, allShowUnscheduled, isOverview]);

    const openAppointmentDetails = (row: AppointmentListItemDto) => {
        navigate(`details/${row.serviceAppointmentNumber}`);
    }

    return (
        <>
            <Helmet>
                <title>{t('Appointments')}</title>
            </Helmet>
            <StyledPageWidth useMaxWidth={true} maxWidth={1600}>
                <ListHeader>
                    <ListTitle>{t('Appointments')}</ListTitle>
                    <ListPreTitle>{user!.currentOrganization!.name}</ListPreTitle>
                </ListHeader>

                {initialLoad &&
                    <LoadingIndicator />
                }

                {!initialLoad && !hasItems &&
                    <EmptyPageBox
                        title={t('Your appointments')}
                        description={t('Scheduled events for technical support or product servicing will be displayed here.')}
                        ilustrationPath='/assets/technical_appointment.svg' />
                }

                {!initialLoad && hasItems &&
                    <>
                        <FilterContainer>
                            <SegmentControllContainer>
                                <SegmentControl
                                    items={
                                        [{ key: 'overview', content: t('Overview'), disabled: false },
                                        { key: 'all', content: t('All appointments'), disabled: false }]}
                                    selected={!!searchTerm ? 'all' :
                                        isOverview ? 'overview' : 'all'}
                                    onChange={(key) => {
                                        //@ts-ignore
                                        posthog.capture?.('AppointmentsPage MainFilter',{
                                            selected: key
                                        });
                                        setIsOverview(key == 'overview')
                                    }}
                                    size={Size.Small}
                                >
                                </SegmentControl>
                            </SegmentControllContainer>

                            <SearchBarContainer>
                                <SearchBar
                                    enterSearch={() => { }}
                                    id="appointmentSearchField"
                                    placeholder={t('Search by subject')}
                                    size={Size.Medium}
                                    setSearchTerm={setSearchTerm}
                                    removeSearch={() => setSearchTerm('')}
                                />
                            </SearchBarContainer>
                        </FilterContainer>



                        {(!searchTerm && isOverview) &&
                            <>
                                <SectionHeader>
                                    <h6>{`${t('Upcoming')} (${upcomingAppointments.length})`}</h6>
                                    <DropdownButton
                                        items={workOrderTypes}
                                        size={Size.Small}
                                        onClick={value => {
                                            //@ts-ignore
                                            posthog.capture?.('AppointmentsPage UpcomingType',{
                                                type: value[0]
                                            });
                                            setUpcomingWorkOrderType(value[0])}
                                        }
                                        value={[upcomingWorkOrderType !== '' ? upcomingWorkOrderType : workOrderTypes[0].value]}
                                        type={'text'} />
                                </SectionHeader>
                                {!upcomingAppointments?.length &&
                                    <Banner type="neutral" noIcon size={Size.Small}>
                                        {t('No appointments')}
                                    </Banner>
                                }

                                <UpcomingContainer>
                                    {
                                        upcomingAppointments?.map((appointment, i) =>
                                            <Card
                                                key={'upcomming'+i}
                                                variant='outline'
                                                middleSectionProps={{
                                                    categoryLabel: `${moment(appointment.scheduleStart).format("MMM D, YYYY - h:mm A")} (${moment.tz(moment.tz.guess()).format('z')})`,
                                                    title: appointment.workType ?? '',
                                                    description: `${t('Service contact')}: ${appointment.assignedResource ?? appointment.assignedResource}`,
                                                    colorBandColor: COLORS.primary_500,
                                                    tags: [{
                                                        label: appointment.deliveryTypeTranslated ?? '',
                                                        variant: 'neutral',
                                                        icon: <SystemIcons.MapPoint />
                                                    } as CardTag, {
                                                        label: appointment.workOrderType ?? '',
                                                        variant: 'neutral'
                                                    } as CardTag
                                                    ].filter(x => !!x.label)
                                                }}
                                                onCardClicked={
                                                    () => {
                                                        //@ts-ignore
                                                        posthog.capture?.('AppointmentsPage UpcomingCardClick',{
                                                            serviceAppointmentNumber: appointment.serviceAppointmentNumber
                                                        });
                                                        navigate(`details/${appointment.serviceAppointmentNumber}`);
                                                        return {};
                                                    }
                                                }
                                            />
                                        )
                                    }
                                </UpcomingContainer>


                                <SectionHeader>
                                    <h6>{`${t('Unscheduled')} (${unscheduledAppointments.length + onHoldAppointments.length})`}</h6>
                                    <SectionHeaderRight>
                                        <DropdownButton
                                            items={workOrderTypes}
                                            size={Size.Small}
                                            onClick={value => {    
                                                //@ts-ignore
                                                posthog.capture?.('AppointmentsPage UnscheduledCardType',{
                                                    type: value[0]
                                                });
                                                setUnscheduledWorkOrderType(value[0])
                                            }}
                                            value={[unscheduledWorkOrderType !== '' ? unscheduledWorkOrderType : workOrderTypes[0].value]}
                                            type={'text'} />
                                    </SectionHeaderRight>
                                </SectionHeader>
                                {!(unscheduledAppointments.length + onHoldAppointments.length) &&
                                    <Banner type="neutral" noIcon size={Size.Small}>
                                        {t('No appointments')}
                                    </Banner>
                                }

                                {!!onHoldAppointments?.length &&
                                    <Frame>
                                        <ComponentL color={COLORS.neutral_500} textStyle={ComponentTextStyle.Bold}>{`${t('On hold')} (${onHoldAppointments.length})`}</ComponentL>
                                        {
                                            onHoldAppointments?.map((appointment, i) =>
                                                <UnscheduledHorizontalCard  
                                                key={'onHold'+i}
                                                    title={appointment.workType ?? ''}
                                                    description={
                                                        !!appointment.assignedResource ?
                                                            `${t('Service contact')}: ${appointment.assignedResource}`
                                                            : ''
                                                    }
                                                    variant='outline'
                                                    tags={[{
                                                        label: appointment.deliveryTypeTranslated ?? '',
                                                        variant: 'neutral',
                                                        icon: <SystemIcons.MapPoint />
                                                    } as CardTag, {
                                                        label: appointment.workOrderType ?? '',
                                                        variant: 'neutral'
                                                    } as CardTag
                                                    ].filter(x => !!x.label)}
                                                    actions={
                                                        appointmentActions(appointment, t)}
                                                    action={() => {
                                                        //@ts-ignore
                                                        posthog.capture?.('AppointmentsPage OnHoldCardClick',{
                                                            serviceAppointmentNumber: appointment.serviceAppointmentNumber
                                                        });
                                                        navigate(`details/${appointment.serviceAppointmentNumber}`);
                                                    }} />
                                            )
                                        }
                                    </Frame>
                                }

                                {!!unscheduledAppointments?.length &&

                                    <Frame>
                                        <ComponentL color={COLORS.neutral_500} textStyle={ComponentTextStyle.Bold}> {`${t('Unscheduled')} (${unscheduledAppointments.length})`} </ComponentL>

                                        {
                                            unscheduledAppointments?.map((appointment, i) =>
                                                <HorizontalCard
                                                    key={'unscheduled'+i}
                                                    title={appointment.workType ?? ''}
                                                    description={
                                                        !!appointment.assignedResource ?
                                                            `${t('Service contact')}: ${appointment.assignedResource}`
                                                            : ''
                                                    }
                                                    variant='outline'
                                                    tags={[{
                                                        label: appointment.deliveryTypeTranslated ?? '',
                                                        variant: 'neutral',
                                                        icon: <SystemIcons.MapPoint />
                                                    } as CardTag, {
                                                        label: appointment.workOrderType ?? '',
                                                        variant: 'neutral'
                                                    } as CardTag
                                                    ].filter(x => !!x.label)}
                                                    actions={
                                                        appointmentActions(appointment, t)}
                                                    action={() => {
                                                        //@ts-ignore
                                                        posthog.capture?.('AppointmentsPage UnscheduledClick',{
                                                            serviceAppointmentNumber: appointment.serviceAppointmentNumber
                                                        });
                                                        navigate(`details/${appointment.serviceAppointmentNumber}`);
                                                    }} />
                                            )
                                        }
                                    </Frame>
                                }
                            </>
                        }


                        {(!!searchTerm || !isOverview) &&
                            <>
                                <SectionHeader>
                                    <h6>{t('All Appointments')}</h6>
                                    <SectionHeaderRight>
                                        <DropdownButton
                                            icon={<SystemIcons.Calendar />}
                                            items={years}
                                            size={Size.Small}
                                            onClick={value => {
                                                //@ts-ignore
                                                posthog.capture?.('AppointmentsPage AllAppointmentYear',{
                                                    year: value[0]
                                                });
                                                setAllYear(Number(value[0]))
                                            }}
                                            value={[allYear.toString()]}
                                            type={'text'} />
                                        <DropdownButton
                                            items={workOrderTypes}
                                            size={Size.Small}
                                            onClick={value => {
                                                //@ts-ignore
                                                posthog.capture?.('AppointmentsPage AllAppointmentType',{
                                                    type: value[0]
                                                });
                                                setAllWorkOrderType(value[0])
                                            }}
                                            value={[allWorkOrderType !== '' ? allWorkOrderType : workOrderTypes[0].value]}
                                            type={'text'} />
                                    </SectionHeaderRight>
                                </SectionHeader>
                                <ChipWrapper>
                                    <FilterChip
                                        disabled={!allShowUnscheduledEnabled}
                                        text={t('Show unscheduled')}
                                        selected={allShowUnscheduled}
                                        size={Size.Large}
                                        onClick={() => {
                                            //@ts-ignore
                                            posthog.capture?.('AppointmentsPage ShowUnscheduled');
                                            setAllShowUnscheduled(!allShowUnscheduled)}
                                        } />
                                    <FilterChip
                                        disabled={!allShowExpiredEnabled}
                                        text={t('Show expired')}
                                        selected={allShowExpired}
                                        size={Size.Large}
                                        onClick={() => {
                                            //@ts-ignore
                                            posthog.capture?.('AppointmentsPage ShowExpired');
                                            setAllShowExpired(!allShowExpired)
                                        }} />
                                </ChipWrapper>
                                <TableContainer>
                                    <Table
                                        noRowsLabel={t('There are no rows to display', { 'ns': 'Common' })}
                                        rowsPerPageLabel={t('Rows per page:', { 'ns': 'Common' })}
                                        columns={columns}
                                        rows={appointments}
                                        remoteOperations={false}
                                        showLoadingIndicator={loading}
                                        onSelectionChange={(row: AppointmentListItemDto) => {
                                            //@ts-ignore
                                            posthog.capture?.('AppointmentsPage TableClick',{
                                                serviceAppointmentNumber: row.serviceAppointmentNumber
                                            });
                                            openAppointmentDetails(row)
                                        }}
                                        selectable={true}
                                    />
                                </TableContainer>
                            </>
                        }

                    </>
                }
            </StyledPageWidth>
        </>);
}

export default AppointmentsPage;



