import {
  BREAKPOINTS,
  COLORS,
  ComponentLStyling,
  ComponentMStyling,
  ComponentSStyling,
  ComponentTextStyle,
  PageWidth,
} from "@laerdal/life-react-components";
import styled from "styled-components";

export const ListTitle = styled.h3`
  margin: 0;

  ${BREAKPOINTS.LARGE}{
    display: flex;
  }
`;

export const ListPreTitle = styled.div`
  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.neutral_500)}

  ${BREAKPOINTS.LARGE}{
    display: flex;
    ${ComponentLStyling(ComponentTextStyle.Regular, COLORS.neutral_500)}
  }
`;

export const ListSubtitle = styled.p`
  margin: 0;
  padding: 0;
  color: #000;
`;

export const ListHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledPageWidth = styled(PageWidth)`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    padding: 16px 16px ;
    gap: 16px;
    margin: 0 auto;

    ${BREAKPOINTS.MEDIUM}{
      padding: 24px 32px;
      gap: 24px;
    }

    ${BREAKPOINTS.LARGE}{
        gap: 32px;
        padding: 32px 64px;
        box-sizing: border-box;
    }
`;