import {useTranslation} from "react-i18next";
import {
  BREAKPOINTS,
  COLORS,
  ComponentLStyling,
  ComponentMStyling,
  ComponentSStyling,
  ComponentTextStyle,
  defaultOnMouseDownHandler, HyperLink,
  Tile,
  TileFooterProps
} from "@laerdal/life-react-components";
import styled from "styled-components";
import {useDynamicSize} from "../../../util/DynamicSize";
import {PaymentType} from "../../../model/dto/organization/paymentType";
import {useSelector} from "react-redux";
import {
  selectSubscription,
  selectSubscriptionBillingDetails,
  selectSubscriptionService,
  selectSubscriptionTransactions
} from "../../../store/subscription/subscriptionSlice";
import {CardType} from "../../../model/dto/organization/cardType";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";
import {LicenseInformationHelper} from "../helpers/LicenseInformationHelper";
import {VALID_SUBSCRIPTION_STATUSES} from "../../../model/dto/organization/subscriptionStatus";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${COLORS.neutral_100};
  border-radius: 4px;
  gap: 4px;
  padding: 16px;

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
`;

const Header = styled.div`
  ${ComponentSStyling(ComponentTextStyle.Bold, COLORS.black)}
  ${BREAKPOINTS.MEDIUM} {
    ${ComponentMStyling(ComponentTextStyle.Bold, COLORS.black)}
  }

  ${BREAKPOINTS.LARGE} {
    ${ComponentLStyling(ComponentTextStyle.Bold, COLORS.black)}
  }
`;

const Description = styled.div`
  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.black)}
  ${BREAKPOINTS.MEDIUM} {
    ${ComponentMStyling(ComponentTextStyle.Regular, COLORS.black)}
  }

  ${BREAKPOINTS.LARGE} {
    ${ComponentLStyling(ComponentTextStyle.Regular, COLORS.black)}
  }

  &.invalid {
    color: ${COLORS.critical_500};
  }
`;

const EmptyMessage = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.neutral_500)}
`;

export const LicensePaymentDetailsCard = () => {
  const {t} = useTranslation('License');
  const size = useDynamicSize();
  const navigate = useNavigate();

  const billingDetails = useSelector(selectSubscriptionBillingDetails);
  const service = useSelector(selectSubscriptionService);
  const subscription = useSelector(selectSubscription);
  const transactions = useSelector(selectSubscriptionTransactions);

  const header = {
    title: t('Payment method'),
    subtitle: t('Change how you pay for your plan.')
  }
  const footer =
    !!transactions
      ? {
        leftItem: {
          componentType: 'link',
          onMouseDown: defaultOnMouseDownHandler,
          href: '#',
          onClick: e => {
            e.preventDefault();
            e.stopPropagation();
            document.getElementById('billing-history')?.scrollIntoView({behavior: 'smooth'});
          },
          linkText: t('Billing history'),
          variant: 'default',
        }
      } as TileFooterProps
      : {}

  const isCardPayment = subscription?.paymentType == PaymentType.Paddle || subscription?.paymentType == PaymentType.Adyen;

  const isInvoicePayment = subscription?.paymentType == PaymentType.Invoice || subscription?.paymentType == null;

  const isCardExpired = LicenseInformationHelper.IsCardExpired(billingDetails);
  const cardExpiryDate = LicenseInformationHelper.CardExpiryDate(billingDetails);
  return (
    <Tile header={header}
          style={{height: 'max-content'}}
          size={size}
          footer={footer}>
      <Wrapper>
        {
          isCardPayment &&
          !!billingDetails?.cardLastFourDigits &&
          <>
            <Header>{t('Credit card')}</Header>
            <Description>{CardType[billingDetails!.cardType!]}-{billingDetails!.cardLastFourDigits}</Description>
            <Description
              className={isCardExpired ? 'invalid' : ''}>{t('Expiry {{date}}', {date: dayjs(cardExpiryDate).format('MM/YYYY')})}</Description>
            {
              VALID_SUBSCRIPTION_STATUSES.includes(subscription.status) &&
              <HyperLink variant={'default'}
                         href="#"
                         style={{width: 'max-content'}}
                         onClick={e => {
                           e.preventDefault();
                           e.stopPropagation();

                           //@ts-ignore
                           posthog.capture?.('AC SubscriptionManageCreditCard Opened', {
                             subscription_name: service!.name,
                             subscription_number: subscription!.subscriptionNumber || subscription!.id,
                             payment_provider: PaymentType[subscription!.paymentType],
                           });

                           navigate('edit/payment');
                         }}>
                {t('Update')}
              </HyperLink>
            }
          </>
        }
        {
          isInvoicePayment &&
          billingDetails?.email &&
          <>
            <Header>{t('Pay by invoice')}</Header>
            <Description>{t('Contact: {{name}}', {name: `${billingDetails.firstName || ''} ${billingDetails.lastName || ''}`})}</Description>
            <Description>{t('Send to: {{name}}', {name: billingDetails.email})}</Description>
          </>
        }
        {
          (isCardPayment && !billingDetails?.cardLastFourDigits || isInvoicePayment && !billingDetails?.email) &&
          <EmptyMessage>
            {t('No payment method found')}
          </EmptyMessage>
        }
      </Wrapper>
    </Tile>
  )

}