import {useSelector} from 'react-redux';
import {selectUserPermissions, selectUserProfile} from '../store/account/accountSlice';
import {
  hasSubscriptionManagementPermissions,
  hasSubscriptionOwnerManagementPermissions
} from '../util/permissions-helper';

export enum PermissionsType {

  Subscription = 'Subscription',
  SubscriptionOwner = 'SubscriptionOwner',

  AccountOwner = 'account.owner',
  ViewAppointments = 'myaccount.viewappointments',
  ViewOrders = 'myaccount.vieworders',
  ViewSupportCases = 'myaccount.viewsupportcases',
  ManageAccess = 'organization.manageaccess',
  ManageProducts = 'organization.manageproducts',
  UpdateProducts = 'organization.updateproducts',
  ViewProducts = 'organization.viewproducts',
  PurchaseOnAccount = 'webshop.purchaseonbehalfoforganization',
}

export const usePermissions = () => {
  const user = useSelector(selectUserProfile);
  const permissions = useSelector(selectUserPermissions);

  const hasPermissions = (type: PermissionsType, data?: any, data2?: any): boolean => {
    const currentOrganization = user?.currentOrganization;

    switch (type) {
      case PermissionsType.ManageProducts:
      case PermissionsType.ViewProducts:
        if (!currentOrganization?.allowProducts) {
          return false;
        }
        break;
      case PermissionsType.ViewAppointments:
        if (!currentOrganization?.allowServiceAppointments) {
          return false;
        }
        break;
      case PermissionsType.ViewOrders:
        if (!currentOrganization?.allowOrders) {
          return false;
        }
        break;
      default:
        break;
    }

    //specific to Scenario cloud
    if (type == PermissionsType.Subscription &&
      data?.toLowerCase() === process.env.REACT_APP_SCENARIO_CLOUD_SERVICE_ID?.toLowerCase())
      return hasSubscriptionManagementPermissions(user, data);

    if (permissions?.includes(PermissionsType.AccountOwner)) {
      return true;
    }

    switch (type) {
      case PermissionsType.Subscription:
        return hasSubscriptionManagementPermissions(user, data);
      case PermissionsType.SubscriptionOwner:
        return hasSubscriptionOwnerManagementPermissions(user, data);
      default:
        return !!permissions?.includes(type);
    }
  };

  return {hasPermissions};
};