import React, {useContext, useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {
  BREAKPOINTS,
  ChipDropdownInput,
  COLORS,
  DropdownFilter, InputLabel,
  ModalDialog,
  Size,
  ToastContext,
} from '@laerdal/life-react-components';
import styled from 'styled-components';
import {Service} from '../../../model/dto/userProfile/service';
import {OrganizationService} from '../../../model/dto/organization/organizationService';
import {OrganizationMember} from '../../../model/dto/organization/organizationMember';
import {
  OrganizationServiceInvitationResponse,
} from '../../../model/dto/organization/organizationServiceInvitationResponse';
import {ServiceRole} from '../../../model/dto/userProfile/serviceRole';
import {isValidEmailAddress} from '../../../util/validation-helpers';
import OrganizationApi from '../../../services/api/OrganizationApi';
import {User} from '../../../model/dto/userProfile/user';
import {FailToastOptions, SuccessToastOptions} from '../../../model/constants/ToastConstants';
import {useMediaMatch} from 'rooks';

const UsersInputWrapper = styled.div`
  margin-bottom: 24px;
  div {
    min-width: unset;
  }
`;

const ModalDescription = styled.p`
  margin-bottom: 16px;
`;

interface Props {
  onClose: () => void;
  isOpen: boolean;
  service?: Service;
  organizationService?: OrganizationService;
  organizationMembers?: OrganizationMember[];
  handleUserAdd: (invitations: OrganizationServiceInvitationResponse[], orgService?: OrganizationService) => void;
}

const AddUserModal: React.FunctionComponent<Props> = ({
  onClose,
  isOpen,
  service,
  organizationService,
  handleUserAdd,
  organizationMembers,
}) => {
  const {t} = useTranslation('License');
  const {addToast} = useContext(ToastContext);
  const [busy, setBusy] = useState<boolean>(false);
  const [users, setUsers] = useState<string[]>([]);
  const [role, setRole] = useState<ServiceRole | undefined>(undefined);

  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));

  const [usersList, setUsersList] = useState<{ label: string; secondaryLabel?: string; value: string, disabled?: boolean }[] | string[]>(
    []);
  const [filterInput, setFilterInput] = useState<string>('');

  const shouldFilter = (user: User) => `${user.firstName} ${user.lastName} ${user.email}`.toLowerCase().includes(
    filterInput.toLowerCase());

  useEffect(() => {
    let options =
      organizationMembers
        ?.filter((a) => shouldFilter(a.user))
        ?.map((a) => ({
          label: `${a.user.firstName || ''} ${a.user.lastName || ''}`,
          secondaryLabel: a.user.email,
          value: a.user.email,
          disabled: false
        })) || [];

    if (!options.length && !!filterInput) {
      options.push({
        label: filterInput.toLowerCase(),
        secondaryLabel: '',
        value: filterInput.toLowerCase(),
        disabled: !isValidEmailAddress(filterInput),
      });
    }

    options = options.filter(
      (a) => !organizationService?.members?.find((b) => b.user?.email === a.value) &&
        !organizationService?.invitations?.find((b) => b.email === a.value && b.isActive),
    );

    setUsersList(options);
  }, [organizationMembers, organizationService, filterInput]);

  useEffect(() => setFilterInput(''), [users]);

  useEffect(() => {
    if (isOpen && service?.serviceRoles && service?.serviceRoles?.length == 1){
      setRole(service!.serviceRoles![0])
    } else {
      setRole(undefined)
    }
  }, [isOpen]);

  const close = () => {
    setUsers([]);
    setRole(undefined);
    setBusy(false);
    onClose();
  };

  const submit = () => {
    const serviceRole = role ?? service!.serviceRoles![0];
    setBusy(true);

    OrganizationApi.InviteMemberToOrganizationService(organizationService!.id, {
        role: serviceRole,
        emails: users,
        sendEmail: true,
      })
      .then((value) => {
        //@ts-ignore
        posthog.capture?.('AC SubscriptionAddUsers Added',{
          subscription_name: service!.name,
        });

        handleUserAdd(value, organizationService);
        close();
        addToast(t('Users invited'), SuccessToastOptions);
      })
      .catch((reason) => {
        setBusy(false);
        addToast(t('Inviting users failed'), FailToastOptions);
      });
  };

  return (
    <ModalDialog
      isModalOpen={isOpen}
      title={t('Add to {{ServiceName}}', {ServiceName: service?.name})}
      size={isMediumScreen ? Size.Medium : Size.Small}
      closeAction={close}
      submitAction={e => e?.preventDefault()}
      closeModalAndClearInput={close}
      note={
        t(
          'Selected people will be added to {{ServiceName}} and also be able to view the products, services, and people associated with your organization in Laerdal Connect.',
          {
            ServiceName: service?.name,
          },
        )}
      buttons={[
        {
          id: 'cancel',
          variant: 'tertiary',
          text: t('Cancel'),
          action: close,
          disabled: busy,
        },
        {
          id: 'save',
          action: submit,
          disabled: !users?.length || !role,
          loading: busy,
          variant: 'primary',
          text: t('Add selected'),
        },
      ]}>
      <ModalDescription>{t(
        'Send an email to people adding them to your  {{ServiceName}} subscription.',
        {ServiceName: service?.name},
      )}</ModalDescription>
      <UsersInputWrapper>
        <InputLabel text={t('Email address or name')} inputId={'NewServiceUsersInput'}/>
        <ChipDropdownInput list={usersList}
                           values={users}
                           inputId="NewServiceUsersInput"
                           onValueChange={setUsers}
                           disableFiltering={true}
                           onInputChange={setFilterInput}
                           placeholder={t('People')}/>
      </UsersInputWrapper>
      <UsersInputWrapper>
        <InputLabel text={t('Access level')} inputId={'RoleId'}/>
        <DropdownFilter
          list={service?.serviceRoles?.map((a) => ({value: a.id, displayLabel: a.name})) ?? []}
          size={Size.Medium}
          value={role?.id}
          onSelect={(value) => setRole(service?.serviceRoles?.find((r) => r.id === value))}
          id={'RoleId'}
        />
      </UsersInputWrapper>
    </ModalDialog>
  );
};

export default AddUserModal;
