import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {SubscriptionSliceState} from "./subscriptionSlice.state";
import {LicenseInformationHelper} from "../../pages/licenseManagementPage/helpers/LicenseInformationHelper";
import {SubscriptionOptions} from "../../model/dto/organization/subscriptionOptions";
import {Subscription} from "../../model/dto/organization/subscription";
import {SubscriptionPrice} from "../../model/dto/organization/subscriptionPrice";
import {SubscriptionBillingDetails} from "../../model/dto/organization/subscriptionBillingDetails";
import {SubscriptionTransaction} from "../../model/dto/organization/subscriptionTransaction";
import {Session} from "../../model/dto/organization/session";
import {OrganizationMember} from "../../model/dto/organization/organizationMember";
import {Service} from "../../model/dto/userProfile/service";
import {OrganizationService} from "../../model/dto/organization/organizationService";
import {SubscriptionAmendment} from "../../model/dto/organization/subscriptionAmendment";
import {ServicePrice} from "../../model/dto/organization/servicePrice";
import {SubscriptionAmendmentOrderResponse} from "../../model/dto/organization/subscriptionAmendmentOrderResponse";
import {BillingInterval} from "../../model/dto/userProfile/billingInterval";
import {ServicePlan} from "../../model/dto/userProfile/servicePlan";
import {ServicePlanTier} from "../../model/dto/userProfile/servicePlanTier";

const initialState: SubscriptionSliceState = {}

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscription: (state, action: { payload: Subscription | undefined }) => {
      state.subscription = action.payload;
    },
    setSubscriptionPrice: (state, action: { payload: SubscriptionPrice | undefined }) => {
      state.price = action.payload;
    },
    setSubscriptionBillingDetails: (state, action: { payload: SubscriptionBillingDetails | undefined }) => {
      state.billingDetails = action.payload;
    },
    setSubscriptionTransactions: (state, action: { payload: SubscriptionTransaction[] | undefined }) => {
      state.transactions = action.payload;
    },
    setSubscriptionSessions: (state, action: { payload: Session[] | undefined }) => {
      state.sessions = action.payload;
    },
    setCanManageSubscription: (state, action: { payload: boolean }) => {
      state.canManage = action.payload;
    },
    setIsSubscriptionOwner: (state, action: { payload: boolean }) => {
      state.isOwner = action.payload;
    },
    setIsSalesforceContact: (state, action: { payload: boolean }) => {
      state.isSalesforceContact = action.payload;
    },
    setOrganizationMembers: (state, action: { payload: OrganizationMember[] | undefined }) => {
      state.organizationMembers = action.payload;
    },
    setService: (state, action: { payload: Service | undefined }) => {
      state.service = action.payload;
    },
    setOrganizationService: (state, action: { payload: OrganizationService | undefined }) => {
      state.organizationService = action.payload;

      state.members = action.payload ? LicenseInformationHelper.ServiceMembers(action.payload) : undefined;
    },
    setSubscriptionScheduledAmendment: (state, action: { payload: SubscriptionAmendment | undefined }) => {
      state.scheduledAmendment = action.payload;
    },
    setSubscriptionPrices: (state, action: { payload: ServicePrice[] | undefined }) => {
      state.prices = action.payload;
    },
    setSubscriptionAmendmentOptions: (state, action: { payload: SubscriptionOptions | undefined }) => {
      state.amendmentOptions = action.payload;
    },
    setSubscriptionAmendmentOrder: (state, action: { payload: SubscriptionAmendmentOrderResponse | undefined }) => {
      state.amendmentOrder = action.payload;
    },
    setSubscriptionHasChangedOptions: (state, action: { payload: boolean }) => {
      state.hasChangedOptions = action.payload;
    },
    setSubscriptionAvailableTerms: (state, action: {
      payload: { billingInterval: BillingInterval; duration: number }[] | undefined
    }) => {
      state.availableTerms = action.payload;
    },
    setSubscriptionAvailablePlans: (state, action: { payload: ServicePlan[] | undefined }) => {
      state.availablePlans = action.payload;
    },
    setSubscriptionAvailableTiers: (state, action: { payload: ServicePlanTier[] | undefined }) => {
      state.availableTiers = action.payload;
    },
    setSubscriptionAmendmentPrice: (state, action: { payload: { price?: number; currency: string } | undefined }) => {
      state.amendmentPrice = action.payload;
    }
  },
});


export const {
  setSubscription,
  setSubscriptionPrice,
  setSubscriptionBillingDetails,
  setSubscriptionTransactions,
  setSubscriptionSessions,
  setCanManageSubscription,
  setIsSubscriptionOwner,
  setIsSalesforceContact,
  setOrganizationMembers,
  setService,
  setOrganizationService,
  setSubscriptionScheduledAmendment,
  setSubscriptionPrices,
  setSubscriptionAmendmentOptions,
  setSubscriptionHasChangedOptions,
  setSubscriptionAvailableTerms,
  setSubscriptionAvailablePlans,
  setSubscriptionAvailableTiers,
  setSubscriptionAmendmentOrder,
  setSubscriptionAmendmentPrice
} = subscriptionSlice.actions;

export const selectCanManageSubscription = (state: RootState) => !!state?.subscriptions?.canManage;
export const selectIsSubscriptionOwner = (state: RootState) => !!state?.subscriptions?.isOwner;
export const selectIsSalesforceContact = (state: RootState) => !!state?.subscriptions?.isSalesforceContact;

export const selectSubscription = (state: RootState) => state?.subscriptions?.subscription;
export const selectSubscriptionScheduledAmendment = (state: RootState) => state?.subscriptions?.scheduledAmendment;
export const selectSubscriptionService = (state: RootState) => state?.subscriptions?.service;
export const selectSubscriptionOrganizationService = (state: RootState) => state?.subscriptions?.organizationService
export const selectSubscriptionPrice = (state: RootState) => state?.subscriptions?.price;
export const selectSubscriptionBillingDetails = (state: RootState) => state?.subscriptions?.billingDetails;
export const selectSubscriptionTransactions = (state: RootState) => state?.subscriptions?.transactions;
export const selectSubscriptionSessions = (state: RootState) => state?.subscriptions?.sessions;
export const selectSubscriptionMembers = (state: RootState) => state?.subscriptions?.members;
export const selectOrganizationMembers = (state: RootState) => state?.subscriptions?.organizationMembers;

export const selectSubscriptionAmendmentPrice = (state: RootState) => state?.subscriptions?.amendmentPrice;
export const selectSubscriptionAmendmentOrder = (state: RootState) => state?.subscriptions?.amendmentOrder;
export const selectSubscriptionPriceConfigurations = (state: RootState) => state?.subscriptions?.prices;
export const selectSubscriptionAmendmentOptions = (state: RootState) => state?.subscriptions?.amendmentOptions;

export const selectSubscriptionHasChangedOptions = (state: RootState) => state?.subscriptions?.hasChangedOptions;

export const selectSubscriptionAvailableTerms = (state: RootState) => state?.subscriptions?.availableTerms;
export const selectSubscriptionAvailablePlans = (state: RootState) => state?.subscriptions?.availablePlans;
export const selectSubscriptionAvailableTiers = (state: RootState) => state?.subscriptions?.availableTiers;


export default subscriptionSlice.reducer;