import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {
  BackButton,
  BREAKPOINTS,
  COLORS,
  ComponentLStyling,
  ComponentMStyling,
  ComponentSStyling,
  ComponentTextStyle,
  LoadingIndicator,
  Size
} from "@laerdal/life-react-components";
import {useTranslation} from "react-i18next";
import {Service} from "../../model/dto/userProfile/service";
import {OrganizationService} from "../../model/dto/organization/organizationService";
import {OrganizationMember} from "../../model/dto/organization/organizationMember";
import {Session} from "../../model/dto/organization/session";
import OrganizationApi from "../../services/api/OrganizationApi";
import {InstanceLimitType} from "../../model/dto/userProfile/instanceLimitType";
import SettingsApi from "../../services/api/SettingsApi";
import {OrganizationServiceMember} from "../../model/dto/organization/organizationServiceMember";
import {
  OrganizationServiceInvitationResponse
} from "../../model/dto/organization/organizationServiceInvitationResponse";
import AddUserModal from "./components/AddUserModal";
import LicenseCard from "./components/LicenseCard";
import {PermissionsType, usePermissions} from "../../hooks/Permissions";
import {useNavigate} from "react-router";
import {EmptyPageBox} from '../_commonComponents/EmptyPageBox';
import dayjs from "dayjs";
import {StyledPageWidth} from '../_commonComponents/StyledComponents';
import {SubscriptionStatus, VALID_SUBSCRIPTION_STATUSES} from "../../model/dto/organization/subscriptionStatus";

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  margin-top: 16px;

  ${BREAKPOINTS.MEDIUM} {
    display: flex;
  }
`;

const Description = styled.p`
  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.neutral_500)}
  ${BREAKPOINTS.MEDIUM} {
    ${ComponentMStyling(ComponentTextStyle.Regular, COLORS.neutral_500)}
  }

  ${BREAKPOINTS.LARGE} {
    ${ComponentLStyling(ComponentTextStyle.Regular, COLORS.neutral_500)}
  }
`;

const SubscriptionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  ${BREAKPOINTS.MEDIUM} {
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  ${BREAKPOINTS.LARGE} {
    gap: 32px;
  }

`;

const ListEmptyMessage = styled.div`
  ${ComponentMStyling(ComponentTextStyle.Regular, COLORS.neutral_600)}
`;

interface InviteUsersModalData {
  open: boolean;
  service: Service;
  organizationService: OrganizationService;
  organizationMembers: OrganizationMember[];
  organizationServiceMembers: OrganizationServiceMember[];
}

export const LicenseListPage = () => {

  const {t} = useTranslation('License');
  const navigate = useNavigate();

  const {hasPermissions} = usePermissions();

  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState<Service[]>([]);
  const [organizationServices, setOrganizationServices] = useState<OrganizationService[]>([]);
  const [organizationMembers, setOrganizationMembers] = useState<OrganizationMember[]>([]);
  const [sessions, setSessions] = useState<{ [serviceId: string]: Session[] }>({});
  const [inviteUsersModalData, setInviteUsersModalData] = useState<InviteUsersModalData>();

  const loadData = () => {
    const abort = new AbortController();
    Promise.all([
      OrganizationApi.GetOrganizationServices(abort.signal),
      SettingsApi.GetServices(abort.signal),
      OrganizationApi.GetOrganizationMembers(abort.signal)
    ])
      .then(([orgServices, services, members]) => {
        const availableServices = orgServices.filter(s =>
          hasPermissions(PermissionsType.Subscription, s.id) &&
          !!s.subscription &&
          (dayjs(s.subscription?.startDate).isBefore(dayjs()))
        );

        const sessionBasedServices = services.filter((a) => a.instanceLimitType == InstanceLimitType.Session).map((a) => a.id);

        const sessionRequests =
          availableServices?.filter((a) => sessionBasedServices.includes(a.serviceId))
            .map((a) =>
              OrganizationApi.GetSessions(a.id, abort.signal)
                .then((r) => ({
                  serviceId: a.serviceId,
                  sessions: r,
                })),
            );

        return Promise.all(sessionRequests ?? []).then((response) => {
          setSessions(response.reduce((p, c) => ({...p, [c.serviceId]: c.sessions}), {}));
          setOrganizationServices(availableServices);
          setServices(services);
          setOrganizationMembers(members);
          return Promise.resolve();
        });
      })
      .catch((reason) => {
        if (!abort.signal.aborted) {
          setOrganizationServices([]);
          setServices([]);
          setOrganizationMembers([]);
        }
      })
      .finally(() => setLoading(false));

    return () => abort.abort();
  };

  useEffect(() => loadData(), []);

  const openInviteUserModal = (orgService: OrganizationService) => {
    setInviteUsersModalData({
      open: true,
      organizationMembers: organizationMembers,
      service: services!.find((a) => a.id === orgService.serviceId)!,
      organizationService: orgService,
      organizationServiceMembers: orgService.members,
    });
  }

  const handleUserAdd = (invitations: OrganizationServiceInvitationResponse[], orgService?: OrganizationService) => {
    const service = organizationServices.find((a) => a.id === orgService!.id)!;

    service.invitations = [...(service.invitations || []), ...invitations.filter(a => a.isSuccessful).map(a => ({
      ...a.invitation,
      isActive: true
    }))];

    setOrganizationServices([...organizationServices]);
  };


  const activeSubscriptions =
    organizationServices?.filter(a => VALID_SUBSCRIPTION_STATUSES.includes(a.subscription?.status || SubscriptionStatus.Deactivated));

  const inactiveSubscriptions =
    organizationServices?.filter(a => !VALID_SUBSCRIPTION_STATUSES.includes(a.subscription?.status || SubscriptionStatus.Deactivated));

  return (
    <StyledPageWidth useMaxWidth={true} maxWidth={1600}>
      <AddUserModal
        onClose={() => setInviteUsersModalData(undefined)}
        isOpen={inviteUsersModalData?.open || false}
        service={inviteUsersModalData?.service}
        organizationService={inviteUsersModalData?.organizationService}
        organizationMembers={inviteUsersModalData?.organizationMembers}
        handleUserAdd={handleUserAdd}
      />
      <Header>
        <BackButton size={Size.Small} onClick={() => navigate('/overview')}>{t('Back to overview')}</BackButton>
        <Title>{t('Subscriptions & Licenses')}</Title>
        <Description>{t('Manage access to digital products and services.')}</Description>
      </Header>

      {
        loading &&
        <LoadingContainer>
          <LoadingIndicator/>
        </LoadingContainer>
      }

      {
        activeSubscriptions?.length > 0 &&
        <>
          <h5>{t('Active subscriptions')}</h5>
          <SubscriptionsContainer>
            {
              activeSubscriptions!.map(a =>
                <LicenseCard key={a.id}
                             organizationMembers={organizationMembers}
                             organizationService={a}
                             sessions={sessions[a.serviceId]}
                             service={services?.find(b => b.id === a.serviceId)!}
                             openInviteUsersModal={() => openInviteUserModal(a)}/>
              )
            }
          </SubscriptionsContainer>
        </>
      }
      {
        inactiveSubscriptions?.length > 0 &&
        <>
          <h5>{t('Inactive subscriptions')}</h5>
          <SubscriptionsContainer>
            {
              inactiveSubscriptions!.map(a =>
                <LicenseCard key={a.id}
                             organizationMembers={organizationMembers}
                             organizationService={a}
                             sessions={sessions[a.serviceId]}
                             service={services?.find(b => b.id === a.serviceId)!}
                             openInviteUsersModal={() => openInviteUserModal(a)}/>
              )
            }
          </SubscriptionsContainer>
        </>
      }

      {
        !loading && !activeSubscriptions?.length && !inactiveSubscriptions?.length &&
        <EmptyPageBox title={t('Your subscriptions')} description={t('There are no active subscriptions to display.')}/>
      }
    </StyledPageWidth>
  )

};

export default LicenseListPage;