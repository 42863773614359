import { SystemIcons, Tag, TagVariants } from "@laerdal/life-react-components";
import { ReactNode } from "react";
import {useTranslation} from 'react-i18next';

type AppointmentStatusProps = {
    status: string | null;
    statusTranslated: string | null;
  };

const AppointmentStatus = ({status, statusTranslated}: AppointmentStatusProps ) => { 
    const {t} = useTranslation('Appointments');

    const GetStatusVariant = (status :string | null) : TagVariants => {
        if(status?.toLowerCase() == "completed") return 'positive';
        else if(status?.toLowerCase() == "pending") return 'warning';
        else if(status?.toLowerCase() == "expired") return 'neutral';
        else if(status?.toLowerCase() == "dispatched") return 'accent1';
        else if(status?.toLowerCase() == "unscheduled") return 'critical';
        else if(status?.toLowerCase() == "cannot complete") return 'neutral';
        return 'neutral';
      }

      const GetStatusIcon = (status :string | null) : ReactNode => {
        if(status?.toLowerCase() == "completed") return <SystemIcons.CheckMark />;
        else if(status?.toLowerCase() == "pending") return <SystemIcons.Time />;
        else if(status?.toLowerCase() == "expired") return undefined;
        else if(status?.toLowerCase() == "cannot complete") return undefined;
        else if(status?.toLowerCase() == "dispatched") return <SystemIcons.Calendar />;
        else if(status?.toLowerCase() == "unscheduled") return <SystemIcons.Calendar />;
        return <></>;
      }
      
    return (
        !!statusTranslated ? 
          <Tag label={statusTranslated ?? ''} variant={GetStatusVariant(status)} icon={GetStatusIcon(status)} />
          : <></>
      );
}

export default AppointmentStatus;