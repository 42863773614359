import React, {useContext, useState} from 'react';
import {OrganizationInvitation} from '../../../model/dto/organization/organizationInvitation';
import {
  BREAKPOINTS,
  ChipInput,
  COLORS,
  ComponentTextStyle,
  ComponentXSStyling,
  InputLabel,
  ModalDialog, 
  Size,
  States,
  SystemIcons,
  ToastContext,
} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import {useMediaMatch} from 'rooks';
import {ChipItem} from '@laerdal/life-react-components/dist/ChipsInput/ChipInputTypes';
import styled from 'styled-components';
import OrganizationApi from '../../../services/api/OrganizationApi';
import {FailToastOptions, SuccessToastOptions} from '../../../model/constants/ToastConstants';

const Wrapper = styled.div`
  margin-bottom: 16px;
`;

const Description = styled.p`
  margin-bottom: 16px;
`;
const Note = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  ${ComponentXSStyling(ComponentTextStyle.Regular, COLORS.neutral_500)}
`;


interface Props {
  isOpen: boolean;
  members: string[];
  onClose: () => void;
  onAdd: (invitations: OrganizationInvitation[]) => void;
}

export const InviteUserModal = ({isOpen, onClose, onAdd, members}: Props) => {

  const {t} = useTranslation('Organization');
  const {addToast} = useContext(ToastContext);
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState<ChipItem[]>([]);
  const [validation, setValidation] = useState<string>();

  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));

  const close = () => {
    setEmails([]);
    setValidation(undefined);
    onClose();
  };

  const submit = () => {
    if (emails.length === 0) {
      return setValidation(t('Enter a valid email address'));
    } else if (!!validation) {
      return;
    }

    setLoading(true);

    //@ts-ignore
    posthog.capture?.('InviteUserModal InvitedUser');
    OrganizationApi.AddUsersToOrganization(emails.map(a => a.label))
      .then(response => {
        const newInvitations = response?.filter(a => a.isSuccessful).map(a => ({...a.invitation, isActive: true}));
        addToast(t('Users invited'), {...SuccessToastOptions, icon: <SystemIcons.CheckMark/>});
        onAdd(newInvitations);
        setEmails([]);
        onClose();
      })
      .catch(a => {
        setEmails([]);
        addToast(t('Inviting users failed'), FailToastOptions);
      })
      .finally(() => setLoading(false));
  };

  const handleValueChange = (values: string[]) => {
    const regex = /^\S+@\S+\.\S+$/;
    const existing = members.filter(a => values.includes(a));
    const invalid = !!values.filter(a => !regex.test(a))?.length;
    const validation = invalid
      ? t('Invalid email address')
      : existing.length >= 1
        ? t('Email already exists', {email: existing.join(', ')})
        : undefined;

    const items = values.map(a => ({
      label: a,
      icon: 'User',
      variant: members.includes(a) || !regex.test(a) ? States.Invalid : States.Default,
    }) as (ChipItem & { value: string }));

    setEmails(items);
    setValidation(validation);
  };

  return (<ModalDialog isModalOpen={isOpen}
                       closeModalAndClearInput={close}
                       closeAction={close}
                       submitAction={(e) => e?.preventDefault()}
                       size={isMediumScreen ? Size.Medium : Size.Small}
                       title={t('Invite people')}
                       buttons={[
                         {
                           id: 'cancel',
                           variant: 'tertiary',
                           text: t('Cancel'),
                           action: close,
                           disabled: loading,
                           type: 'button',
                         },
                         {
                           id: 'submit',
                           variant: 'primary',
                           type: 'submit',
                           text: t('Send invite(s)'),
                           loading: loading,
                           action: submit,
                         },
                       ]}>
    <Wrapper>
      <Description>
        {t('Send an email to co-workers asking them to register and join your organization in Laerdal Connect.')}
      </Description>

      <InputLabel inputId={'emails'} text={t('Email address')}/>
      <ChipInput inputId={'email'}
                 autoComplete={'off'}
                 variants={emails.map(a => a.variant!)}
                 values={emails.map(a => a.label)}
                 autoSave={true}
                 placeholder={t('e.g. annie@laerdal.com')}
                 altPlaceholder={t('Type to add more people')}
                 validationMessage={validation}
                 onValueChange={handleValueChange}/>
      {
        !validation &&
          <Note>
              <SystemIcons.Information size={'20px'}/>
            {t('Use spaces to separate multiple email addresses.')}
          </Note>
      }
    </Wrapper>
  </ModalDialog>);
};