import AuthApi from './AuthApi';
import axios, {AxiosResponse} from 'axios';
import {OrganizationMember} from '../../model/dto/organization/organizationMember';
import {OrganizationRole} from '../../model/dto/organization/organizationRole';
import {OrganizationInvitation} from '../../model/dto/organization/organizationInvitation';
import {OrganizationService} from '../../model/dto/organization/organizationService';
import {ServiceRole} from '../../model/dto/userProfile/serviceRole';
import {OrganizationInvitationResponse} from '../../model/dto/organization/organizationInvitationResponse';
import {
  OrganizationServiceInvitationResponse,
} from '../../model/dto/organization/organizationServiceInvitationResponse';
import {
  OrganizationServiceInvitationsRequest,
} from '../../model/dto/organization/organizationServiceInvitationsRequest';
import {Session} from "../../model/dto/organization/session";
import {Organization} from "../../model/dto/organization/organization";
import {SubscriptionDetails} from "../../model/dto/organization/subscriptionDetails";

class OrganizationApi {
  static instance = new OrganizationApi();

  GetOrganizationMembers = async (signal?: AbortSignal): Promise<OrganizationMember[]> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/organization/members`, {
        headers: {Authorization: `Bearer ${await AuthApi.getToken()}`, 'Content-Type': 'application/json'},
        signal: signal,
      })
      .then((response) => {
        return response?.data;
      });
  };

  GetOrganizationInvitations = async (signal?: AbortSignal): Promise<OrganizationInvitation[]> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/organization/invitations`, {
        headers: {Authorization: `Bearer ${await AuthApi.getToken()}`, 'Content-Type': 'application/json'},
        signal: signal,
      })
      .then((response) => {
        return response?.data;
      });
  };

  IsSfOrganization = async (signal?: AbortSignal): Promise<boolean> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/organization/is-sf-account`, {
        headers: {Authorization: `Bearer ${await AuthApi.getToken()}`, 'Content-Type': 'application/json'},
        signal: signal,
      })
      .then((response) => {
        return response?.data;
      });
  };

  GetOrganizationServices = async (signal?: AbortSignal): Promise<OrganizationService[]> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/organization/services`, {
        headers: {Authorization: `Bearer ${await AuthApi.getToken()}`, 'Content-Type': 'application/json'},
        signal: signal,
      })
      .then((response) => {
        return response?.data;
      });
  };

  GetOrganizationService = async (id: string, signal?: AbortSignal): Promise<OrganizationService> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/organization/services/${id}`, {
        headers: {Authorization: `Bearer ${await AuthApi.getToken()}`, 'Content-Type': 'application/json'},
        signal: signal,
      })
      .then((response) => {
        return response?.data;
      });
  };

  AddUsersToOrganization = async (emails: string[],
  ): Promise<OrganizationInvitationResponse[]> => {
    const newUsers = {
      emails: emails,
      sendEmail: true,
      acceptUrl: process.env.REACT_APP_CONNECT_URL + '/join/',
    };
    return axios
      .post(`${process.env.REACT_APP_API_URL}/organization/invite`, newUsers, {
        headers: {Authorization: `Bearer ${await AuthApi.getToken()}`, 'Content-Type': 'application/json'},
      })
      .then((response) => {
        return response.data;
      });
  };

  InviteMemberToOrganizationService = async (
    orgServiceId: string,
    inviteObj: OrganizationServiceInvitationsRequest | undefined,
  ): Promise<OrganizationServiceInvitationResponse[]> => {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/organization/service/${orgServiceId}/member/invite`,
        inviteObj,
        {headers: {Authorization: `Bearer ${await AuthApi.getToken()}`}},
      )
      .then((response: AxiosResponse) => response.data);
  };

  UpdateOrganizationInvitation = async (
    invitationCode: string,
    resendInvitationEmail: boolean,
    revokeInvitation: boolean = false,
  ): Promise<void> => {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/organization/invite/${invitationCode}?resend=${resendInvitationEmail}&revoke=${revokeInvitation}`,
        null,
        {
          headers: {Authorization: `Bearer ${await AuthApi.getToken()}`},
        },
      )
      .then(() => {
        return;
      });
  };

  UpdateOrganizationServiceMember = async (
    orgServiceId: string,
    memberId: string,
    role: ServiceRole,
  ): Promise<number> => {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/organization/service/${orgServiceId}/member/${memberId}`,
        role,
        {headers: {Authorization: `Bearer ${await AuthApi.getToken()}`}},
      )
      .then((response: AxiosResponse) => response.status);
  };


  RemoveOrganizationMember = async (memberEmail: string, sendEmail: boolean): Promise<any> => {
    return axios
      .delete(
        `${process.env.REACT_APP_API_URL}/organization/member/${memberEmail}?sendEmail=${sendEmail}`,
        {headers: {Authorization: `Bearer ${await AuthApi.getToken()}`}},
      )
      .then((response) => {
        return response;
      })
      .catch((error) => Promise.reject(error));
  };

  RemoveOrganizationServiceMember = async (orgServiceId: string, memberId: string): Promise<number> => {
    return axios
      .delete(
        `${process.env.REACT_APP_API_URL}/organization/service/${orgServiceId}/member/${memberId}`,
        {headers: {Authorization: `Bearer ${await AuthApi.getToken()}`}},
      )
      .then((response: AxiosResponse) => response.status);
  };

  RemoveOrganizationServiceInvitation = async (orgServiceId: string, code: string): Promise<number> => {
    return axios
      .delete(
        `${process.env.REACT_APP_API_URL}/organization/service/${orgServiceId}/member/invite/${code}`,
        {headers: {Authorization: `Bearer ${await AuthApi.getToken()}`}},
      )
      .then((response: AxiosResponse) => response.status);
  };

  GetSessions = async (orgServiceId: string, abort?: AbortSignal): Promise<Session[]> => {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/organization/service/${orgServiceId}/sessions`,
        {headers: {Authorization: `Bearer ${await AuthApi.getToken()}`}, signal: abort},
      )
      .then((response: AxiosResponse) => response.data);
  };

}

export default OrganizationApi.instance;