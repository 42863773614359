import React, {useContext, useEffect, useState} from 'react';
import {
  BREAKPOINTS,
  COLORS,
  ComponentMStyling,
  ComponentTextStyle,
  ModalDialog,
  Size,
  ToastContext
} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Service} from "../../../model/dto/userProfile/service";
import {OrganizationService} from "../../../model/dto/organization/organizationService";
import OrganizationApi from "../../../services/api/OrganizationApi";
import {ServiceMemberItem, ServiceMemberItemInvitation, ServiceMemberItemMember} from "../types";
import {FailToastOptions, SuccessToastOptions} from "../../../model/constants/ToastConstants";
import {useMediaMatch} from 'rooks';

const Header = styled.div`
  ${ComponentMStyling(ComponentTextStyle.Bold, COLORS.black)}
`;

const Description = styled.div`
  ${ComponentMStyling(ComponentTextStyle.Regular, COLORS.black)}
`;

interface Props {
  onClose: () => void;
  isOpen: boolean;
  service: Service | null | undefined;
  organizationServiceId: string | null | undefined;
  member: ServiceMemberItem | undefined;
  handleUserRemove: (key: string) => void;
}

const RemoveUserModal: React.FunctionComponent<Props> = ({
                                                           onClose,
                                                           isOpen,
                                                           service,
                                                           organizationServiceId,
                                                           member,
                                                           handleUserRemove
                                                         }) => {
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));

  const {t} = useTranslation('License');
  const {addToast} = useContext(ToastContext);
  const [busy, setBusy] = useState<boolean>(false);

  const removeServiceMember = (item: ServiceMemberItemMember) => {
    OrganizationApi.RemoveOrganizationServiceMember(organizationServiceId!, item.member.user.id!)
      .then(() => {
        addToast(t('Person removed from {{serviceName}}', {serviceName: service?.name}), SuccessToastOptions);
        handleUserRemove(item.member.id!);
      })
      .catch(() => {
        addToast(t('There was a problem removing the service member.'), FailToastOptions);
      })
      .finally(() => close());
  }

  const revokeServiceInvitation = (item: ServiceMemberItemInvitation) => {
    OrganizationApi.RemoveOrganizationServiceInvitation(organizationServiceId!, item.invitation.code)
      .then(() => {
        addToast(t('Person removed from {{serviceName}}', {serviceName: service?.name}), SuccessToastOptions);
        handleUserRemove(item.invitation.code);
      })
      .catch(() => {
        addToast(t('There was a problem removing the service member.'), FailToastOptions);
      })
      .finally(() => close());
  }

  const close = () => {
    setBusy(false);
    onClose();
  }

  const submit = () => {
    setBusy(true);

    switch (member?.type) {
      case 'member':
        removeServiceMember(member);
        break;
      case 'invitation':
        revokeServiceInvitation(member);
        break;
    }

  };

  let header = '';
  switch (member?.type) {
    case 'member':
      header = member.member.user.email;
      break;
    case 'invitation':
      header = member.invitation.email;
      break;
  }

  return (
    <ModalDialog
      isModalOpen={isOpen}
      closeModalAndClearInput={close}
      closeAction={close}
      size={isMediumScreen ? Size.Medium : Size.Small}
      title={t('Remove from service')}
      submitAction={e => e?.preventDefault()}
      note={t('This person will lose access to this service, but will remain member of your organization')}
      buttons={[
        {
          id: 'cancel',
          text: t('No, cancel'),
          disabled: busy,
          variant: 'tertiary',
          action: close,
        },
        {
          id: 'remove',
          variant: 'critical',
          loading: busy,
          action: submit,
          text: t('Yes, remove'),
        },
      ]}>
      <Header>{header}</Header>
      <Description>{t('Are you sure you want to remove this person from {{serviceName}}?', {serviceName: service?.name})}</Description>
    </ModalDialog>
  );
};

export default RemoveUserModal;
